export const ACCESS_TOKEN: string = "ACCESS_TOKEN";
export const IMAGE_PATH: string = "https://onecruit.uz/api/storage/files";
export const NOTIFICATION_TYPE: string = "INVITE_STUDENT_TO_PROJECT";
export const USER_AGREEMENT_RU: string = `Любой контент, введенный пользователем на сайте (например, фото профиля, текст в разделе «Обо мне» и «Навыки») считается «Пользовательским контентом»  (User-Generated Content) и не должен представлять собой оскорбительный, бестактный, расстраивающий или аморальный контент. Примеры такого контента включают:
Ненормативная лексика и дискредитирующий, дискриминационный или оскорбительный контент, включая ссылки или комментарии о религии, расе, поле, национальном/этническом происхождении или других целевых группах, особенно если приложение может унизить, запугать или нанести вред целевому лицу или группе.
Реалистичные изображения людей или животных, которые убивают, калечат, пытают или оскорбляют, или материалы, поощряющие насилие, членовредительство или самоубийство.
Материалы откровенно сексуального или порнографического характера, определяемые как «откровенные описания или демонстрации половых органов или действий, направленных на стимуляцию эротических чувств»
Подстрекательские религиозные комментарии или неточные или вводящие в заблуждение цитаты из религиозных текстов.
Пользователи несут полную ответственность за контент, который они вводят.`;
export const USER_AGREEMENT_EN: string = `Any content entered by a user on the site (for example, profile photo, text in the "About Me" and "Skills" sections) is considered as "User-Generated Content" and must not constitute offensive, indecent, upsetting or immoral content. Examples of such content include:
• Profanity and defamatory, discriminatory, or offensive content, including references or comments about religion, race, gender, national/ethnic origin, or other target groups, especially if the app may humiliate, intimidate, or harm the target person or group.
• Realistic images of people or animals that kill, maim, torture, or abuse, or material that encourages violence, self-harm, or suicide.
• Sexually explicit or pornographic material, defined as "explicit descriptions or displays of sexual organs or acts intended to stimulate erotic feelings"
• Inflammatory religious comments or inaccurate or misleading quotations from religious texts.
Users are solely responsible for the content they enter.`;
export const USER_AGREEMENT_UZ: string = `Foydalanuvchi tomonidan saytga kiritilgan har qanday kontent (masalan, profil surati, “Men haqimda, ko‘nikmalar” bo‘limidagi matn) “Foydalanuvchi yaratgan kontent” (User-Generated Content) hisoblanadi va haqoratomuz, odobsiz, hafa qiluvchi yoki amoral mazmunga ega bo’lishi mumkin emas. Bunday kontentga quyidagilar misol bo‘ladi:
• Din, irq, jins, milliy/etnik kelib chiqishi yoki boshqa maqsadli guruhlarga oid havolalar yoki sharhlarni o‘z ichiga olgan uyatli va tuhmatli, kamsituvchi yoki haqoratli, ayniqsa ma’lum shaxs yoki guruhni kamsitishi, qo‘rqitishi yoki zarar yetkazishi mumkin bo‘lgan kontent.
• O'ldiradigan, mayib qiladigan, qiynoqqa soladigan yoki haqorat qiluvchi odamlar yoki hayvonlarning real tasvirlari yoki zo‘ravonlik, o‘z joniga qasd qilish yoki o‘z joniga qasd qilishga undaydigan materiallar.
• “Jinsiy organlarning aniq tasvirlari yoki namoyishlari yoki erotik tuyg'ularni uyg'otishga qaratilgan harakatlar” sifatida aniqlangan shahvoniy yoki pornografik material.
• Qo‘zg‘atuvchi diniy sharhlar yoki diniy matnlardan noto‘g‘ri yoki chalg‘ituvchi iqtiboslar.
Foydalanuvchilar ular kiritgan kontent uchun to‘liq javobgarlikni zimmasiga oladi.`;
