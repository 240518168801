import React, { useState } from "react";
import Container from "../../../layout/Container";
import GoBack from "../../../Components/Buttons/GoBack";
import { Badge, Breadcrumb, Card, Col, Row } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MetaType, ParamsType } from "../../../interfaces/index.interface";
import { getListsOfChat } from "../../../api/profile";
import dayjs from "dayjs";
import { DotLoading, InfiniteScroll } from "antd-mobile";
import { useTranslation } from "react-i18next";

interface JobPosition {
  id: number;
  name: string;
  type: string;
  dueDate: string;
}

interface ItemType {
  unreadCount: number;
  jobPosition: JobPosition;
  lastTime: string;
  id: number;
}

interface DataChatLists {
  hrId: number;
  page?: number;
  size?: number;
}

interface DataType {
  meta: MetaType;
  items: ItemType[];
}

const ChatLists = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [listChats, setListChats] = useState<ItemType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });

  const loadMore = async () => {
    try {
      setLoading(true);

      const dataChat: DataChatLists = {
        hrId: Number(id),
        page: params.page,
        size: params.size,
      };

      const res = await getListsOfChat(dataChat);
      const data: DataType = res?.data?.data;
      console.log("🚀 ~ loadMore ~ data:", data);
      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      // setFiltered_data((prevState) => ({
      //   ...prevState,
      //   page: data.meta.page + 1,
      // }));

      setListChats((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMore(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>{t("no_more")}</span>
        )}
      </>
    );
  };
  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack />
      <div className="container">
        {/* Breadcrumb */}
        <Row className="pb-2">
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate(-1)}>
                {t("messages")}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/notifications"} style={{ textDecoration: "none" }}>
                  {t("chat_lists")}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row>
          <div className="w-100">
            {listChats?.length
              ? listChats?.map((item, index) => (
                  <Col span={24} key={index}>
                    {/* <Avatar src={`${IMAGE_PATH + item?.company?.logo}`} /> */}
                    <Link
                      to={String(item?.jobPosition?.id)}
                      style={{ textDecoration: "none" }}
                    >
                      <Card loading={loading} className="my-2">
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center">
                            <div>
                              <h6 className="m-0 truncate">
                                {item?.jobPosition
                                  ? item?.jobPosition?.name
                                  : "General"}
                              </h6>
                            </div>
                          </div>
                          <div className="text-end">
                            <p className="m-0">
                              {dayjs(item.lastTime).format("DD-MM-YYYY")}
                            </p>
                            <Badge count={item.unreadCount} />
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))
              : loading
              ? ""
              : ""}
          </div>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            className="text-center w-100"
          >
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </Row>
      </div>
    </Container>
  );
};

export default ChatLists;
