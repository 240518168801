import { DotLoading } from "antd-mobile";
import LogoCircle from "../../assets/icons/LogoCircle";
import styles from "./styles.module.scss";

const LoaderOpacity = () => {
  return (
    <div className={styles.container}>
      <span style={{ fontSize: 20 }} className={styles.box}>
        <LogoCircle class={styles.logo} />
        <DotLoading color="white" />
      </span>
    </div>
  );
};

export default LoaderOpacity;
