import React from "react";
import Container from "../../../../layout/Container";
import GoBack from "../../../../Components/Buttons/GoBack";
import Typography from "../../../../Components/Typography";
import VacanyCard from "../../Components/VacanyCard";
import { useTranslation } from "react-i18next";

const AllInternships = () => {
  const { t } = useTranslation();
  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack to={"/"} />

      <div className="container">
        {/* Title */}
        <Typography title={t("all_internerships")} variant="h2" />
      </div>

      <VacanyCard jobType="INTERNSHIP" />
    </Container>
  );
};

export default AllInternships;
