import { useEffect, useState } from "react";
import instance from "../configs/axios";
import { ParamsType, TypeAxiosConfig } from "../interfaces/index.interface";
import { AxiosRequestConfig } from "axios";

type PropTypes = {
  url: string;
  refetch?: any[];
  params?: ParamsType;
};

interface ValidatorTypes {
  loading: boolean;
  error: string;
}

export const useGetAllData = (props: PropTypes) => {
  const { url, refetch = [], params } = props;
  const page = params?.page;

  const [validator, setValidator] = useState<ValidatorTypes>({
    loading: true,
    error: "",
  });

  const [data, setData] = useState<any>([]);

  const fetchData = async () => {
    try {
      setValidator({
        loading: true,
        error: "",
      });
      const config: AxiosRequestConfig = params
        ? {
            url,
            method: "get",
            params,
          }
        : {
            url,
            method: "get",
          };
      const res = await instance(config);
      setData(res?.data?.data);
    } catch (error: any) {
      if (error?.response?.data?.status === 404) {
        setValidator((prevState) => ({
          ...prevState,
          error: "Work experience not found",
        }));
      }
    } finally {
      setValidator((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  return {
    data,
    error: validator.error,
    loading: validator.loading,
    setData,
    fetchData,
  };
};
