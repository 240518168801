import {
  AutocomplateOptType,
  TypeAxiosConfig,
} from "../interfaces/index.interface";
import { useEffect, useState } from "react";
import { getFacultiesById, getJobOnType } from "../api/profile";
import useDebounce from "./useDebounce";
import instance from "../configs/axios";

type PropType = {
  url: string;
  language?: "uz" | "ru" | "en";
};

type DataType = {
  id: number;
  name: string;
};

export const useGetOptionsObj = (props: PropType) => {
  const { url, language = "en" } = props;

  // Autocomplate Options
  const [options, setOptions] = useState<{ value: string; id: number }[]>([]);
  const [optionsChild, setOptionsChild] = useState<{ value: string }[]>([]);

  //   Search value
  const [search, setSearch] = useState<string>("");
  const debounce = useDebounce(search);

  //   Handler
  const handlerSearch = (data: any) => {
    setOptions([]);
    setOptionsChild([]);
  };

  //   OnSearch
  const getPanelValue = (searchText: string) => {
    setSearch(searchText);
  };

  //   OnSelect
  const onSelect = async (value: string, option: AutocomplateOptType) => {
    // console.log(
    //   "🚀 ~ file: useGetOptionsObj.ts:37 ~ onSelect ~ option:",
    //   option
    // );

    try {
      const res = await getFacultiesById(option.id);
      setOptionsChild([]);
      const data: any = res?.data?.data;
      for (let i = 0; i < data.length; i++) {
        setOptionsChild((prevState) => [
          ...prevState,
          {
            value: data[i],
          },
        ]);
      }
    } catch (error: any) {
      console.log(
        "🚀 ~ file: useGetOptionsObj.ts:51 ~ onSelect ~ error:",
        error
      );
    }
  };

  // Get Options on type
  useEffect(() => {
    (async () => {
      if (debounce) {
        try {
          const config: TypeAxiosConfig = {
            url: `${url}?searchAny=${debounce}`,
            method: "get",
            headers: {
              "ACCEPT-LANGUAGE": language,
            },
          };
          const res = await instance(config);
          setOptions([]);
          const data: DataType[] = res?.data?.data;
          for (let i = 0; i < data.length; i++) {
            setOptions((prevState) => [
              ...prevState,
              {
                value: data?.[i].name,
                id: data?.[i]?.id,
              },
            ]);
          }
        } catch (error: any) {
          console.log("🚀 ~ file: index.tsx:105 ~ error:", error);
        }
      }
    })();
  }, [debounce]);
  return {
    options,
    optionsChild,
    getPanelValue,
    handlerSearch,
    onSelect,
  };
};
