import { Button, Col, Row } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LogoCircle from "../../assets/icons/LogoCircle";

const PasswordUpdated = () => {
  const { isLight } = useSelector((state: any) => state.uiReducer);
  return (
    <Fragment>
      <Row className="mb-5">
        <Col span={24}>
          <div className="text-center">
            {/* <Link to={"/"}> */}
            <LogoCircle
              class={`logoAuth ${isLight ? "logoLight" : "logoDark"}`}
            />
            {/* </Link> */}
            <h1>Change password</h1>
            <p className="px-4">Your password has been updated</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col span={24} className="mb-2 px-3 mt-5">
          <Link to={"/login"}>
            <Button
              htmlType="submit"
              className="w-100 py-4 d-flex justify-content-center align-items-center"
              type="primary"
            >
              Back to Login
            </Button>
          </Link>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PasswordUpdated;
