import { Col, Form, Input, message, Row } from "antd";
import { Modal } from "antd-mobile";
import { CloseCircleOutline } from "antd-mobile-icons";
import { Fragment, useState } from "react";
import { updatePassword } from "../../../../api/auth";
import GoBack from "../../../../Components/Buttons/GoBack";
import SaveBtn from "../../../../Components/Buttons/SaveBtn";
import { UpdatePasswordType } from "../../../../interfaces/index.interface";
import Typography from "../../../../Components/Typography";
import { useTranslation } from "react-i18next";
import Container from "../../../../layout/Container";

const UpdatePassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = async (onFinish: UpdatePasswordType) => {
    try {
      setLoading(true);
      const data: UpdatePasswordType = {
        oldPassword: onFinish.oldPassword,
        newPassword: onFinish.newPassword,
      };
      const res = await updatePassword(data);
      message.success("Password successfully updated");
    } catch (error: any) {
      Modal.alert({
        header: (
          <CloseCircleOutline
            style={{
              fontSize: 64,
              color: "var(--adm-color-danger)",
            }}
          />
        ),
        title: error?.response?.data?.message,
        showCloseButton: true,
        confirmText: "Ok",
      });
      // message.error(error?.response?.data?.message);
      console.log("🚀 ~ file: index.tsx:21 ~ onFinish ~ error:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container type="fluid">
      <GoBack to={"/profile/settings"} />
      <div className="container">
        {/* Title */}
        <Typography title={t("update_password")} variant="h2" />

        <Form onFinish={onFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={t("old_password")}
                name={"oldPassword"}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input.Password allowClear placeholder="******" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("new_password")}
                name={"password"}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input.Password allowClear placeholder="******" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t("confirm_password")}
                name={"newPassword"}
                required={false}
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(String(t("reject_confirm_pass")))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password allowClear placeholder="******" />
              </Form.Item>
            </Col>
            <SaveBtn loading={loading}>{String(t("update"))}</SaveBtn>
          </Row>
        </Form>
      </div>
    </Container>
  );
};

export default UpdatePassword;
