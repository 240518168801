import instance from "../../configs/axios";
import { UpdatePasswordType } from "../../interfaces/index.interface";

interface login {
  login: string;
  password: string;
}

type ValuesType = {
  email: string;
  password: string;
  fullname: string;
};

const aboutMe = (token?: string) => {
  return instance({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: "get",
    url: "/user/get-about",
  });
};

const postLogin = (data: login) => {
  return instance({
    method: "post",
    url: "/auth/login",
    data: data,
  });
};

const postSignup = (data: ValuesType) => {
  return instance({
    method: "post",
    url: "/auth/registration",
    data: data,
  });
};

const postCheckEmail = (email: string, code: number) => {
  return instance({
    method: "post",
    url: `/auth/check-email?email=${email}&code=${code}`,
  });
};

const postForgotPass = (email: string) => {
  return instance({
    method: "post",
    url: `/auth/forgot-password?email=${email}&user_type=STUDENT`,
  });
};

const postChangePass = (password: string) => {
  return instance({
    method: "post",
    url: `/user/change-password?password=${password}`,
  });
};

const postLoginOauth2 = (token: string) => {
  return instance({
    method: "post",
    url: `/auth/oauth2/${token}`,
  });
};

const updateMe = (data?: any, type?: string) => {
  console.log("🚀 ~ file: index.ts:70 ~ updateMe ~ type:", type);
  return instance({
    headers: {
      "Content-Type": `${
        type === "profile"
          ? "multipart/form-data"
          : "application/json;charset=utf-8"
      }`,
    },
    method: "put",
    url: `/students/${type}`,
    data: data,
  });
};

const deleteMe = () => {
  return instance({
    method: "delete",
    url: "/students",
  });
};

const updatePassword = (data: UpdatePasswordType) => {
  return instance({
    method: "put",
    url: "/students/password",
    data: data,
  });
};

export {
  aboutMe,
  postLogin,
  postSignup,
  postCheckEmail,
  postForgotPass,
  postChangePass,
  postLoginOauth2,
  updateMe,
  deleteMe,
  updatePassword,
};
