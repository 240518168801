import { PropTypeIcon } from "../../../interfaces/index.interface";
import styles from "./styles.module.scss";

const IconFilter = (props: PropTypeIcon) => {
  const { className } = props;
  return (
    <span
      className={`icon ${styles.icon} ${
        props?.className ? props.className : ""
      }`}
    />
  );
};

export default IconFilter;
