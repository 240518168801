import { Badge, Button, Col, Dropdown, MenuProps, Row } from "antd";
import { Image as ImageMobile } from "antd-mobile";
import { Fragment, useState } from "react";
import { MessageOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_PATH } from "../../../../constants";
import { useGetImage } from "../../../../hooks/useGetImage";
import styles from "./styles.module.scss";
import { BellOutline } from "antd-mobile-icons";
import { useUnreadNotif } from "../../../../hooks/useUnreadNotif";
import { useTranslation } from "react-i18next";
import "../../../../i18next";

const ProfilePic = () => {
  const { t, i18n } = useTranslation();
  const { count } = useUnreadNotif();
  const navigate = useNavigate();

  const { userData, isAuth } = useSelector((state: any) => state.authReducer);
  const { loading } = useGetImage({ url: userData?.photoUrl });
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const handleChangeLng = (lng: any) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleChange = (lng: string) => {
    handleChangeLng(lng);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span onClick={(e) => handleChange("ru")}>Ru</span>,
    },
    {
      key: "2",
      label: <span onClick={(e) => handleChange("uz")}>Uz</span>,
    },
    {
      key: "3",
      label: <span onClick={(e) => handleChange("en")}>En</span>,
    },
  ];

  const dropdownContent = () => {
    return (
      <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [
            `${
              localStorage.getItem("lng") === "ru"
                ? "1"
                : localStorage.getItem("lng") === "uz"
                ? "2"
                : localStorage.getItem("lng") === "en"
                ? "3"
                : "1"
            }`,
          ],
        }}
        className="me-2"
        placement="bottomLeft"
      >
        <Button
          style={{ textTransform: "capitalize" }}
          className="d-flex align-items-center"
        >
          {localStorage.getItem("lng") ? localStorage.getItem("lng") : "Ru"}
          {/* <img
            style={{ width: "20px" }}
            className="ms-2"
            src="https://res.cloudinary.com/apple-angren/image/upload/v1672383305/my-icons/527-5275877_-png_fyn2m5.png"
            alt=""
          /> */}
        </Button>
      </Dropdown>
    );
  };

  return (
    <Fragment>
      <Row className="pt-4">
        {isAuth ? (
          <Col span={12}>
            <div className={styles.profileBox}>
              {loading ? (
                <ImageMobile
                  src="/404"
                  width={40}
                  height={40}
                  className="img-fluid rounded-circle img-40"
                  style={{ marginRight: "12px" }}
                />
              ) : (
                <img
                  src={
                    userData?.photoUrl
                      ? userData?.photoUrl?.startsWith("https")
                        ? userData?.photoUrl
                        : IMAGE_PATH + userData?.photoUrl
                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                  alt=""
                  className="img-fluid rounded-circle img-40"
                />
              )}
              <span className={`${isLight ? "textBlue" : "textDark"}`}>
                {userData?.fullname}
              </span>
            </div>
          </Col>
        ) : (
          <Col span={12}>
            <Link to={"/login"}>
              <Button type="primary">{t("Login")}</Button>
            </Link>
          </Col>
        )}

        {isAuth ? (
          <Col
            span={12}
            className="d-flex justify-content-end align-items-center pe-2"
          >
            {dropdownContent()}
            <Link to={"notifications"}>
              <Badge count={count}>
                <BellOutline style={{ fontSize: "25px", color: "#4265f6" }} />
              </Badge>
            </Link>
          </Col>
        ) : (
          <Col
            span={12}
            className="d-flex justify-content-end align-items-center pe-2"
          >
            {dropdownContent()}
          </Col>
        )}
      </Row>
    </Fragment>
  );
};

export default ProfilePic;
