import instance from "../../configs/axios";
import { IMAGE_PATH } from "../../constants";
import {
  EducationTypes,
  ParamsType,
  WorkExperienceTypes,
} from "../../interfaces/index.interface";
import { FilteredDataType } from "../../pages/Home/interface/index.interface";
import { TypeProjectItem } from "../../pages/Profile/Pages/Project/interfaces/index.interface";
import { SkilsUpdateType } from "../../pages/Profile/Pages/Skills";

interface data {
  text: string;
}

interface PostSkillType {
  skills: number[];
}

// Profile Image
const getProfileImage = (url: string) => {
  return instance({
    method: "get",
    url: `${IMAGE_PATH + url}`,
  });
};

// Languages
const getAllLngs = () => {
  return instance({
    method: "get",
    url: "/languages",
  });
};
const getStudentLngs = () => {
  return instance({
    method: "get",
    url: "/student-languages",
  });
};
const postLanguages = (data: data) => {
  return instance({
    method: "put",
    url: "/student-languages",
    data: data,
  });
};
const getLanguageLevels = () => {
  return instance({
    method: "get",
    url: "/language-levels",
  });
};

// Area and Skills
const getAllAre = () => {
  return instance({
    method: "get",
    url: `/area/get`,
  });
};
const getAllSkillsGroup = () => {
  return instance({
    method: "get",
    url: "/skill/group-by-areas",
  });
};
const getSkillsByAreaId = (id?: number | string) => {
  return instance({
    method: "get",
    url: `/skill/get?area_id=${id}`,
  });
};
const getStudentSkills = () => {
  return instance({
    method: "get",
    url: "/student/skills",
  });
};
const postSkill = (data: SkilsUpdateType[]) => {
  return instance({
    method: "put",
    url: "/student/skills",
    data: data,
  });
};

// Certificate
const postCertificate = (data: any) => {
  return instance({
    method: "post",
    url: "/student/certificates",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};
const updateCertificate = (data: any, id: number) => {
  return instance({
    method: "put",
    url: `/student/certificates/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
  });
};
const deleteCertificate = (id: number) => {
  return instance({
    method: "delete",
    url: `/student/certificates/${id}`,
  });
};
const getAllCertificate = () => {
  return instance({
    method: "get",
    url: "/student/certificates",
  });
};
const getCertificateById = (id: number) => {
  return instance({
    method: "get",
    url: `/student/certificates/${id}`,
  });
};

// Work Experience
const postWorkExperience = (data: WorkExperienceTypes) => {
  return instance({
    method: "post",
    url: "/student/work-experiences",
    data: data,
  });
};
const getAllWorkExperience = () => {
  return instance({
    method: "get",
    url: "/student/work-experiences",
  });
};
const getWorkExperienceById = (id: string | undefined) => {
  return instance({
    method: "get",
    url: `/student/work-experiences/${id}`,
  });
};
const deleteWorkExperience = (id: number | undefined) => {
  return instance({
    method: "delete",
    url: `/student/work-experiences/${id}`,
  });
};
const updateWorkExperience = (id: number, data: WorkExperienceTypes) => {
  return instance({
    method: "put",
    url: `/student/work-experiences/${id}`,
    data: data,
  });
};

// Get Job and Company On Type
const getJobOnType = (value: string) => {
  return instance({
    method: "get",
    url: `/student/areas?searchAny=${value}`,
  });
};
const getCompanyOnType = (value: string) => {
  return instance({
    method: "get",
    url: `/student/companies?searchAny=${value}`,
  });
};

// Education
const getAllEducation = () => {
  return instance({
    method: "get",
    url: "/student/educations",
  });
};
const deleteEduation = (id: number) => {
  return instance({
    method: "delete",
    url: `/student/educations/${id}`,
  });
};
const postEducation = (data: EducationTypes) => {
  return instance({
    method: "post",
    url: "/student/educations",
    data: data,
  });
};
const updateEducation = (id: number, data: EducationTypes) => {
  return instance({
    method: "put",
    url: `/student/educations/${id}`,
    data: data,
  });
};
const getEducationById = (id: string) => {
  return instance({
    method: "get",
    url: `/student/educations/${id}`,
  });
};
const getUniversitiesOnType = (value: string) => {
  return instance({
    method: "get",
    url: `/universities?searchAny=${value}`,
  });
};

// Get Faculties By Id
const getFacultiesById = (id: number) => {
  return instance({
    method: "get",
    url: `faculties?universityId=${id}`,
  });
};

// My project
const getAllProjects = (params: any) => {
  return instance({
    method: "get",
    url: `/student/projects`,
    params: params,
  });
};
const updateProject = (
  id: number,
  data: TypeProjectItem,
  isBetaProject?: boolean
) => {
  return instance({
    method: "put",
    url: `/student/projects/${id}`,
    data: data,
    params: {
      isBetaProject,
    },
  });
};
const postProject = (data: TypeProjectItem, isBetaProject?: boolean) => {
  return instance({
    method: "post",
    url: "/student/projects",
    data: data,
    params: {
      isBetaProject,
    },
  });
};
const joinProject = (
  data: { skillIds: number[] },
  id: number,
  isBetaProject: boolean = false
) => {
  return instance({
    method: "post",
    url: `/student/projects/${id}/join`,
    data: data,
    params: {
      isBetaProject,
    },
  });
};
const deleteProject = (id: number | undefined, isBetaProject?: boolean) => {
  return instance({
    url: `/student/projects/${id}`,
    method: "delete",
    params: {
      isBetaProject,
    },
  });
};
const leaveProject = (id: number | undefined, isBetaProject?: boolean) => {
  return instance({
    url: `/student/projects/${id}/leave`,
    method: "post",
    params: {
      isBetaProject,
    },
  });
};
const getProjectById = (id: string, isBetaProject?: boolean) => {
  return instance({
    method: "get",
    url: `/student/projects/${id}`,
    params: {
      isBetaProject,
    },
  });
};
const projectExist = (
  name?: string,
  id?: string | null,
  isBetaProject?: boolean
) => {
  return instance({
    url: `student/projects/exists?name=${name}${id ? `&id=${id}` : ""}`,
    method: "get",
    params: {
      isBetaProject,
    },
  });
};
const projectLinkExist = (
  link?: string,
  id?: string | null,
  isBetaProject?: boolean
) => {
  return instance({
    url: `student/projects/exists?link=${link}${id ? `&id=${id}` : ""}`,
    method: "get",
    params: {
      isBetaProject,
    },
  });
};

// Notifications
const getAllNotif = () => {
  return instance({
    url: "/student/notifications",
  });
};
const getUnreadNotif = () => {
  return instance({
    url: "/student/notifications/unread-count",
  });
};
const getNotifById = (id: string | undefined) => {
  return instance({
    url: `/student/notifications/${id}`,
  });
};
const getUnreadNotifChat = () => {
  return instance({
    url: "/student/chats/unread-count",
  });
};

// Get Users
const getUsers = (
  page?: number | null,
  size?: number | null,
  searchAny?: string | null,
  projectId?: number | null
) => {
  return instance({
    url: `student/users`,
    method: "get",
    params: { page, size, searchAny, projectId },
  });
};
const getAllStudents = (data: ParamsType) => {
  return instance({
    url: "/student/users/connection",
    params: data,
  });
};
const getStudentById = (id?: string) => {
  return instance({
    url: `/user/${id}`,
  });
};

// Get Statistics
const getStatistics = () => {
  return instance({
    url: "/students/statistic",
  });
};

// Get Vacany
const getVacanies = (data: FilteredDataType) => {
  console.log("🚀 ~ file: index.tsx:355 ~ getVacanies ~ data:", data);
  return instance({
    url: "/student/job-position/filter",
    params: data,
  });
};
const getVacanyById = (id: string | undefined) => {
  return instance({
    url: `/student/job-position/${id}`,
  });
};
const postApplyJob = (id: string | undefined) => {
  return instance({
    url: `/student/job-position/${id}`,
    method: "post",
  });
};
const postSaveJob = (id: string | undefined) => {
  return instance({
    url: `/student/favorite?job_id=${id}`,
    method: "post",
  });
};

// Messages (Chats)
interface DataChat {
  type: string;
  page?: number;
  size?: number;
}
interface DataChatLists {
  hrId: number;
  page?: number;
  size?: number;
}
const getAllChats = (data: DataChat) => {
  return instance({
    url: `/student/chats`,
    params: data,
  });
};
const getListsOfChat = (data: DataChatLists) => {
  return instance({
    url: `/student/chats/company-sublist`,
    params: data,
  });
};
export interface TypeAllMessages {
  type: "PRIVATE" | "GROUP" | "CHANNEL";
  toUserId?: string;
  jobPositionId?: string;
  page?: number;
  size?: number;
}
export interface TypePostMessage {
  toUserId?: string;
  jobPositionId?: string;
}
const getAllMessages = (data: TypeAllMessages) => {
  return instance({
    url: `/student/messages`,
    params: data,
  });
};
const postMessage = (data: TypePostMessage, mess: any) => {
  return instance({
    method: "post",
    url: `/student/messages`,
    params: data,
    data: mess,
  });
};
const checkHasNewMessage = (data: TypeAllMessages) => {
  return instance({
    url: `/student/messages/check-has-new-message`,
    params: data,
  });
};

// Get all companies
const getAllCompanies = (data: ParamsType) => {
  return instance({
    url: `/student/companies/all`,
    params: data,
  });
};
const getCompanyById = (id: number) => {
  return instance({
    url: `/student/companies/${id}`,
  });
};
// Like press
const getLikes = (id?: string) => {
  return instance({
    url: `/student/like-as/exists/${id}`,
  });
};
export type TypePostLike = {
  as_like?: boolean;
};
const postLike = (id?: string, param?: TypePostLike) => {
  return instance({
    method: "post",
    url: `/student/like-as/press/${id}`,
    params: param,
  });
};
export type TypeVoteMemeber = {
  toId: number;
  likeVote: boolean;
};
const voteMember = (
  id?: string,
  data?: TypeVoteMemeber,
  isBetaProject?: boolean
) => {
  return instance({
    method: "post",
    url: `/student/projects/${id}/vote-member`,
    params: {
      isBetaProject,
    },
    data: data,
  });
};

export {
  getCompanyById,
  getUnreadNotifChat,
  getAllProjects,
  voteMember,
  projectLinkExist,
  postLike,
  getLikes,
  getAllCompanies,
  getStudentById,
  getAllStudents,
  checkHasNewMessage,
  postMessage,
  getAllMessages,
  getListsOfChat,
  getAllChats,
  getUsers,
  getProfileImage,
  getAllLngs,
  getStudentLngs,
  postLanguages,
  getLanguageLevels,
  getAllAre,
  getAllSkillsGroup,
  getSkillsByAreaId,
  getStudentSkills,
  postSkill,
  postCertificate,
  getAllCertificate,
  getCertificateById,
  updateCertificate,
  deleteCertificate,
  postWorkExperience,
  getAllWorkExperience,
  deleteWorkExperience,
  getWorkExperienceById,
  updateWorkExperience,
  getJobOnType,
  getCompanyOnType,
  getAllEducation,
  deleteEduation,
  postEducation,
  updateEducation,
  getEducationById,
  getUniversitiesOnType,
  getFacultiesById,
  updateProject,
  postProject,
  getProjectById,
  projectExist,
  deleteProject,
  leaveProject,
  getUnreadNotif,
  getAllNotif,
  getNotifById,
  getStatistics,
  getVacanies,
  getVacanyById,
  postApplyJob,
  postSaveJob,
  joinProject,
};
