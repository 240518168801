import GoBack from "../../../../Components/Buttons/GoBack";
import Container from "../../../../layout/Container";
import CustomEmpty from "../../../../Components/Empty";
import Typography from "../../../../Components/Typography";
import { useGetAllData } from "../../../../hooks/useGetAllData";
import {
  TypeDataProject,
  TypeProjectItem,
  UseGetAllDataType,
} from "./interfaces/index.interface";
import { Button, Card, Col, Drawer, Pagination, Row, Tag, message } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { mapSkeleton } from "../../../../utils/MapSkeleton";
import { useNavigate } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import { useState } from "react";
import DeleteDrawer from "../../../../utils/DeleteDrawer";
import LineIphone from "../../../../assets/icons/LineIphone";
import { deleteProject, leaveProject } from "../../../../api/profile";
import { ParamsType } from "../../../../interfaces/index.interface";
import { useTranslation } from "react-i18next";

interface TypeOpen {
  delete: boolean;
  leave: boolean;
}

const InitialStateOpen: TypeOpen = {
  delete: false,
  leave: false,
};

const Project = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 5,
  });
  const [open, setOpen] = useState<TypeOpen>(InitialStateOpen);
  const [id, setId] = useState<number | undefined>(0);

  const { data, loading, error, setData, fetchData }: UseGetAllDataType =
    useGetAllData({
      url: "student/projects",
      params: {
        page: params.page,
        size: params.size,
        my: true,
      },
    });
  const navigate = useNavigate();

  const openDelete = (id?: number | undefined, type?: "delete" | "leave") => {
    setId(id);

    switch (type) {
      case "delete":
        setOpen((prevState) => ({
          ...prevState,
          delete: true,
        }));
        break;

      case "leave":
        setOpen((prevState) => ({
          ...prevState,
          leave: true,
        }));
        break;

      default:
        break;
    }
  };

  const handlerPag = (e: number) => {
    setParams((prevState) => ({
      ...prevState,
      page: e,
    }));
  };

  const onClose = (type: "delete" | "leave") => {
    switch (type) {
      case "delete":
        setOpen((prevState) => ({
          ...prevState,
          delete: false,
        }));
        break;

      case "leave":
        setOpen((prevState) => ({
          ...prevState,
          leave: false,
        }));
        break;

      default:
        break;
    }
  };

  const handlerSubmit = async (type: "delete" | "leave") => {
    try {
      const res =
        type === "delete" ? await deleteProject(id) : await leaveProject(id);
      const filtered: TypeProjectItem[] = data?.items?.filter(
        (item: any) => item.id !== id
      );
      fetchData();

      message.success(
        `${type === "delete" ? t("deleted") : t("you_are_leaved")}`
      );
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      console.log("🚀 ~ file: index.tsx:52 ~ handlerSubmit ~ error:", error);
    } finally {
      setOpen((prevState) => ({
        ...prevState,
        delete: false,
        leave: false,
      }));
    }
  };
  return (
    <Container type="fluid">
      <GoBack extraIcon={{ path: "add" }} to={"/profile"} />

      <div className="container">
        {/* Title */}
        <Typography title={t("my_projects")} variant="h2" />

        {/* List */}
        <Row>
          {loading ? (
            Array.from({ length: 2 }).map((item: any, index: number) =>
              mapSkeleton(index)
            )
          ) : data?.items?.length ? (
            data.items.map((item: TypeProjectItem, index: number) => (
              <Col span={24} key={index} className={"mb-2"}>
                <Card
                  actions={
                    item.isOwner
                      ? [
                          <EditOutlined
                            key="edit"
                            onClick={() =>
                              navigate(
                                `${String(item.id)}?isOwner=${item.isOwner}`
                              )
                            }
                          />,

                          <DeleteOutlined
                            onClick={() => openDelete(item?.id, "delete")}
                          />,
                        ]
                      : [
                          <EditOutlined
                            key="edit"
                            onClick={() =>
                              navigate(
                                `${String(item.id)}?isOwner=${item.isOwner}`
                              )
                            }
                          />,
                          <LogoutOutlined
                            onClick={() => openDelete(item?.id, "leave")}
                          />,
                        ]
                  }
                >
                  <Tag
                    color={`${item.isOwner ? "blue" : "orange"}`}
                    style={{ position: "absolute", right: "20px" }}
                  >
                    {item.isOwner ? t("owner") : t("joined")}
                  </Tag>
                  <Meta
                    title={item.name}
                    description={`${t("from")} ${item.startDate} ${
                      item.endDate !== null
                        ? `${t("to")} ${item.endDate}`
                        : t("until_now")
                    }`}
                  />
                </Card>
              </Col>
            ))
          ) : error ? (
            <h1>{error}</h1>
          ) : (
            <CustomEmpty type="customize" />
          )}
        </Row>

        {/* Pagination */}
        {data?.items?.length ? (
          <Row className="justify-content-center my-3">
            <Pagination
              current={params.page}
              total={data?.meta?.totalCount}
              pageSize={data?.meta?.size}
              onChange={handlerPag}
            />
          </Row>
        ) : (
          ""
        )}

        {/* Delete Drawer */}
        <Drawer
          height={250}
          placement={"bottom"}
          closable={false}
          onClose={() => onClose("delete")}
          open={open.delete}
          key={"bottom"}
          className={`drawerModal`}
        >
          <div className="wrapper text-center">
            <LineIphone className={"drawerBlueLine"} />
            <h3 className={`mt-2 danger`}>{t("delete_project")}</h3>
            <p className="mb-4">{t("warning_delete_project")}</p>
            <Button
              type="primary"
              className="w-100 mb-2"
              htmlType="submit"
              style={{ height: 48 }}
              onClick={() => handlerSubmit("delete")}
              loading={loading}
            >
              {t("yes")}
            </Button>
            <Button
              type="default"
              className="w-100"
              style={{ height: 48 }}
              onClick={() => onClose("delete")}
            >
              {t("cancel")}
            </Button>
          </div>
        </Drawer>

        {/* Leave Drawer */}
        <Drawer
          height={250}
          placement={"bottom"}
          closable={false}
          onClose={() => onClose("leave")}
          open={open.leave}
          key={"bottom"}
          className={`drawerModal`}
        >
          <div className="wrapper text-center">
            <LineIphone className={"drawerBlueLine"} />
            <h3 className={`mt-2 warning`}>{t("leave_project")}</h3>
            <p className="mb-4">{t("warning_leave_project")}</p>
            <Button
              type="primary"
              className="w-100 mb-2"
              htmlType="submit"
              style={{ height: 48 }}
              onClick={() => handlerSubmit("leave")}
              loading={loading}
            >
              {t("yes")}
            </Button>
            <Button
              type="default"
              className="w-100"
              style={{ height: 48 }}
              onClick={() => onClose("delete")}
            >
              {t("cancel")}
            </Button>
          </div>
        </Drawer>
      </div>
    </Container>
  );
};

export default Project;
