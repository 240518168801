import { Card, Col, Empty, Modal, Row } from "antd";
import { Modal as ModalMobile } from "antd-mobile";
import { Fragment, useState } from "react";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack";
import {
  deleteWorkExperience,
  getWorkExperienceById,
} from "../../../../api/profile";
import { WorkExperienceTypes } from "../../../../interfaces/index.interface";
import { mapSkeleton } from "../../../../utils/MapSkeleton";
import Typography from "../../../../Components/Typography";
import DeleteDrawer from "../../../../utils/DeleteDrawer";
import Container from "../../../../layout/Container";
import { useGetAllData } from "../../../../hooks/useGetAllData";
import { useTranslation } from "react-i18next";
import CustomEmpty from "../../../../Components/Empty";

const { Meta } = Card;

const WorkExperience = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedData, setClickedData] = useState<WorkExperienceTypes>();
  const [id, setId] = useState<number | undefined>(0);
  const listData = useGetAllData({
    url: "/student/work-experiences",
  });

  const toUpdatePage = (id: number | undefined) => {
    navigate(`${id}`);
  };

  const openDelete = (id: number | undefined) => {
    setOpen(true);
    setId(id);
  };

  // Modal view

  const showModal = async (id: number | undefined) => {
    // setIsModalOpen(true);

    try {
      const res = await getWorkExperienceById(String(id));
      const data: WorkExperienceTypes = res?.data?.data;
      ModalMobile.alert({
        header: (
          <div>
            <div className="d-flex justify-content-between">
              <h6 className="me-2">Company name:</h6>
              <span className="truncate" style={{ textAlign: "right" }}>
                {data.companyName}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="me-2">Job type:</h6>
              <span className="truncate" style={{ textAlign: "right" }}>
                {data.jobType}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="me-2">Start date:</h6>
              <span>{data.startDate}</span>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="me-2">End date:</h6>
              <span>{data.endDate ? data.endDate : "Now"}</span>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="me-2">Duration:</h6>
              <span>
                {data?.duration >= 12
                  ? Math.floor(data?.duration / 12) +
                    " year " +
                    (data?.duration % 12) +
                    " months"
                  : data?.duration + " months"}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <h6 className="me-2">Description:</h6>
              <span className="truncate" style={{ textAlign: "right" }}>
                {data.description}
              </span>
            </div>
          </div>
        ),
        showCloseButton: false,
        confirmText: "Close",
      });
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:42 ~ showModal ~ error:", error);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // Modal view

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack extraIcon={{ path: "add" }} to={"/profile"} />

      <div className={`container`}>
        {/* Title */}

        <Typography title={t("work_experience")} colSpan={24} />

        {/* List */}
        <Row>
          {listData.loading ? (
            Array.from({ length: 2 }).map((item: any, index: number) =>
              mapSkeleton(index)
            )
          ) : listData.data?.length ? (
            listData.data.map((item: WorkExperienceTypes, index: number) => (
              <Col span={24} key={index} className={"mb-2"}>
                <Card
                  actions={[
                    // <EyeOutlined onClick={() => showModal(item.id)} />,
                    <EditOutlined
                      key="edit"
                      onClick={() => toUpdatePage(item.id)}
                    />,
                    <DeleteOutlined onClick={() => openDelete(item.id)} />,
                  ]}
                >
                  <Meta
                    title={item.companyName}
                    description={`${t("from")} ${item.startDate} ${
                      item.endDate !== null
                        ? `${t("to")} ${item.endDate}`
                        : t("until_now")
                    }`}
                  />
                </Card>
              </Col>
            ))
          ) : listData.error ? (
            <h1>{listData.error}</h1>
          ) : (
            <CustomEmpty />
          )}
        </Row>

        {/* Delete Drawe */}
        <DeleteDrawer
          title={String(t("delete_work_experience"))}
          description={String(t("warning_delete_work_experience"))}
          open={open}
          setOpen={setOpen}
          id={id}
          url={`/student/work-experiences/${id}`}
          data={listData.data}
          setData={listData.setData}
        />

        {/* Modal */}
        <Modal
          title="Details:"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          footer={null}
        >
          {clickedData ? (
            <div>
              <div className="d-flex">
                <h6 className="me-2">Company name:</h6>
                <span>{clickedData.companyName}</span>
              </div>
              <div className="d-flex">
                <h6 className="me-2">Job type:</h6>
                <span>{clickedData.jobType}</span>
              </div>
              <div className="d-flex">
                <h6 className="me-2">Start date:</h6>
                <span>{clickedData.startDate}</span>
              </div>
              <div className="d-flex">
                <h6 className="me-2">End date:</h6>
                <span>{clickedData.endDate ? clickedData.endDate : "Now"}</span>
              </div>
              <div className="d-flex">
                <h6 className="me-2">Duration:</h6>
                <span>
                  {clickedData?.duration >= 12
                    ? Math.floor(clickedData?.duration / 12) +
                      " year " +
                      (clickedData?.duration % 12) +
                      " months"
                    : clickedData?.duration + " months"}
                </span>
              </div>
              <div className="d-flex">
                <h6 className="me-2">Description:</h6>
                <span>{clickedData.description}</span>
              </div>
            </div>
          ) : (
            <Empty />
          )}
        </Modal>
      </div>
    </Container>
  );
};

export default WorkExperience;
