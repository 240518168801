import React from "react";
import { Link } from "react-router-dom";
import ArrowRight from "../../../assets/icons/ArrowRight";
import styles from "./styles.module.scss";

const NextBtn = (props: any) => {
  const { path, onclick } = props;
  return (
    <Link to={path} onClick={onclick}>
      <div className={styles.btn}>
        <ArrowRight />
      </div>
    </Link>
  );
};

export default NextBtn;
