import React, { useState } from "react";
import Container from "../../../../layout/Container";
import GoBack from "../../../../Components/Buttons/GoBack";
import Typography from "../../../../Components/Typography";
import CustomEmpty from "../../../../Components/Empty";
import { MetaType, ParamsType } from "../../../../interfaces/index.interface";
import { getAllCompanies } from "../../../../api/profile";
import { DotLoading, InfiniteScroll } from "antd-mobile";
import {
  Avatar,
  Button,
  Card,
  Col,
  Drawer,
  Empty,
  Image,
  Row,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import LineIphone from "../../../../assets/icons/LineIphone";
import { IMAGE_PATH } from "../../../../constants";
import { useTranslation } from "react-i18next";

interface ItemType {
  logo?: string;
  webSite: string;
  address?: string;
  name: string;
  id: number;
}

interface DataType {
  meta: MetaType;
  items: ItemType[];
}

const AllCompanies = () => {
  const { t } = useTranslation();
  const [list, setList] = useState<ItemType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });
  const [loadingFollow, setLoadingFollow] = useState(false);
  const [openFollow, setOpenFollow] = useState(false);
  const [id, setId] = useState<number>(Number);

  const loadMore = async () => {
    try {
      setLoading(true);

      const res = await getAllCompanies(params);
      const data: DataType = res?.data?.data;
      console.log("🚀 ~ file: index.tsx:36 ~ loadMore ~ data:", data);
      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));

      setList((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMore(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>{t("no_more")}</span>
        )}
      </>
    );
  };

  // Drawer
  const follow = async () => {
    try {
      setLoadingFollow(true);
      // const res = await postApplyJob(String(id));
      // listJobs[jobIndex].applied = true;
      message.success("Followed");
    } catch (error: any) {
      const err = error.response.data.message;
      message.error(err);
      console.log("🚀 ~ file: index.tsx:79 ~ applyJob ~ error:", error);
    } finally {
      setOpenFollow(false);
      setLoadingFollow(false);
    }
  };

  const onCloseApply = () => {
    setOpenFollow(false);
  };

  const onShowFollow = (id: number) => {
    setOpenFollow(true);
    setId(id);
  };

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack to={"/"} />

      <div className="container">
        {/* Title */}
        <Typography title={t("all_companies")} variant="h2" />

        {/* List */}
        <Row>
          {list?.length ? (
            list?.map((item, index) => (
              <Col span={12} key={index}>
                <Card className="mt-2 me-2 text-center">
                  <Link
                    to={String(item.id)}
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    <div className="w-100 d-flex justify-content-center">
                      {item.logo ? (
                        <Avatar
                          src={IMAGE_PATH + "/" + item.logo}
                          style={{
                            width: "50px",
                            height: "50px",
                            boxShadow:
                              "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                          }}
                        />
                      ) : (
                        <Avatar
                          style={{
                            width: "50px",
                            height: "50px",
                            boxShadow:
                              "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <UserOutlined />
                        </Avatar>
                      )}
                    </div>

                    <h4 className="truncateW100 h6 mt-3 mb-0">{item.name}</h4>
                    <p className="truncateW100 mt-0">{item.address}</p>
                  </Link>
                  {/* <Button
                      type="primary"
                      className="w-100"
                      onClick={() => onShowFollow(item.id)}
                    >
                      Follow
                    </Button> */}
                  {/* <Meta
                      avatar={
                        <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                      }
                      title="Card title"
                      description="This is the description"
                    /> */}
                </Card>
              </Col>
            ))
          ) : loading ? (
            ""
          ) : (
            <CustomEmpty createBtn={false} />
          )}
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            className="text-center w-100"
          >
            {list?.length ? <InfiniteScrollContent hasMore={hasMore} /> : ""}
          </InfiniteScroll>
        </Row>
      </div>

      <Drawer
        height={240}
        placement={"bottom"}
        closable={false}
        onClose={onCloseApply}
        open={openFollow}
        key={"bottom"}
        className={`drawerModal`}
      >
        <div className="wrapper text-center">
          <LineIphone className={"drawerBlueLine"} />
          <h3 className={`mt-2 mb-4`}>Do you want to follow this company?</h3>
          {/* <p className="mb-4">description</p> */}
          <Button
            type="primary"
            className="w-100 mb-2"
            htmlType="submit"
            style={{ height: 48 }}
            onClick={follow}
            loading={loadingFollow}
          >
            OK
          </Button>
          <Button
            type="default"
            className="w-100"
            style={{ height: 48 }}
            onClick={() => setOpenFollow(false)}
          >
            CANCEL
          </Button>
        </div>
      </Drawer>
    </Container>
  );
};

export default AllCompanies;
