import React, { useEffect, useState } from "react";
import Container from "../../layout/Container";
import GoBack from "../Buttons/GoBack";

import { Button, Col, Form, Row, Select, Spin, Tag } from "antd";
import Typography from "../Typography";
import CustomEmpty from "../Empty";
import SaveBtn from "../Buttons/SaveBtn";
import { getAllSkillsGroup } from "../../api/profile";
import { AreaSkillTypes, SkillTypes } from "../../interfaces/index.interface";
import { useTranslation } from "react-i18next";

const { Option } = Select;

type PropType = {
  handleCancel: () => void;
  allAreaAndSkill?: AreaSkillTypes[];
  appliedSkills: number[];
  setappliedSkills: (e: any) => void;
  trigger?: number;
  selectedSkills: number[];
  setselectedSkills: (e?: any) => void;
  setAllAreaAndSkill: (e?: any) => void;
};

const SkillsList = (props: PropType) => {
  const { t } = useTranslation();
  const {
    setappliedSkills,
    appliedSkills,
    allAreaAndSkill,
    handleCancel,
    trigger,
    selectedSkills,
    setselectedSkills,
    setAllAreaAndSkill,
  } = props;
  const [form] = Form.useForm();
  const ringer = require("../../assets/audios/Mouse-Click-00-c-FesliyanStudios (mp3cut.net).mp3");
  const audio = new Audio(ringer);
  audio.loop = false;

  const [areaId, setAreaId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);

  // const [allAreaAndSkill, setAllAreaAndSkill] = useState<AreaSkillTypes[]>();

  const [selectedAreaAndSkill, setSelectedAreaAndSkill] = useState<
    AreaSkillTypes[]
  >([]);

  const [selectedArea, setSelectedArea] = useState<AreaSkillTypes[]>();

  const [studentSkillsLength, setStudentSkillsLength] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        // const res = await getAllSkillsGroup();
        // setAllAreaAndSkill(res?.data?.data);

        // const res_student = await getStudentSkills();
        // setStudentSkills(res_student?.data?.data);
        // setStudentSkillsLength(res_student?.data?.data?.length);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:16 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  // console.log(
  //   "🚀 ~ file: index.tsx:39 ~ SkillsList ~ allAreaAndSkill:",
  //   allAreaAndSkill
  // );

  const onAreChange = (values: number) => {
    setAreaId(values);
    const filtered = allAreaAndSkill?.filter(
      (item: AreaSkillTypes) => item.id === values
    );
    setSelectedArea(filtered);
    // console.log("🚀 ~ file: index.tsx:8 ~ onGenderChange ~ values:", values);
  };

  // const [test, setTest] = useState<Set<number>>(new Set<number>());

  // function handleAdd(value: number) {
  //   const newSet = new Set(test);
  //   newSet.add(value);
  //   setTest(newSet);
  // }

  // function handleRemove(value: number) {
  //   const newSet = new Set(test);
  //   newSet.delete(value);
  //   setTest(newSet);
  // }

  const handlerTag = (skillId: number, areId: number) => {
    // console.log("🚀 ~ file: index.tsx:70 ~ handlerTag ~ id:", id);
    if (selectedSkills.includes(skillId)) {
      // handleRemove(areId);
      setselectedSkills(selectedSkills.filter((item) => item !== skillId));
    } else {
      setselectedSkills((prevState: any) => [...prevState, skillId]);
      // handleAdd(areId);
      // const filteredData = allAreaAndSkill?.filter(
      //   (item: AreaSkillTypes) => item.id === areId
      // );
    }
    // console.log("🚀 ~ file: index.tsx:80 ~ SkillsList ~ test:", test);
    // console.log(studentSkills);

    // audio.play();
  };

  const mapSkill = (data: AreaSkillTypes) => {
    return (
      <div key={data.id} className="mb-3">
        <h5 className="mb-1">{data.name}</h5>
        <div>
          {data.skills.map((skill: SkillTypes, index: number) => (
            <Tag
              onClick={() => handlerTag(skill.id, data.id)}
              color={`${
                selectedSkills?.includes(skill.id) ? "#108ee9" : "default"
              }`}
              className="py-2 px-3 mb-2"
              key={skill.id}
              style={{
                fontSize: "14px",
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
            >
              {skill.name}
            </Tag>
          ))}
        </div>
      </div>
    );
  };

  const closeModal = () => {
    handleCancel();

    setselectedSkills(appliedSkills);

    setTimeout(() => {
      setSelectedArea(allAreaAndSkill);
      setAreaId(null);
      form.resetFields();
    }, 1000);

    // setAllAreaAndSkill(allAreaAndSkill);
    // setselectedSkills(allAreaAndSkill);
  };

  useEffect(() => {
    if (trigger) {
      closeModal();
    }
  }, [trigger]);

  const saveSkill = async () => {
    setappliedSkills(selectedSkills);
    handleCancel();
    // if (studentSkillsLength === studentSkills?.length) {
    // message.warning("Skill already saved");
    // } else {
    // setStudentSkillsLength(selectedSkills?.length);
    // try {
    //   setLoadingBtn(true);
    //   const data = {
    //     skills: selectedSkills,
    //   };
    //   console.log("🚀 ~ file: index.tsx:91 ~ saveSkill ~ data:", data);
    //   const res = await postSkill(data);
    //   message.success("Skill successfullys saved");
    // } catch (error: any) {
    //   console.log("🚀 ~ file: index.tsx:116 ~ saveSkill ~ error:", error);
    // } finally {
    //   setLoadingBtn(false);
    // }
    // }
  };
  return (
    <div>
      {/* {loading && <Loader />} */}
      {/* Title */}
      {/* <Typography title="Add Skill" variant="h2" /> */}

      <Form layout="vertical" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item name="area" required={false}>
              <Select
                placeholder={t("select_area")}
                onChange={onAreChange}
                // allowClear
                defaultValue={areaId}
                value={areaId}
              >
                <Option value={null}>{t("all_areas")}</Option>
                {allAreaAndSkill &&
                  allAreaAndSkill.map((item: AreaSkillTypes, index: number) => (
                    <Option value={item.id} key={item.id}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            {selectedArea?.length ? (
              selectedArea.map((item: AreaSkillTypes, index: number) =>
                mapSkill(item)
              )
            ) : allAreaAndSkill?.length ? (
              allAreaAndSkill.map((item: AreaSkillTypes, index: number) =>
                mapSkill(item)
              )
            ) : loading ? (
              <Spin className="d-block mt-5" />
            ) : (
              <CustomEmpty type="customize" />
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24} className="d-flex">
            <Button
              onClick={saveSkill}
              loading={loadingBtn}
              type="primary"
              className="w-100"
            >
              {t("apply")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SkillsList;
