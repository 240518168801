import { Button, Col, Form, Input, Row } from "antd";
import { Modal } from "antd-mobile";
import { CloseCircleOutline, ExclamationCircleFill } from "antd-mobile-icons";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postForgotPass } from "../../api/auth";
import LogoCircle from "../../assets/icons/LogoCircle";
import { SET_USER_EMAIL } from "../../redux/actionTypes";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type ValuesType = {
  email: string;
};

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { isLight } = useSelector((state: any) => state.uiReducer);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = async (values: ValuesType) => {
    try {
      setLoading(true);
      dispatch({
        type: SET_USER_EMAIL,
        payload: {
          email: values.email,
          type: "forgot",
        },
      });
      const res = await postForgotPass(values.email);
      navigate("/check-your-email");
    } catch (err: any) {
      console.log("🚀 ~ file: index.tsx:23 ~ onFinish ~ err:", err);
      Modal.alert({
        header: (
          <CloseCircleOutline
            style={{
              fontSize: 64,
              color: "var(--adm-color-danger)",
            }}
          />
        ),
        title: err?.response?.data?.message,
        showCloseButton: true,
        confirmText: "Ok",
      });
    } finally {
      setLoading(false);
    }
  };

  const skip = () => navigate("/login");
  return (
    <Fragment>
      <Form onFinish={onFinish}>
        {/* Inputs */}
        <Row>
          <Col span={24}>
            <div className="text-center">
              {/* <Link to={"/"}> */}
              <LogoCircle
                class={`${styles.logo} ${isLight ? "logoLight" : "logoDark"}`}
              />
              {/* </Link> */}
              <h1>{t("forgot_password")}</h1>
              <p className="px-4">{t("forgot_warning")}</p>
            </div>
          </Col>

          <Col span={24}>
            <Form.Item
              className="px-3"
              label={t("email")}
              name="email"
              required={false}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
              ]}
            >
              <Input allowClear placeholder="Brandonelouis@gmail.com" />
            </Form.Item>
          </Col>
        </Row>

        {/* Send verification code */}
        <Row className="d-flex justify-content-between px-3 mt-4">
          <Col span={24} className="mb-2">
            <Button
              htmlType="submit"
              className="w-100 py-4 d-flex justify-content-center align-items-center"
              type="primary"
              loading={loading}
            >
              {t("send_verification_code")}
            </Button>
          </Col>
          <Col span={24}>
            <Button
              onClick={skip}
              className="w-100 py-4 d-flex justify-content-center align-items-center"
            >
              {t("back_to_login")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default ForgotPassword;
