import { IS_ENTERED, SET_USER_EMAIL } from "./../actionTypes";
import { ACCESS_TOKEN } from "./../../constants/index";
import {
  LOG_OUT,
  SET_ALL_DATA,
  SET_IS_AUTH,
  SET_USER_DATA,
} from "../actionTypes";

type PropType = {
  type: string;
  payload: any;
};

type InitialState = {
  isAuth: Boolean;
  userData: any;
  permissions: string[];
  email: string;
  type: string;
};

const initial_state: InitialState = {
  isAuth: false,
  userData: null,
  permissions: ["HOME", "PROFILE", "internship_index"],
  email: "",
  type: "",
};

export const authReducer = (state = initial_state, action: PropType) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case SET_ALL_DATA:
      return {
        isAuth: action.payload.isAuth,
        userData: action?.payload?.data,
        permissions: action?.payload?.data?.permissions ?? ["HOME", "PROFILE"],
      };
    case SET_IS_AUTH:
      return {
        ...state,
        isAuth: action.payload,
      };
    case LOG_OUT:
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(IS_ENTERED);
      return {};
    case SET_USER_EMAIL:
      return {
        ...state,
        email: action.payload.email,
        type: action.payload.type,
      };

    default:
      return { ...state };
  }
};
