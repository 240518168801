import dayjs from "dayjs";

export const disabledDate = (current: any, form: any, month: number) => {
  const selectedDate = dayjs(form.getFieldValue("startDate"));

  return (
    dayjs(selectedDate).add(month, "month") >= current ||
    dayjs().add(month, "month") <= dayjs(selectedDate)
  );

  // return current && current < dayjs().endOf("day");
};
