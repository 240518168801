import { useGoogleLogin } from "@react-oauth/google";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Modal as ModalAntd,
} from "antd";
import { Modal } from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postLoginOauth2, postSignup } from "../../api/auth";
import LogoCircle from "../../assets/icons/LogoCircle";
import {
  ACCESS_TOKEN,
  USER_AGREEMENT_EN,
  USER_AGREEMENT_RU,
  USER_AGREEMENT_UZ,
} from "../../constants";
import { SET_ALL_DATA, SET_USER_EMAIL } from "../../redux/actionTypes";
import google from "../../assets/icons/source/google.svg";
import appStore from "../../assets/icons/source/appStore.svg";
import { useTranslation } from "react-i18next";

type ValuesType = {
  email: string;
  password: string;
  fullname: string;
};

const SignUp = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const onChange = (e: CheckboxChangeEvent) => {
    // console.log(`checked = ${e.target.checked}`);
  };

  const onFinish = async (values: ValuesType) => {
    const data = {
      email: values.email,
      password: values.password,
      fullname: values.fullname,
    };

    if (checked) {
      try {
        setLoading(true);
        dispatch({
          type: SET_USER_EMAIL,
          payload: {
            email: values.email,
          },
        });
        const res = await postSignup(data);
        navigate("/check-your-email");
      } catch (err: any) {
        message.error(
          err?.response?.data?.message
            ? err?.response?.data?.message
            : err?.response?.data?.error
        );
        console.log("🚀 ~ file: index.tsx:39 ~ onFinish ~ err:", err);
      } finally {
        setLoading(false);
      }
    } else {
      Modal.alert({
        header: (
          <div>
            <ExclamationCircleFill
              style={{
                fontSize: 64,
                color: "var(--adm-color-warning)",
              }}
            />
          </div>
        ),
        title: t("terms_of_agreement_warning"),
        showCloseButton: true,
        confirmText: "Ok",
      });
    }
  };

  const googleAuth = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res: any = await postLoginOauth2(response?.access_token);
        const token = res?.data?.data?.token;

        localStorage.setItem(ACCESS_TOKEN, token);

        dispatch({
          type: SET_ALL_DATA,
          payload: {
            isAuth: true,
            data: res?.data?.data,
          },
        });

        message.success("Login success");
        navigate(`/`);
      } catch (error: any) {
        Modal.alert({
          header: (
            <ExclamationCircleFill
              style={{
                fontSize: 64,
                color: "var(--adm-color-warning)",
              }}
            />
          ),
          title: error?.response?.data?.message,
          showCloseButton: true,
          confirmText: "Ok",
        });
      }
    },
  });

  const skip = () => navigate("/");

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      setChecked(false);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setChecked(true);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
    setChecked(false);
  };

  return (
    <Fragment>
      <ModalAntd
        centered
        title={t("terms_of_agreement")}
        open={isModalOpen}
        onOk={handleOk}
        footer={false}
        onCancel={handleCancelModal}
      >
        <div style={{ height: "70vh", overflowX: "scroll" }}>
          <p>{USER_AGREEMENT_RU}</p>
          <p>{USER_AGREEMENT_EN}</p>
          <p>{USER_AGREEMENT_UZ}</p>
          <Button type="primary" className="w-100" onClick={handleCancel}>
            {t("agree")}
          </Button>
        </div>
      </ModalAntd>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {/* Inputs */}
        <Row>
          {/* Full name */}
          <Col span={24}>
            <div className="text-center">
              {/* <Link to={"/"}> */}
              <LogoCircle
                class={`logo ${isLight ? "logoLight" : "logoDark"}`}
              />
              {/* </Link> */}
              <h2>{t("welcome_to_onecruit")}</h2>
              <p className="px-4">{t("welcome_intro_subtitle")}</p>
            </div>

            <Form.Item
              className="px-3"
              label={t("fullname")}
              name="fullname"
              required={false}
              rules={[
                {
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
              ]}
            >
              <Input allowClear placeholder="Brandone Louis" />
            </Form.Item>
          </Col>

          {/* Email */}
          <Col span={24}>
            <Form.Item
              className="px-3"
              label={t("email")}
              name="email"
              required={false}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
              ]}
            >
              <Input allowClear placeholder="Brandonelouis@gmail.com " />
            </Form.Item>
          </Col>

          {/* Password */}
          <Col span={24}>
            <Form.Item
              name="password"
              label={t("password")}
              className="px-3"
              hasFeedback
              required={false}
              rules={[
                {
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
              ]}
            >
              <Input.Password placeholder="*******" />
            </Form.Item>
          </Col>

          {/* Confirm password */}
          <Col span={24}>
            <Form.Item
              name="confirm"
              label={t("confirm_password")}
              className="px-3"
              dependencies={["password"]}
              hasFeedback
              required={false}
              rules={[
                {
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="*******" />
            </Form.Item>
          </Col>
        </Row>

        {/* Forgot & Checkbox */}
        <Row>
          <Col span={24}>
            <div className="px-3 d-flex justify-content-start">
              <Form.Item
                name="agreement"
                required={false}
                // rules={[
                //   {
                //     required: true,
                //     message: `${t("please_fill_the_field")}`,
                //   },
                // ]}
              >
                <Checkbox checked={checked} onChange={onChangeCheckbox}>
                  {t("terms_of_agreement")}
                </Checkbox>
              </Form.Item>
            </div>
          </Col>
        </Row>

        {/* Login & Skip */}
        <Row className="d-flex justify-content-between px-3 mt-1">
          <Col span={12} className="pe-2 mb-3">
            <Button
              htmlType="submit"
              className="w-100 py-4 d-flex justify-content-center align-items-center"
              loading={loading}
              type="primary"
            >
              {t("sign_up")}
            </Button>
          </Col>
          <Col span={12} className="ps-2">
            <Button
              onClick={skip}
              className="w-100 py-4 d-flex justify-content-center align-items-center"
            >
              {t("skip")}
            </Button>
          </Col>
        </Row>

        {/* Google & Apple */}
        <Row className="px-3">
          <Col span={12} className="pe-2">
            <Button
              className="me-3 w-100 py-4 d-flex justify-content-center align-items-center"
              onClick={() => googleAuth()}
            >
              <img src={google} alt="" className="me-2" />
              {t("sign_in_with_google")}
            </Button>
          </Col>
          <Col span={12} className="ps-2">
            <Button className="w-100 py-4 d-flex justify-content-center align-items-center">
              <img src={appStore} alt="" className="me-2" />
              {t("sign_in_with_apple")}
            </Button>
          </Col>
        </Row>

        {/* Sign up */}
        <Row>
          <Col span={24}>
            <p className="text-center mt-3">
              {t("do_you_have_account")} <Link to={"/login"}>{t("Login")}</Link>
            </p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default SignUp;
