import { Button, Col, Form, message, Row } from "antd";
import { NumberKeyboard, PasscodeInput } from "antd-mobile";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postCheckEmail } from "../../api/auth";
import LogoCircle from "../../assets/icons/LogoCircle";
import { ACCESS_TOKEN } from "../../constants";
import { SET_ALL_DATA } from "../../redux/actionTypes";
import { useTranslation } from "react-i18next";

const CheckEmail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [password, setPassword] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { isLight } = useSelector((state: any) => state.uiReducer);
  const { email, type } = useSelector((state: any) => state.authReducer);

  const verifyEmail = async () => {
    if (password && password.length >= 6) {
      setError(false);

      try {
        setLoading(true);
        console.log(type);

        const res = await postCheckEmail(email, password);
        let token = res?.data?.data?.token;

        dispatch({
          type: SET_ALL_DATA,
          payload: {
            isAuth: true,
            data: res?.data?.data?.user,
          },
        });

        localStorage.setItem(ACCESS_TOKEN, token);

        // message.success("Login success");

        navigate(`${type === "forgot" ? "/change-password" : "/"}`);

        // console.log("🚀 ~ file: index.tsx:23 ~ verifyEmail ~ res:", res);
      } catch (err: any) {
        message.error(err?.response?.data?.message);
        setError(true);
        console.log("🚀 ~ file: index.tsx:23 ~ verifyEmail ~ err:", err);
      } finally {
        setLoading(false);
      }
    } else {
      setError(true);
    }
  };

  const handlerPassword = (e: any) => {
    setPassword(e);
    if (e.length >= 6) {
      setError(false);
    } else {
      //   setError(true);
    }
  };

  const skip = () => navigate("/login");
  return (
    <Fragment>
      {/* Inputs */}
      <Row>
        <Col span={24}>
          <div className="text-center">
            {/* <Link to={"/"}> */}
            <LogoCircle class={`logo ${isLight ? "logoLight" : "logoDark"}`} />
            {/* </Link> */}
            <h1>Check Your Email</h1>
            <p className="px-4">We send code to your email. Please check it.</p>
          </div>
        </Col>

        <Col span={24} className="text-center">
          <PasscodeInput
            plain
            onChange={handlerPassword}
            length={6}
            error={error}
          />
        </Col>
      </Row>

      {/* Login & Skip */}
      <Row className="d-flex justify-content-between px-3 mt-4">
        <Col span={24} className="mb-2">
          <Button
            htmlType="submit"
            className="w-100 py-4 d-flex justify-content-center align-items-center"
            type="primary"
            onClick={verifyEmail}
            loading={loading}
          >
            Verify your email
          </Button>
        </Col>
        <Col span={24}>
          <Button
            onClick={skip}
            className="w-100 py-4 d-flex justify-content-center align-items-center"
          >
            Back to login
          </Button>
        </Col>
      </Row>

      {/* Time */}
      {/* <Row>
        <Col span={24}>
          <p className="text-center mt-3">
            Time - 2:58 If you don’t take sms code <Link to={"/"}>Resend</Link>
          </p>
        </Col>
      </Row> */}
    </Fragment>
  );
};

export default CheckEmail;
