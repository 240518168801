import { Button, Col, Drawer, Row } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import AboutMe from "../../../../assets/icons/AboutMe";
import LineIphone from "../../../../assets/icons/LineIphone";
import GoBack from "../../../../Components/Buttons/GoBack";
import List from "../../Components/List";
import styles from "./styles.module.scss";
import { IList } from "../../Components/List/list.interface";
import { useDispatch, useSelector } from "react-redux";
import "../../../../i18next";
import { useTranslation } from "react-i18next";
import { CHANGE_THEME, LOG_OUT } from "../../../../redux/actionTypes";
import { useNavigate } from "react-router-dom";
import { deleteMe } from "../../../../api/auth";

import Appearance from "../../../../Components/Appearance";
import Container from "../../../../layout/Container";
import Password from "../../../../assets/icons/Password";
import Logout from "../../../../assets/icons/Logout";
import Delete from "../../../../assets/icons/Delete";

const Settings = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [openAccount, setOpenAccount] = useState(false);
  const { isLight, type } = useSelector((state: any) => state.uiReducer);
  const [active, setActive] = useState(
    type === "light" ? 1 : type === "dark" ? 2 : 3
  );

  const list: IList[] = [
    {
      icon: <Password />,
      title: `${t("password")}`,
      path: "update-password",
    },
    {
      icon: <Logout />,
      title: `${t("logout")}`,
      onClick: showDrawerLogOut,
    },
    {
      icon: <Delete className="bg-danger" />,
      title: `${t("delete_account")}`,
      onClick: showDrawerDeleteAcc,
    },
  ];

  function showDrawerLogOut() {
    setOpen(true);
  }
  function showDrawerDeleteAcc() {
    setOpenAccount(true);
  }

  const onClose = () => {
    setOpen(false);
  };

  const onCloseDeleteAcc = () => {
    setOpenAccount(false);
  };

  const handlerLogOut = () => {
    dispatch({
      type: LOG_OUT,
    });
    setOpen(false);
    navigate("/");
  };

  const handlerDeleteAcc = async () => {
    try {
      const res = await deleteMe();
      dispatch({
        type: LOG_OUT,
      });
      setOpenAccount(false);
      navigate("/");
    } catch (error) {
      console.log(
        "🚀 ~ file: Settings.tsx:64 ~ handlerDeleteAcc ~ error:",
        error
      );
    }
  };

  // console.log("isLight: ", isLight);
  const changeTheme = (id: number) => {
    // console.log(type);
    // console.log(window.matchMedia("(prefers-color-scheme: light)").matches);
    dispatch({
      type: CHANGE_THEME,
      payload: id,
    });

    setActive(id);
  };

  // Languages

  // useEffect(() => {
  //   localStorage.setItem("lng", "ru");
  // }, []);

  const handleChangeLng = (lng: any) => {
    console.log("🚀 ~ file: Settings.tsx:108 ~ handleChangeLng ~ lng:", lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleChange = (e: any) => {
    console.log(e.target.value);
    handleChangeLng(e.target.value);
  };

  return (
    <Container type="fluid">
      <GoBack to={"/profile"} />
      <div
        className={`container ${styles.container} ${
          isLight ? styles.light : styles.dark
        }`}
      >
        <h2
          className="mb-2 px-2"
          style={{
            borderBottom: "1px solid #80808059",
            padding: "0 0 10px 0",
          }}
        >
          {t("settings")}
        </h2>

        <Row>
          <Col span={24}>
            <List list={list} bgIcon={true} clasName="pb-0" />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Drawer
              height={250}
              placement={"bottom"}
              closable={false}
              onClose={onClose}
              open={open}
              key={"bottom"}
              className={styles.drawer}
            >
              <div className="wrapper text-center">
                <LineIphone className={"drawerBlueLine"} />
                <h3>{t("log_out")}</h3>
                <p className="mb-4">{t("warning_log_out")}</p>
                <Button
                  type="primary"
                  className="w-100 mb-2"
                  htmlType="submit"
                  style={{ height: 48 }}
                  onClick={handlerLogOut}
                >
                  {t("yes")}
                </Button>
                <Button
                  type="default"
                  className="w-100"
                  style={{ height: 48 }}
                  onClick={() => setOpen(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </Drawer>
          </Col>
          <Col span={24}>
            <Drawer
              height={250}
              placement={"bottom"}
              closable={false}
              onClose={onCloseDeleteAcc}
              open={openAccount}
              key={"bottom"}
              className={styles.drawer}
            >
              <div className="wrapper text-center">
                <LineIphone className={"drawerBlueLine"} />
                <h3 className={`mt-2 ${styles.deleteTitle}`}>
                  {t("delete_account")}
                </h3>
                <p className="mb-4">{t("warning_delete_account")}</p>
                <Button
                  type="primary"
                  className="w-100 mb-2"
                  htmlType="submit"
                  style={{ height: 48 }}
                  onClick={handlerDeleteAcc}
                >
                  {t("yes")}
                </Button>
                <Button
                  type="default"
                  className="w-100"
                  style={{ height: 48 }}
                  onClick={() => setOpenAccount(false)}
                >
                  {t("cancel")}
                </Button>
              </div>
            </Drawer>
          </Col>
        </Row>

        {/* Appearance */}
        {/* <Row className="px-2 mb-2">
          <Col span={24} className="mb-2">
            <h2
              style={{
                borderBottom: "1px solid #80808059",
                padding: "0 0 10px 0",
              }}
            >
              Apearance
            </h2>
          </Col>
          <Col span={24} className="d-flex justify-content-between">
            <Appearance
              mode="light"
              active={active === 1}
              onClick={() => changeTheme(1)}
            />
            <Appearance
              mode="dark"
              active={active === 2}
              onClick={() => changeTheme(2)}
            />
            <Appearance
              mode="system"
              active={active === 3}
              onClick={() => changeTheme(3)}
            />
          </Col>
        </Row> */}

        {/* Languages */}
        <Row className="px-2">
          <Col span={24}>
            <h2
              className="mb-2"
              style={{
                borderBottom: "1px solid #80808059",
                padding: "0 0 10px 0",
              }}
            >
              {t("languages")}
            </h2>
            <select
              id="selectLang"
              onChange={handleChange}
              style={{
                width: "100%",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #4265f6",
                fontWeight: "bold",
              }}
            >
              <option
                value="ru"
                selected={localStorage.getItem("lng") == "ru" ? true : false}
              >
                RU
              </option>
              <option
                value="uz"
                selected={localStorage.getItem("lng") == "uz" ? true : false}
              >
                UZ
              </option>

              <option
                value="en"
                selected={localStorage.getItem("lng") == "en" ? true : false}
              >
                EN
              </option>
            </select>
            {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta
            sequi laudantium ipsum voluptas vero, tempore saepe unde eius
            obcaecati dolores nesciunt ad distinctio expedita. Eum et suscipit
            voluptate sequi voluptatibus?
          </p> */}
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Settings;
