// dashbaord
import ChangePassword from "../auth/ChangePassword";
import CheckEmail from "../auth/CheckEmail";
import ForgotPassword from "../auth/ForgotPassword";
import Login from "../auth/Login";
import PasswordUpdated from "../auth/PasswordUpdated";
import SignUp from "../auth/SignUp";
import Header from "../layout/Header";

import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";

import List from "../pages/Profile/Components/List";
import MyProfile from "../pages/Profile/Pages/MyProfile";
import PleaseLogin from "../pages/PleaseLogin";
import Profile from "../pages/Profile";
import AboutMe from "../pages/Profile/Pages/AboutMe";
import Skills from "../pages/Profile/Pages/Skills";

import Settings from "../pages/Profile/Pages/Settings/Settings";

import AddWorkExperience from "../pages/Profile/Pages/WorkExperience/AddWorkExperience";
import WorkExperience from "../pages/Profile/Pages/WorkExperience";

import Education from "../pages/Profile/Pages/Education";
import AddEducation from "../pages/Profile/Pages/Education/AddEducation";
import BetaProject from "../pages/Profile/Pages/BetaProject";
import AddBetaProject from "../pages/Profile/Pages/BetaProject/AddBetaProject";
import Project from "../pages/Profile/Pages/Project";
import AddProject from "../pages/Profile/Pages/Project/AddProject";
import Certificate from "../pages/Profile/Pages/Certificate";
import UploadCertificate from "../pages/Profile/Pages/Certificate/UploadCertificate";
import Language from "../pages/Profile/Pages/Language";
import UpdatePassword from "../pages/Profile/Pages/UpdatePassword";
import Notifications from "../pages/Home/Pages/Notifications";
import NotificationView from "../pages/Home/Pages/Notifications/NotificationView";
import ApplyVacany from "../pages/Home/Pages/ApplyVacany";

import Connection from "../pages/Connection";
import AllJobs from "../pages/Home/Pages/AllJobs";
import AllInternships from "../pages/Home/Pages/AllInternships";
import AllCompanies from "../pages/Home/Pages/AllCompanies";
import UsersChats from "../pages/UsersChats";
import ChatLists from "../pages/UsersChats/ChatLists";
import Chat from "../pages/UsersChats/ChatLists/Chat";
import StudentProfile from "../pages/Connection/StudentProfile";
import ViewCompany from "../pages/Home/Pages/AllCompanies/ViewCompany";
import MyStatistics from "../pages/Profile/Pages/MyStatistics";

export const routes = [
  // {
  //   name: "My company",
  //   key: "MY_COMPANY",
  //   path: "/dashboard",
  //   component: MyCompany,
  //   config: {
  //     layout: "layout",
  //     isSidebar: true,
  //     isAuth: true,
  //   },
  // },
  {
    name: "Header",
    key: "",
    path: `/`,
    component: Header,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Login",
    key: "",
    path: `/login`,
    component: Login,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Signup",
    key: "",
    path: `/sign-up`,
    component: SignUp,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Check your email",
    key: "",
    path: `/check-your-email`,
    component: CheckEmail,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Forgot password",
    key: "",
    path: `/forgot-password`,
    component: ForgotPassword,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Change password",
    key: "",
    path: `/change-password`,
    component: ChangePassword,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Success",
    key: "",
    path: `/password_updated`,
    component: PasswordUpdated,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Home",
    key: "",
    path: `/`,
    component: Home,
    config: {
      layout: "layout",
      isSidebar: true,
      isAuth: false,
    },
  },
  {
    name: "Connection",
    key: "",
    path: `connection`,
    component: Connection,
    config: {
      layout: "layout",
      isSidebar: true,
      isAuth: false,
    },
  },
  {
    name: "Connection by id",
    key: "",
    path: `connection/:userID`,
    component: StudentProfile,
    config: {
      layout: "none-layout",
      isSidebar: true,
      isAuth: false,
    },
  },
  {
    name: "Users chats",
    key: "",
    path: `chat`,
    component: UsersChats,
    config: {
      layout: "layout",
      isSidebar: true,
      isAuth: false,
    },
  },
  {
    name: "Chat lists",
    key: "",
    path: `chat/:id`,
    component: ChatLists,
    config: {
      layout: "none-layout",
      isSidebar: true,
      isAuth: false,
    },
  },
  {
    name: "Chats of list",
    key: "",
    path: `chat/:userId/:listId`,
    component: Chat,
    config: {
      layout: "none-layout",
      isSidebar: true,
      isAuth: false,
    },
  },
  {
    name: "Project",
    key: "",
    path: `profile/project`,
    component: Project,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Add project",
    key: "",
    path: `profile/project/add`,
    component: AddProject,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Add project",
    key: "",
    path: `profile/project/:id`,
    component: AddProject,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Project",
    key: "",
    path: `profile/project`,
    component: Project,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Beta project",
    key: "",
    path: `profile/beta-project`,
    component: BetaProject,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Add beta project",
    key: "",
    path: `profile/beta-project/add`,
    component: AddBetaProject,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Update beta project",
    key: "",
    path: `profile/beta-project/:id`,
    component: AddBetaProject,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Certificate",
    key: "",
    path: `profile/certificate`,
    component: Certificate,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Upload certificate",
    key: "",
    path: `profile/certificate/upload`,
    component: UploadCertificate,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Upload certificate",
    key: "",
    path: `profile/certificate/:certificateId`,
    component: UploadCertificate,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Profile",
    key: "",
    path: `profile`,
    component: Profile,
    config: {
      layout: "layout",
      isSidebar: true,
      isAuth: true,
    },
    children: [
      // {
      //   name: "List",
      //   key: "",
      //   path: `profile`,
      //   component: List,
      //   config: {
      //     layout: "layout",
      //     isSidebar: true,
      //     isAuth: true,
      //   },
      // },
    ],
  },
  {
    name: "Please login",
    key: "",
    path: `profile/404`,
    component: PleaseLogin,
    config: {
      layout: "layout",
      isSidebar: true,
      isAuth: true,
    },
  },
  {
    name: "My statistics",
    key: "",
    path: `profile/my-statistics`,
    component: MyStatistics,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "About me",
    key: "",
    path: `profile/about-me`,
    component: AboutMe,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "About me",
    key: "",
    path: `profile/edit`,
    component: MyProfile,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Skills",
    key: "",
    path: `profile/skills`,
    component: Skills,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Language",
    key: "",
    path: `profile/language`,
    component: Language,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Settings",
    key: "",
    path: `profile/settings`,
    component: Settings,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Settings",
    key: "",
    path: `my-profile/settings`,
    component: Settings,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Update password",
    key: "",
    path: `profile/update-password`,
    component: UpdatePassword,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Work experience",
    key: "",
    path: `profile/work-experience`,
    component: WorkExperience,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Add work experience",
    key: "",
    path: `profile/work-experience/add`,
    component: AddWorkExperience,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Edit work experience",
    key: "",
    path: `profile/work-experience/:id`,
    component: AddWorkExperience,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "My Education",
    key: "",
    path: `profile/education`,
    component: Education,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Add Education",
    key: "",
    path: `profile/education/add`,
    component: AddEducation,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Update Education",
    key: "",
    path: `profile/education/:id`,
    component: AddEducation,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Notifications",
    key: "",
    path: `notifications`,
    component: Notifications,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Notifications",
    key: "",
    path: `notifications/:notificationId`,
    component: NotificationView,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Apply vacany",
    key: "",
    path: `/job/:id`,
    component: ApplyVacany,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "All jobs",
    key: "",
    path: `all-jobs`,
    component: AllJobs,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "All internships",
    key: "",
    path: `all-internships`,
    component: AllInternships,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "All companies",
    key: "",
    path: `all-companies`,
    component: AllCompanies,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
  {
    name: "Company view",
    key: "",
    path: `all-companies/:id`,
    component: ViewCompany,
    config: {
      layout: "none-layout",
      isSidebar: false,
      isAuth: false,
    },
  },
];
