import { Fragment, useEffect, useState } from "react";
import LogoCircle from "../../assets/icons/LogoCircle";
import NextBtn from "../../Components/Buttons/NextBtn";
import Loader from "../../layout/Loader";
import styles from "./styles.module.scss";
import courseApp from "../../assets/images/svg/CourseApp.svg";
import { Col, Row } from "antd";

const Intro = () => {
  const [hiddeIntro, setHiddeIntro] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHiddeIntro(false);
    }, 1000);
  }, []);

  return (
    <Fragment>
      {hiddeIntro && <Loader />}
      <div className={`${styles.container} p-3`}>
        <Row className="align-items-center">
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            className={`${styles.logo} text-center`}
          >
            <LogoCircle class={styles.logoLight} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className="text-center">
              <img src={courseApp} alt="" className={styles.courseApp} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <div className={styles.content}>
              <h1 className={`${styles.title} text-start`}>
                Find Your <br /> <span>Dream Job</span> Here!
              </h1>
              <p className="mt-4 mb-5">
                Explore all the most exciting job roles <br /> based on your
                interest and study major.
              </p>
            </div>
            <NextBtn path="/login" />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default Intro;
