import React from "react";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import Login from "../../auth/Login";
import Intro from "../../pages/Intro";

const Header = () => {
  const { isLight } = useSelector((state: any) => state.uiReducer);

  return (
    <div
      className={`${styles.header} ${
        isLight ? styles.headerLight : styles.headerDark
      }`}
    >
      <Intro />
    </div>
  );
};

export default Header;
