import { IS_ENTERED, SET_ENTERED } from "../actionTypes";

interface initialState {
  isEntered: Boolean;
}
const initial_state: initialState = {
  isEntered: localStorage.getItem(IS_ENTERED)
    ? Boolean(localStorage.getItem(IS_ENTERED))
    : false,
};

export const enteredReducer = (state = initial_state, action: any) => {
  switch (action.type) {
    case SET_ENTERED: {
      localStorage.setItem(IS_ENTERED, "true");
      return {
        ...state,
        isEntered: state.isEntered,
      };
    }

    default:
      return { ...state };
  }
};
