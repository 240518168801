import styles from "./styles.module.scss";

const Add = (props: any) => {
  return (
    <span
      className={`icon ${styles.icon} ${props?.class ? props.class : ""}`}
    />
  );
};

export default Add;
