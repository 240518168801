import React, { useState } from "react";
import Container from "../../layout/Container";
import GoBack from "../../Components/Buttons/GoBack";
import Typography from "../../Components/Typography";
import Search from "antd/es/input/Search";
import { Avatar, Badge, Card, Col, Row } from "antd";
import { useGetAllData } from "../../hooks/useGetAllData";
import { DotLoading, InfiniteScroll } from "antd-mobile";
import { MetaType, ParamsType } from "../../interfaces/index.interface";
import { getAllChats } from "../../api/profile";
import { IMAGE_PATH } from "../../constants";
import dayjs from "dayjs";
import Meta from "antd/es/card/Meta";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Company {
  id: number;
  ownerId: number;
  name: string;
  logo: string;
}
interface ItemType {
  unreadCount: number;
  company: Company;
  lastTime: string;
}

interface DataChat {
  type: string;
  page?: number;
  size?: number;
}

interface DataType {
  meta: MetaType;
  items: ItemType[];
}

const UsersChats = () => {
  const { t } = useTranslation();
  const [listChatsUser, setListChatsUser] = useState<ItemType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });

  const loadMore = async () => {
    try {
      setLoading(true);

      const dataChat: DataChat = {
        type: "hr",
        page: params.page,
        size: params.size,
      };

      const res = await getAllChats(dataChat);
      const data: DataType = res?.data?.data;
      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      // setFiltered_data((prevState) => ({
      //   ...prevState,
      //   page: data.meta.page + 1,
      // }));

      setListChatsUser((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMore(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value: string) => console.log(value);

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>{t("no_more")}</span>
        )}
      </>
    );
  };

  return (
    <Container type="fluid">
      {/* Go back */}
      {/* <GoBack /> */}

      <div className="container">
        {/* Title */}
        <Typography
          title={t("messages")}
          variant="h2"
          className="text-center mt-3"
          colSpan={24}
        />

        {/* <Row>
          <Col span={24}>
            <Search
              placeholder="input search text"
              onSearch={onSearch}
              enterButton
            />
          </Col>
        </Row> */}

        <Row>
          <div className="w-100">
            {listChatsUser?.length
              ? listChatsUser?.map((item, index) => (
                  <Col span={24} key={index}>
                    {/* <Avatar src={`${IMAGE_PATH + item?.company?.logo}`} /> */}
                    <Link
                      to={String(item.company.ownerId)}
                      style={{ textDecoration: "none" }}
                    >
                      <Card loading={loading} className="my-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center">
                            <Avatar
                              src={`${IMAGE_PATH + "/" + item?.company?.logo}`}
                              className="me-2"
                            />
                            <div>
                              <h6 className="m-0 truncate">
                                {item.company.name}
                              </h6>
                            </div>
                          </div>
                          <div className="text-end">
                            <p className="m-0">
                              {dayjs(item.lastTime).format("DD-MM-YYYY")}
                            </p>
                            {item.unreadCount ? (
                              <Badge count={item.unreadCount} />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Card>
                    </Link>
                  </Col>
                ))
              : loading
              ? ""
              : ""}
          </div>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            className="text-center w-100"
          >
            <InfiniteScrollContent hasMore={hasMore} />
          </InfiniteScroll>
        </Row>
      </div>
    </Container>
  );
};

export default UsersChats;
