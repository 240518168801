import { useEffect, useState } from "react";
import { getProfileImage } from "../api/profile";

type PropType = {
  url?: string;
};

export const useGetImage = (props: PropType) => {
  const { url = "" } = props;
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (url) {
        try {
          setLoading(true);
          const res = await getProfileImage(url);
        } catch (error: any) {
          console.log("🚀 ~ file: useGetImage.ts:16 ~ error:", error);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, []);

  return { loading };
};
