import { Button, Col, Form, Input, Row } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postChangePass } from "../../api/auth";
import LogoCircle from "../../assets/icons/LogoCircle";
import { ACCESS_TOKEN } from "../../constants";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res = await postChangePass(values.password);
      localStorage.removeItem(ACCESS_TOKEN);
      navigate("/password_updated");
    } catch (err) {
      console.log("🚀 ~ file: index.tsx:16 ~ onFinish ~ err:", err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Fragment>
      <Form layout="vertical" onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <div className="text-center">
              {/* <Link to={"/"}> */}
              <LogoCircle
                class={`logoAuth ${isLight ? "logoLight" : "logoDark"}`}
              />
              {/* </Link> */}
              <h1>Change password</h1>
              <p className="px-4">
                Your password has been updated, please change your password
                regularly to avoid this happening
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          {/* Password */}
          <Col span={24}>
            <Form.Item
              name="password"
              label="Password"
              className="px-3"
              hasFeedback
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please fill the field!",
                },
              ]}
            >
              <Input.Password placeholder="*******" />
            </Form.Item>
          </Col>

          {/* Confirm password */}
          <Col span={24}>
            <Form.Item
              name="confirm"
              label="Confirm password"
              className="px-3"
              dependencies={["password"]}
              hasFeedback
              required={false}
              rules={[
                {
                  required: true,
                  message: "Please fill the field!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="*******" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mb-2 px-3">
            <Button
              htmlType="submit"
              className="w-100 py-4 d-flex justify-content-center align-items-center"
              type="primary"
              loading={loading}
            >
              CHANGE PASSWORD
            </Button>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default ChangePassword;
