import { Col, Empty, Form, message, Row, Select, Spin, Tag } from "antd";
import { Fragment, useEffect, useState } from "react";
import {
  getAllSkillsGroup,
  getStudentSkills,
  postSkill,
} from "../../../../api/profile";
import GoBack from "../../../../Components/Buttons/GoBack";
import SaveBtn from "../../../../Components/Buttons/SaveBtn";
import { useTranslation } from "react-i18next";
import Container from "../../../../layout/Container";
import Typography from "../../../../Components/Typography";
import { useNavigate } from "react-router-dom";

interface SkillTypes {
  id: number;
  name: string;
}
interface AreaSkillTypes {
  id: number;
  name: string;
  skills: [SkillTypes];
}
const { Option } = Select;

export interface SkilsUpdateType {
  areaId: number;
  skillName: string;
}

const Skills = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const ringer = require("../../../../assets/audios/Mouse-Click-00-c-FesliyanStudios (mp3cut.net).mp3");
  const audio = new Audio(ringer);
  audio.loop = false;

  const [areaId, setAreaId] = useState<number | string>("all");
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingBtn, setLoadingBtn] = useState<boolean>(false);
  const [allAreaAndSkill, setAllAreaAndSkill] = useState<AreaSkillTypes[]>([]);
  const [selectedAreaAndSkill, setSelectedAreaAndSkill] =
    useState<AreaSkillTypes[]>();
  const [studentSkills, setStudentSkills] = useState<SkilsUpdateType[]>([]);
  const [studentSkillsLength, setStudentSkillsLength] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getAllSkillsGroup();
        setAllAreaAndSkill(res?.data?.data);

        const res_student = await getStudentSkills();
        setStudentSkills(res_student?.data?.data);

        setStudentSkillsLength(res_student?.data?.data?.length);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:16 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const onAreChange = (values: number | string) => {
    setAreaId(values);
    const filtered = allAreaAndSkill?.filter(
      (item: AreaSkillTypes) => item.id === values
    );
    setSelectedAreaAndSkill(filtered);
    // console.log("🚀 ~ file: index.tsx:8 ~ onGenderChange ~ values:", values);
  };

  const isEqualSkill = (a: SkilsUpdateType, b: SkilsUpdateType): boolean =>
    a.areaId === b.areaId && a.skillName === b.skillName;

  const skillExistsInState = (skill: SkilsUpdateType): boolean => {
    return (
      studentSkills.findIndex((item) =>
        // item === skill
        isEqualSkill(skill, item)
      ) !== -1
    );
  };

  const handlerTag = (skill: SkilsUpdateType) => {
    if (skillExistsInState(skill)) {
      setStudentSkills(
        studentSkills.filter((item) => !isEqualSkill(skill, item))
      );
    } else {
      setStudentSkills((prevState) => [...prevState, skill]);
    }
    // console.log(studentSkills);

    // audio.play();
  };

  const mapSkill = (data: AreaSkillTypes) => {
    return (
      <div key={data.id} className="mb-3">
        <h5 className="mb-1">{data.name}</h5>
        <div>
          {data.skills.map((skill: SkillTypes, index: number) => (
            <Tag
              onClick={() =>
                handlerTag({ skillName: skill.name, areaId: data.id })
              }
              color={`${
                skillExistsInState({ skillName: skill.name, areaId: data.id })
                  ? "#108ee9"
                  : "default"
              }`}
              className="py-2 px-3 mb-2"
              key={skill.id}
              style={{
                fontSize: "14px",
                borderRadius: "20px",
                textTransform: "capitalize",
              }}
            >
              {skill.name}
            </Tag>
          ))}
        </div>
      </div>
    );
  };

  const saveSkill = async () => {
    // if (studentSkillsLength === studentSkills?.length) {
    // message.warning("Skill already saved");
    // } else {
    setStudentSkillsLength(studentSkills?.length);
    try {
      setLoadingBtn(true);
      const data = studentSkills;
      const res = await postSkill(data);
      message.success(t("skills_saved"));
      navigate("/profile");
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:116 ~ saveSkill ~ error:", error);
    } finally {
      setLoadingBtn(false);
    }
    // }
  };

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack to={"/profile"} />
      <div className={`container pb-5`}>
        {/* {loading && <Loader />} */}

        {/* Title */}
        <Typography title={t("add_skills")} variant="h2" />

        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item name="area" required={false}>
                <Select
                  placeholder="Select area"
                  onChange={onAreChange}
                  allowClear
                  defaultValue={"all"}
                >
                  <Option value={"all"}>{t("all_areas")}</Option>
                  {allAreaAndSkill &&
                    allAreaAndSkill.map(
                      (item: AreaSkillTypes, index: number) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      )
                    )}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              {selectedAreaAndSkill?.length ? (
                selectedAreaAndSkill.map(
                  (item: AreaSkillTypes, index: number) => mapSkill(item)
                )
              ) : allAreaAndSkill?.length ? (
                allAreaAndSkill.map((item: AreaSkillTypes, index: number) =>
                  mapSkill(item)
                )
              ) : loading ? (
                <Spin className="d-block mt-5" />
              ) : (
                <Empty />
              )}

              {/* {allSkills?.length ? (
              allSkills.map((item: AllSkilGroupType, index: number) => (
                <div key={index} className="mb-3">
                  <h5 className="mb-1" style={{ textTransform: "capitalize" }}>
                    {item.name}
                  </h5>
                  <div>
                    {item.skills.map((skill: SkillType, inx: number) => (
                      <Tag
                        color="default"
                        className="py-2 px-3 mb-2"
                        key={inx}
                        style={{
                          fontSize: "14px",
                          borderRadius: "20px",
                          textTransform: "capitalize",
                        }}
                      >
                        {skill.name}
                      </Tag>
                    ))}
                  </div>
                </div>
              ))
            ) : searchedSkills?.length ? (
              <div>
                {searchedSkills.map(
                  (skill: SearchedSkillsType, index: number) => (
                    <Tag
                      color="processing"
                      className="py-2 px-3"
                      key={index}
                      style={{
                        fontSize: "14px",
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {skill.name}
                    </Tag>
                  )
                )}
              </div>
            ) : loading ? (
              <Spin className="d-block mt-5" />
            ) : (
              <Empty />
            )} */}
            </Col>
          </Row>

          <SaveBtn onClick={saveSkill} loading={loadingBtn}>
            {String(t("save"))}
          </SaveBtn>

          {/* <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.gender !== currentValues.gender}
      > */}
        </Form>
      </div>
    </Container>
  );
};

export default Skills;
