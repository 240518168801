import { CHANGE_THEME, SAVE_JOB_ID } from "../actionTypes";

interface initialState {
  isLight: Boolean;
  type: "light" | "dark" | "system" | "";
  jobId: null | number | string;
}

const theme = localStorage.getItem("theme");

// const initial_state: initialState = {
//   isLight: theme
//     ? theme === "dark"
//       ? false
//       : true
//     : window.matchMedia("(prefers-color-scheme: light)").matches,
//   type: `${
//     theme && theme === "light"
//       ? "light"
//       : theme === "dark"
//       ? "dark"
//       : theme === "system"
//       ? "system"
//       : ""
//   }`,
// };
const initial_state: initialState = {
  isLight: true,
  type: "light",
  jobId: null,
};

export const uiReducer = (state = initial_state, action: any) => {
  switch (action.type) {
    case CHANGE_THEME: {
      action.payload === 1
        ? localStorage.setItem("theme", "light")
        : action.payload === 2
        ? localStorage.setItem("theme", "dark")
        : localStorage.removeItem("theme");
      // localStorage.setItem(
      //   "theme",
      //   `${
      //     action.payload === 1
      //       ? "light"
      //       : action.payload === 2
      //       ? "dark"
      //       : "system"
      //   }`
      // );
      return {
        ...state,
        isLight:
          action.payload === 1
            ? true
            : action.payload === 2
            ? false
            : window.matchMedia("(prefers-color-scheme: light)").matches,
        type:
          action.payload === 1
            ? "light"
            : action.payload === 2
            ? "dark"
            : "system",
      };
    }
    case SAVE_JOB_ID: {
      console.log(action);

      return {
        ...state,
        jobId: action.jobId,
      };
    }
    default:
      return { ...state };
  }
};
