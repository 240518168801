import { Checkbox, Drawer, Empty, Form, Input, Spin } from "antd";
import React, { useEffect, useState } from "react";
import LineIphone from "../../../../../../../assets/icons/LineIphone";
import { getUsers } from "../../../../../../../api/profile";
import { useGetAllData } from "../../../../../../../hooks/useGetAllData";
import { MetaType } from "../../../../../../../interfaces/index.interface";
import CustomEmpty from "../../../../../../../Components/Empty";
import {
  Button,
  DotLoading,
  InfiniteScroll,
  List,
  SearchBar,
} from "antd-mobile";
import styles from "./styles.module.scss";
import { mockRequest } from "./mock-request";
import { IMAGE_PATH } from "../../../../../../../constants";
import useDebounce from "../../../../../../../hooks/useDebounce";
import { InvitedUsersType } from "../../../../Project/interfaces/index.interface";
import { useTranslation } from "react-i18next";
import SaveBtn from "../../../../../../../Components/Buttons/SaveBtn";

const { Search } = Input;

type PropType = {
  open: boolean;
  setOpen: (value: boolean) => void;
  height?: number;
  trigger?: number;
  invitedUsers: InvitedUsersType[];
  setInvitedUsers: any;
  usersIds?: number[];
  setUsersIds: (e: any) => void;
  projectId?: number;
};

interface ParamsType {
  page: number;
  size: number;
  searchAny?: string | null;
}

interface TypeProjectItem {
  fullname: string;
  id: number;
  photoUrl?: string;
}

type TypeData = {
  items: TypeProjectItem[];
  meta: MetaType;
};

type UseGetAllDataType = {
  data: TypeData;
  loading: boolean;
  error: string;
  setData: (data: TypeData) => void;
  fetchData: () => void;
};

const UsersDrawer = (props: PropType) => {
  const { t } = useTranslation();
  const {
    open,
    setOpen,
    height = 450,
    trigger,
    setInvitedUsers,
    invitedUsers,
    usersIds,
    setUsersIds,
    projectId,
  } = props;

  const [form] = Form.useForm();
  const [data, setData] = useState<TypeProjectItem[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });
  const [searchValue, setSearchValue] = useState<string>("");
  const debounce = useDebounce(searchValue);

  const onClose = () => {
    setOpen(false);
    params.page = 1;
    params.size = 10;
    setTimeout(() => {
      setData([]);
    }, 1000);
    setHasMore(true);
    setSearchValue("");
    form.resetFields();
  };

  const loadMore = async () => {
    try {
      setLoading(true);
      const res = await getUsers(
        params.page,
        params.size,
        searchValue && searchValue,
        projectId ? projectId : null
      );
      const data: TypeData = res?.data?.data;
      setParams((prevState) => ({
        ...prevState,
        page: params.page + 1,
      }));
      setData((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:112 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const doSearch = async () => {
    setData([]);
    setHasMore(true);
    loadMore();
  };

  useEffect(() => {
    if (trigger) {
      doSearch();
    }
  }, [trigger]);

  const inviteUser = (id: number, fullname: string, photoUrl?: string) => {
    const first_letter: string = fullname?.split("")?.[0]?.toUpperCase();

    if (usersIds?.includes(id)) {
      setUsersIds(usersIds.filter((item) => item !== id));
      setInvitedUsers(invitedUsers.filter((item) => item.id !== id));
    } else {
      setUsersIds((prevState: any) => [...prevState, id]);
      setInvitedUsers((prevState: any) => [
        ...prevState,
        {
          id,
          nameFirstLetter: first_letter,
          photoUrl: photoUrl,
        },
      ]);
    }
    console.log(usersIds);
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <DotLoading />
          </>
        ) : (
          <span>No yet users</span>
        )}
      </>
    );
  };

  const onFinish = async (values: any) => {
    console.log("🚀 ~ file: index.tsx:210 ~ onFinish ~ values:", values.search);
    setSearchValue(values.search);
    params.page = 1;
    params.size = 10;
    setData([]);
    try {
      setLoading(true);
      const res = await getUsers(
        params.page,
        params.size,
        values.search,
        projectId ? projectId : null
      );
      const data: TypeData = res?.data?.data;
      setParams((prevState) => ({
        ...prevState,
        page: params.page + 1,
      }));
      setData(data.items);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:112 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (e: string) => {
    onFinish({ search: e });
    if (e?.length === 0) {
      console.log("🚀 ~ file: index.tsx:178 ~ onSearch ~ e:", "EMPTY");
      setData([]);
      params.page = 1;
      params.size = 10;
      setSearchValue("");
      // doSearch();
    }
  };
  return (
    <Drawer
      height={height}
      placement={"bottom"}
      closable={false}
      onClose={onClose}
      open={open}
      key={"bottom"}
      className={`drawerModal pt-3`}
    >
      <div style={{ position: "relative" }}>
        <Form
          form={form}
          className="w-100 pb-1"
          onFinish={onFinish}
          style={{
            position: "sticky",
            top: "0",
            zIndex: "5",
            backgroundColor: "#fff",
          }}
        >
          <div className="wrapper text-center">
            <LineIphone className={"drawerBlueLine"} />
          </div>
          <Form.Item
            required={false}
            rules={[{ required: false, message: "Please fill this field!" }]}
            className="w-100 mb-3"
            name="search"
          >
            <Search
              placeholder={String(t("please_enter_employee_name"))}
              // value={searchValue}
              onSearch={onSearch}
              enterButton
              allowClear
              onChange={(e?: any) => onSearch(e.target.value)}
            />
          </Form.Item>
        </Form>

        {data.length > 0 ? (
          <>
            <List>
              {data.map((item, index) => (
                <List.Item key={index}>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    onClick={(e) =>
                      inviteUser(item.id, item.fullname, item.photoUrl)
                    }
                  >
                    <div>
                      <img
                        src={
                          item.photoUrl
                            ? item?.photoUrl?.startsWith("https")
                              ? item.photoUrl
                              : IMAGE_PATH + item.photoUrl
                            : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                        }
                        alt=""
                        style={{ width: "40px", borderRadius: "50%" }}
                        className="me-2"
                      />
                      {item.fullname}
                    </div>
                    <Checkbox
                      onChange={(e) =>
                        inviteUser(item.id, item.fullname, item.photoUrl)
                      }
                      checked={usersIds?.includes(item.id)}
                    />
                    {/* <Button
                      size="small"
                      color={
                        usersIds?.includes(item.id) ? "success" : "warning"
                      }
                      onClick={(e) => inviteUser(item.id)}
                    >
                      {usersIds?.includes(item.id) ? "Done" : "Invite"}
                    </Button> */}
                  </div>
                </List.Item>
              ))}
            </List>
            {/* <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
              <InfiniteScrollContent hasMore={hasMore} />
            </InfiniteScroll> */}
            <div className="text-center mt-3 mb-5">
              {hasMore ? (
                <Button size="small" onClick={loadMore} loading={loading}>
                  Load more
                </Button>
              ) : (
                ""
              )}
            </div>
            <SaveBtn onClick={onClose}>{String(t("apply"))}</SaveBtn>
          </>
        ) : loading ? (
          <div className={styles.placeholder}>
            <div className={styles.loadingWrapper}>
              <DotLoading />
            </div>
            Loading
          </div>
        ) : (
          <Empty style={{ marginTop: "150px" }} />
        )}
      </div>
    </Drawer>
  );
};

export default UsersDrawer;
