import React from "react";
import styles from "./styles.module.scss";

const LogoCircle = (props: any) => {
  return (
    <span
      className={`icon ${styles.logoOnecruit} ${
        props?.class ? props.class : ""
      }`}
    />
  );
};

export default LogoCircle;
