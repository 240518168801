import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AboutMe from "../../../../assets/icons/AboutMe";
import Add from "../../../../assets/icons/Add";
import ArrowRight from "../../../../assets/icons/ArrowRight";
import ArrowRightV2 from "../../../../assets/icons/ArrowRightV2";
import { IList } from "./list.interface";
import styles from "./styles.module.scss";

type propType = {
  list: IList[];
  bgIcon?: Boolean;
  clasName?: string;
};

const List = (props: propType) => {
  const { list, bgIcon, clasName } = props;
  const { isAuth } = useSelector((state: any) => state.authReducer);
  const { isLight } = useSelector((state: any) => state.uiReducer);

  // console.log(window.innerHeight);

  return (
    <div
      style={{ height: "100%" }}
      className={`${styles.row} ${clasName ? clasName : ""}`}
    >
      {list?.map((item: IList, index: number) =>
        !item.onClick ? (
          <Link
            to={`/profile/${isAuth ? item.path : "404"}`}
            style={{ textDecoration: "none" }}
            key={index}
          >
            <div className="row">
              <div className="col">
                <div
                  className={`${styles.list} ${
                    isLight ? styles.light : styles.dark
                  }`}
                >
                  <div className={styles.title}>
                    {item.icon}
                    <p className="m-0">{item.title}</p>
                  </div>
                  <span
                    className={`${styles.icon} ${
                      bgIcon ? styles.black : styles.blue
                    }`}
                  >
                    <ArrowRightV2 />
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <div className="row" onClick={item.onClick} key={index}>
            <div className="col">
              <div
                className={`${styles.list} ${
                  isLight ? styles.light : styles.dark
                }`}
              >
                <div className={styles.title}>
                  {item.icon}
                  <p className="m-0">{item.title}</p>
                </div>
                <span
                  className={`${styles.icon} ${
                    bgIcon ? styles.black : styles.blue
                  }`}
                >
                  <ArrowRightV2 />
                </span>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default List;
