import { PropTypeIcon } from "../../../interfaces/index.interface";
import styles from "./styles.module.scss";

const ArrowLeft = (props: PropTypeIcon) => {
  return (
    <span
      onClick={props?.onClick}
      className={`icon ${styles.arrow} ${
        props?.className ? props.className : ""
      }`}
    />
  );
};

export default ArrowLeft;
