import React, { useEffect, useState } from "react";
import Container from "../../../../../layout/Container";
import GoBack from "../../../../../Components/Buttons/GoBack";
import Typography from "../../../../../Components/Typography";
import { Link, useParams, useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { getCompanyById, getVacanies } from "../../../../../api/profile";
import { Col, Empty, Image, Row, Spin, message } from "antd";
import { IMAGE_PATH } from "../../../../../constants";
import { DotLoading, InfiniteScroll, Tabs } from "antd-mobile";
import { useTranslation } from "react-i18next";
import CustomEmpty from "../../../../../Components/Empty";
import { MetaType } from "../../../../../interfaces/index.interface";
import { ItemType } from "../../../interface/index.interface";
import { useSelector } from "react-redux";

type CompanyType = {
  address: string;
  id: number;
  logo: string | null;
  name: string;
  webSite: string;
};

interface DataType {
  meta: MetaType;
  items: ItemType[];
}

const ViewCompany = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { isAuth } = useSelector((state: any) => state.authReducer);
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [loadingJobs, setLoadingJobs] = useState(true);
  const [data, setData] = useState<CompanyType>();
  const [scaleStep, setScaleStep] = useState(0.5);
  const [visible, setVisible] = useState(false);
  const [listJobs, setListJobs] = useState<ItemType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState<any>({
    page: 1,
    size: 10,
    company_id: id,
  });
  console.log("🚀 ~ file: index.tsx:9 ~ ViewCompany ~ id:", id);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getCompanyById(Number(id));
        const data: CompanyType = res?.data?.data;
        setData(data);
        console.log("🚀 ~ file: index.tsx:17 ~ data:", data);
      } catch (error: any) {
        const err: string = error?.response?.data?.message;
        message.warning(err);
        console.log("🚀 ~ file: index.tsx:16 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const loadMore = async () => {
    try {
      setLoadingJobs(true);

      const res = await getVacanies(params);
      const data: DataType = res?.data?.data;
      setParams((prevState: any) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));

      setListJobs((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMore(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoadingJobs(false);
    }
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>
            {t("no_more")}
            {/* {jobType === "VACANCY" ? "vacancy" : "internships"} */}
          </span>
        )}
      </>
    );
  };

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack shareIcon shareTitle={"share_this_company"} />
      {loading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="content" />
        </Spin>
      ) : data ? (
        <>
          <div className="container">
            {/* Title */}
            {/* <Typography title="Notifications" variant="h2" /> */}

            <Row>
              <Col span={24} className="text-center">
                <div className={styles.imgBox}>
                  <img
                    className={`${styles.img}`}
                    onClick={() => setVisible(true)}
                    src={
                      data.logo
                        ? `${IMAGE_PATH + "/" + data.logo}`
                        : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                    }
                    // src={
                    //   "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    // }
                    alt=""
                  />
                </div>
                <Image
                  style={{ display: "none" }}
                  preview={{
                    visible,
                    scaleStep,
                    src: `${
                      data.logo
                        ? `${IMAGE_PATH + "/" + data.logo}`
                        : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                    }`,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                  src={
                    data.logo
                      ? `${IMAGE_PATH + "/" + data.logo}`
                      : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                  }
                />
              </Col>

              <Col span={24} className="text-center">
                <h1 className={styles.title}>{data.name}</h1>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Tabs
                  style={{ paddingBottom: "40px" }}
                  activeKey={
                    searchParams.get("activeTab")
                      ? searchParams.get("activeTab")
                      : "company"
                  }
                  onChange={(key) => {
                    console.log(key);

                    setSearchParams({
                      activeTab: String(key),
                    });
                  }}
                >
                  <Tabs.Tab title={t("about_company")} key="company">
                    <div className="content pt-2">
                      <div className="ctRow">
                        <h6>{t("company_name")}</h6>
                        <p className="m-0">{data.name}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("website")}:</h6>
                        <a href={data.webSite} target="_blank">
                          {data.webSite}
                        </a>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("address")}:</h6>
                        <p className="m-0">{data.address}</p>
                      </div>
                    </div>
                  </Tabs.Tab>

                  <Tabs.Tab title={t("jobs")} key="jobs">
                    <div className="content pt-2">
                      <div className="w-100">
                        {listJobs?.length ? (
                          listJobs?.map((item, index) => (
                            <Col span={24} key={index}>
                              <div className={styles.card}>
                                <Link
                                  to={`/job/${item.jobId}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <div className={styles.firstRow}>
                                    <span className={styles.companyImg}>
                                      <img
                                        src={
                                          item.photoUrl
                                            ? `${
                                                IMAGE_PATH + "/" + item.photoUrl
                                              }`
                                            : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                                        }
                                        alt=""
                                      />
                                    </span>

                                    <p className="truncateW100 text-end">
                                      {item.name}
                                    </p>

                                    {/* <span className={styles.saveIcon}> */}
                                    {/* <img src={save ? savedBtn : saveBtn} alt="" onClick={saveJob}/> */}
                                    {/* <IconSave /> */}
                                    {/* </span> */}
                                  </div>

                                  <div className={styles.secondRow}>
                                    {/* <p>{item.name}</p> */}
                                    <p className="truncateW100">
                                      {t("company")}:{" "}
                                      <span>{item.company}</span>
                                    </p>
                                    <p className="truncateW100">
                                      {t("addres")}: <span>{item.address}</span>
                                    </p>
                                    <p className="truncateW100">
                                      {t("experience")}:{" "}
                                      <span>{item.experience} Years</span>
                                    </p>
                                  </div>

                                  <div className={styles.tags}>
                                    <span>{item.position}</span>
                                    <span>{item.area}</span>
                                    <span
                                      style={{ textTransform: "lowercase" }}
                                    >
                                      {item.type}
                                    </span>
                                  </div>
                                </Link>

                                <div className={styles.thirdRow}>
                                  <span className={styles.timePost}>
                                    {item.createdAt}
                                  </span>
                                  {/* {item.applied ? "Applied" : ""} */}
                                  <p>
                                    <span>${item.salary}</span>/{t("mo")}
                                  </p>
                                  {isAuth ? (
                                    !item.applied ? (
                                      <>
                                        {/* <Button
                              type="primary"
                              onClick={() => onShowApply(item.jobId, index)}
                            >
                              Apply
                            </Button> */}
                                      </>
                                    ) : (
                                      <span style={{ color: "#1677ff" }}>
                                        {t("applied")}
                                      </span>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </Col>
                          ))
                        ) : loading ? (
                          ""
                        ) : (
                          <Empty className="mt-5" description={t("no_data")} />
                        )}
                      </div>
                      <InfiniteScroll
                        loadMore={loadMore}
                        hasMore={hasMore}
                        className="text-center w-100"
                      >
                        {listJobs?.length ? (
                          <InfiniteScrollContent hasMore={hasMore} />
                        ) : (
                          ""
                        )}
                      </InfiniteScroll>
                    </div>
                  </Tabs.Tab>
                </Tabs>
              </Col>
            </Row>
          </div>

          {/* <SaveBtn onClick={applyJob}>APPLY NOW</SaveBtn> */}
        </>
      ) : (
        <CustomEmpty createBtn={false} />
      )}
    </Container>
  );
};

export default ViewCompany;
