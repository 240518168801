/* THEME */
export const CHANGE_THEME: string = "CHANGE_THEME";
export const SAVE_JOB_ID: string = "SAVE_JOB_ID";

/* CUSTOMIZER */
export const SET_USER_DATA: string = "SET_USER_DATA";
export const SET_ALL_DATA: string = "SET_ALL_DATA";
export const SET_USER_EMAIL: string = "SET_USER_EMAIL";
export const SET_IS_AUTH: string = "SET_IS_AUTH";
export const LOG_OUT: string = "LOG_OUT";
export const IS_ENTERED: string = "IS_ENTERED";

export const SET_ENTERED: string = "SET_ENTERED";
