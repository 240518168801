import { Col, Row } from "antd";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";

type PropType = {
  title: string;
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";
  colSpan?: number;
  mb?: number;
  className?: string;
};

const Typography = (props: PropType) => {
  const { title, variant = "h1", colSpan, mb = 2, className } = props;
  const { isLight } = useSelector((state: any) => state.uiReducer);
  //   className="mb-2 px-2"

  const theme = isLight ? styles.light : styles.dark;
  const drawHeader = (headerType: string) => {
    switch (headerType) {
      case "h1":
        return (
          <h1 className={`${theme} ${className && className}`}>{title}</h1>
        );

      case "h2":
        return (
          <h2 className={`${theme} ${className && className}`}>{title}</h2>
        );

      case "h3":
        return (
          <h3 className={`${theme} ${className && className}`}>{title}</h3>
        );

      case "h4":
        return (
          <h4 className={`${theme} ${className && className}`}>{title}</h4>
        );

      case "h5":
        return (
          <h5 className={`${theme} ${className && className}`}>{title}</h5>
        );

      case "h6":
        return (
          <h6 className={`${theme} ${className && className}`}>{title}</h6>
        );

      default:
        return <p className={`${theme} ${className && className}`}>{title}</p>;
    }
  };
  return (
    <Row>
      <Col span={colSpan} className={`mb-${mb} `}>
        {drawHeader(variant)}
      </Col>
    </Row>
  );
};

export default Typography;
