import { useSelector } from "react-redux";
import styles from "./styles.module.scss";

type PropType = {
  children?: JSX.Element | any;
  type?: "fluid";
  className?: string;
};

const Container = (props: PropType) => {
  const { children, type, className } = props;
  const { isLight } = useSelector((state: any) => state.uiReducer);

  return (
    <div
      className={`${styles.wrapper} ${isLight ? styles.light : styles.dark}`}
    >
      <div
        className={`container${type ? "-fluid" : ""} ${
          className ? className : ""
        } ${styles.container} ${type ? "p-0" : ""}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
