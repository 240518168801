import { Avatar, Card, Col, Row, Skeleton, Tag } from "antd";
import Meta from "antd/es/card/Meta";
import React, { Fragment } from "react";
import { IMAGE_PATH } from "../../../../../../../constants";
import { convertSkillsToGroupedAreas } from "../../../helpers";
import { MemberType } from "../../../interfaces/index.interface";
import { useParams } from "react-router-dom";
import { AreaSkillTypes } from "../../../../../../../interfaces/index.interface";
import { useTranslation } from "react-i18next";

type PropType = {
  members: MemberType[] | undefined;
  loading: boolean;
  allAreaAndSkill: AreaSkillTypes[];
};

const Members = (props: PropType) => {
  const { t } = useTranslation();
  const { members, loading, allAreaAndSkill } = props;
  const { id } = useParams();
  return (
    <Fragment>
      {id ? (
        <Row className="mt-3">
          <Col span={24}>
            <span style={{ fontWeight: "normal" }}>{t("members")}</span>
            {members?.length ? (
              members?.map((item, index) => (
                <Col key={item.id} span={24} className="mb-2">
                  <Card
                  // actions={[
                  //   <SettingOutlined key="setting" />,
                  //   <EditOutlined key="edit" />,
                  //   <EllipsisOutlined key="ellipsis" />,
                  // ]}
                  >
                    <Skeleton loading={loading} avatar active>
                      {item.isOwner ? (
                        <Tag
                          color={`orange`}
                          style={{ position: "absolute", right: "20px" }}
                        >
                          {t("owner")}
                        </Tag>
                      ) : (
                        ""
                      )}

                      <Meta
                        avatar={<Avatar src={IMAGE_PATH + item.photoUrl} />}
                        title={item.fullname}
                        // description={item.isOwner ? "Owner" : "Member"}
                      />
                      <div>
                        {item?.skills && !item.isOwner
                          ? convertSkillsToGroupedAreas(
                              item.skills,
                              allAreaAndSkill
                            )?.map((item, index) =>
                              index === 0 ? (
                                <div key={index} className="d-flex mt-3">
                                  <h6
                                    className="me-2"
                                    // style={{ textTransform: "capitalize" }}
                                  >
                                    {item.name}:
                                  </h6>
                                  {item.skills.map((skil, inx) =>
                                    inx <= 2 ? (
                                      <Tag
                                        key={inx}
                                        // color={`orange`}
                                        // style={{
                                        //   position: "absolute",
                                        //   right: "20px",
                                        // }}
                                      >
                                        {skil.name}
                                      </Tag>
                                    ) : (
                                      ""
                                    )
                                  )}
                                </div>
                              ) : (
                                ""
                              )
                            )
                          : ""}
                      </div>
                    </Skeleton>
                  </Card>
                </Col>
              ))
            ) : (
              <p className="text-center">{t("no_invited_users")}</p>
            )}
          </Col>
        </Row>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Members;
