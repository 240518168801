import { Card, Col, Skeleton } from "antd";

export const mapSkeleton = (i: number) => {
  return (
    <Col span={24} key={i} className={"mb-2"}>
      <Card className={"mb-3"}>
        <Skeleton loading={true} active></Skeleton>
      </Card>
    </Col>
  );
};
