import {
  AutoComplete,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Switch,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import GoBack from "../../../../../Components/Buttons/GoBack";
import SaveBtn from "../../../../../Components/Buttons/SaveBtn";
import styles from "./styles.module.scss";
import { WorkExperienceTypes } from "../../../../../interfaces/index.interface";
import {
  getWorkExperienceById,
  postWorkExperience,
  updateWorkExperience,
} from "../../../../../api/profile";
import { useNavigate, useParams } from "react-router-dom";
import LoaderOpacity from "../../../../../layout/LoaderDark";

import Typography from "../../../../../Components/Typography";
import { useGetOptionsStr } from "../../../../../hooks/useGetOptionsStr";
import { disabledDate } from "../../../../../helpers/disabledDatePicker";
import { useTranslation } from "react-i18next";
import Container from "../../../../../layout/Container";

interface ValidatorTypes {
  endDate: boolean;
  submit: boolean;
  data: boolean;
  defaultChecked: boolean;
}

const AddWorkExperience = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // Validator
  const [validator, setValidator] = useState<ValidatorTypes>({
    endDate: true,
    submit: false,
    data: false,
    defaultChecked: false,
  });

  // Autocomplate Options
  const jobType = useGetOptionsStr({
    url: "student/areas",
  });

  const company = useGetOptionsStr({
    url: "student/companies",
  });

  //   const div = document.querySelector(".getHeight")?.clientHeight;
  //   const [height, setHeight] = useState(Number(div) + 10);

  // Get work experience values on update
  useEffect(() => {
    (async () => {
      if (id) {
        try {
          setValidator((prevState) => ({
            ...prevState,
            data: true,
          }));
          const res = await getWorkExperienceById(id);
          const data: WorkExperienceTypes = res?.data?.data;
          form.setFieldsValue({
            jobType: data.jobType,
            companyName: data.companyName,
            startDate: dayjs(data.startDate),
            endDate: data.endDate !== null ? dayjs(data.endDate) : "",
            description: data.description,
          });
          setValidator((prevState) => ({
            ...prevState,
            endDate: Boolean(data.endDate),
            defaultChecked: !Boolean(data.endDate),
          }));
        } catch (error: any) {
          console.log("🚀 ~ file: index.tsx:31 ~ useEffect ~ error:", error);
        } finally {
          setValidator((prevState) => ({
            ...prevState,
            data: false,
          }));
        }
      } else {
        setValidator((prevState) => ({
          ...prevState,
          data: false,
        }));
      }
    })();
  }, []);

  //   On Finish
  const onFinish = async (values: WorkExperienceTypes) => {
    const data = {
      jobType: values.jobType,
      companyName: values.companyName,
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: !validator.endDate
        ? null
        : dayjs(values.endDate).format("YYYY-MM-DD"),
      description: values.description,
      actually: !validator.endDate,
    };

    try {
      setValidator((prevState) => ({
        ...prevState,
        submit: true,
      }));
      const res = id
        ? await updateWorkExperience(Number(id), data)
        : await postWorkExperience(data);
      navigate("/profile/work-experience");
      message.success(
        `${t("work_experience")} ${id ? t("updated") : t("created")}`
      );
      console.log("🚀 ~ file: index.tsx:37 ~ onFinish ~ res:", res);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:37 ~ onFinish ~ error:", error);
    } finally {
      setValidator((prevState) => ({
        ...prevState,
        submit: false,
      }));
    }
  };

  const handlerNow = (checked: boolean) => {
    setValidator((prevState: any) => ({
      ...prevState,
      endDate: !checked,
      defaultChecked: checked,
    }));

    form.setFieldsValue({
      endDate: "",
    });
    // setHeight(!checked ? Number(div) + 10 : 0);
  };

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack to={"/profile/work-experience"} />
      <div className={`container pb-5`}>
        {validator.data && <LoaderOpacity />}

        {/* Title */}
        <Typography
          title={t(`${id ? "edit_work_experience" : "add_work_experience"}`)}
          variant="h2"
          colSpan={24}
        />

        {/* Form */}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            {/* Job title */}
            <Col span={24}>
              <Form.Item
                name="jobType"
                label={t("job_name")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                {/* <Input /> */}
                <AutoComplete
                  options={jobType.options}
                  // onSelect={onSelect}
                  onSearch={(text: string) => jobType.getPanelValue(text)}
                  onChange={(e: any) => jobType.handlerSearch(e)}
                />
              </Form.Item>
            </Col>

            {/* Company */}
            <Col span={24}>
              <Form.Item
                name="companyName"
                label={t("company_name")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <AutoComplete
                  options={company.options}
                  onSearch={(text: string) => company.getPanelValue(text)}
                  onChange={(e: any) => company.handlerSearch(e)}
                />
              </Form.Item>
            </Col>

            {/* Start date */}
            <Col span={24}>
              <Form.Item
                name={"startDate"}
                label={t("start_date")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>
            </Col>

            {/* End date */}
            <Col
              span={24}
              // style={{ height: `${height}px` }}
              className={`transitions ${validator.endDate ? "h1" : "h0"}`}
            >
              <Form.Item
                name={"endDate"}
                label={t("end_date")}
                required={false}
                className="getHeight"
                rules={[
                  {
                    required: validator.endDate,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  // showToday={false}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  disabledDate={(current: any) =>
                    disabledDate(current, form, 1)
                  }
                />
              </Form.Item>
            </Col>

            {/* Switch */}
            <Col span={24} className="mb-3">
              <div className="d-flex justify-content-between">
                <span style={{ fontWeight: "bold" }}>{t("until_now")}</span>
                <Switch
                  onChange={handlerNow}
                  checked={validator.defaultChecked}
                />
              </div>
            </Col>

            {/* Description */}
            <Col span={24}>
              <Form.Item
                name={"description"}
                label={t("description")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input.TextArea style={{ height: "155px" }} />
              </Form.Item>
            </Col>
          </Row>

          <SaveBtn loading={validator.submit}>
            {id ? String(t("update")) : String(t("create"))}
          </SaveBtn>
        </Form>
      </div>
    </Container>
  );
};

export default AddWorkExperience;
