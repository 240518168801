import { useNavigate } from "react-router-dom";
import { Card, Col, Empty, Row } from "antd";
import { Fragment, useState } from "react";
import Meta from "antd/es/card/Meta";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import GoBack from "../../../../Components/Buttons/GoBack";
import { EducationType } from "../../../../interfaces/index.interface";
import { mapSkeleton } from "../../../../utils/MapSkeleton";
import Typography from "../../../../Components/Typography";
import DeleteDrawer from "../../../../utils/DeleteDrawer";
import { useGetAllData } from "../../../../hooks/useGetAllData";
import Container from "../../../../layout/Container";
import CustomEmpty from "../../../../Components/Empty";
import { useTranslation } from "react-i18next";

const Education = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<number | undefined>(0);

  // Get Education List
  const { data, setData, error, loading } = useGetAllData({
    url: "/student/educations",
  });

  const openDelete = (id?: number) => {
    setOpen(true);
    setId(id);
  };

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack extraIcon={{ path: "add" }} to={"/profile"} />
      <div className="container">
        {/* Title */}
        <Typography title={t("educations")} variant="h2" />

        {/* List */}
        <Row>
          {loading ? (
            Array.from({ length: 2 }).map((item: any, index: number) =>
              mapSkeleton(index)
            )
          ) : data?.length ? (
            data.map((item: EducationType, index: number) => (
              <Col span={24} key={index} className={"mb-2"}>
                <Card
                  actions={[
                    // <EyeOutlined />,
                    <EditOutlined
                      key="edit"
                      onClick={() => navigate(String(item.id))}
                    />,
                    <DeleteOutlined onClick={() => openDelete(item.id)} />,
                  ]}
                >
                  <Meta
                    title={item.institutionName}
                    description={`${t("from")} ${item.startDate} ${
                      item.endDate !== null
                        ? `${t("to")} ${item.endDate}`
                        : t("until_now")
                    }`}
                  />
                </Card>
              </Col>
            ))
          ) : error ? (
            <h1>{error}</h1>
          ) : (
            <CustomEmpty />
          )}
        </Row>

        {/* Drawer */}
        <DeleteDrawer
          title={String(t("delete_education"))}
          description={String(t("warning_delete_education"))}
          id={id}
          open={open}
          setOpen={setOpen}
          url={`/student/educations/${id}`}
          data={data}
          setData={setData}
        />
      </div>
    </Container>
  );
};

export default Education;
