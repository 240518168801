import { Button, Col, Drawer, Dropdown, MenuProps, Row } from "antd";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import {
  PlusCircleOutlined,
  PlusOutlined,
  MoreOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import ArrowLeft from "../../../assets/icons/ArrowLeft";
import styles from "./styles.module.scss";
import LineIphone from "../../../assets/icons/LineIphone";
import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  FacebookIcon,
  TelegramIcon,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  LinkedinIcon,
  WorkplaceShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

interface ExtraIconType {
  path: string;
}

type PropTypes = {
  extraIcon?: ExtraIconType;
  moreIcon?: boolean;
  showLngSelect?: boolean;
  shareIcon?: boolean;
  noneShadow?: boolean;
  to?: any;
  className?: string;
  shareTitle?: string;
};

const GoBack = (props: PropTypes) => {
  const {
    to = -1,
    extraIcon,
    moreIcon,
    shareIcon,
    className,
    noneShadow,
    showLngSelect,
    shareTitle = "vacancy",
  } = props;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleChangeLng = (lng: any) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  const handleChange = (lng: string) => {
    handleChangeLng(lng);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <span onClick={(e) => handleChange("ru")}>Ru</span>,
    },
    {
      key: "2",
      label: <span onClick={(e) => handleChange("uz")}>Uz</span>,
    },
    {
      key: "3",
      label: <span onClick={(e) => handleChange("en")}>En</span>,
    },
  ];

  const dropdownContent = () => {
    return (
      <Dropdown
        menu={{
          items,
          selectable: true,
          defaultSelectedKeys: [
            `${
              localStorage.getItem("lng") === "ru"
                ? "1"
                : localStorage.getItem("lng") === "uz"
                ? "2"
                : localStorage.getItem("lng") === "en"
                ? "3"
                : "1"
            }`,
          ],
        }}
        className="ms-2"
        placement="bottomLeft"
      >
        <Button
          style={{ textTransform: "capitalize" }}
          className="d-flex align-items-center"
        >
          {localStorage.getItem("lng") ? localStorage.getItem("lng") : "Ru"}
          {/* <img
            style={{ width: "20px" }}
            className="ms-2"
            src="https://res.cloudinary.com/apple-angren/image/upload/v1672383305/my-icons/527-5275877_-png_fyn2m5.png"
            alt=""
          /> */}
        </Button>
      </Dropdown>
    );
  };

  return (
    <Fragment>
      <Row
        className={`${styles.arrow} ${isLight ? styles.light : styles.dark} ${
          className ? className : ""
        } ${noneShadow ? styles.noneShadow : ""}`}
      >
        <Col
          className="d-flex align-items-center justify-content-between"
          span={24}
        >
          <div onClick={() => navigate(to)}>
            <ArrowLeft
              className={`${isLight ? styles.light : styles.dark} ${
                styles.icon
              }`}
            />
          </div>
          {/* <GoBack to={"/profile"} /> */}

          <div className="d-flex align-items-center">
            {extraIcon && (
              <Link to={extraIcon.path} style={{ textDecoration: "none" }}>
                <PlusOutlined
                  className={`${styles.addIcon} ${
                    isLight ? styles.light : styles.dark
                  }`}
                />
              </Link>
            )}

            {shareIcon && (
              <ShareAltOutlined
                onClick={showDrawer}
                className={`${styles.shareIcon} ${
                  isLight ? styles.light : styles.dark
                }`}
              />
            )}

            {showLngSelect && dropdownContent()}
            {/* {moreIcon && (
            <MoreOutlined
              className={`${styles.moreIcon} ${
                isLight ? styles.light : styles.dark
              }`}
            />
          )} */}
          </div>
        </Col>
      </Row>

      <Drawer
        height={170}
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"bottom"}
        className={`drawerModal`}
      >
        <div className="wrapper text-center">
          <LineIphone className={"drawerBlueLine"} />
        </div>
        <h5 className="text-center mb-4">{t(shareTitle)}</h5>
        <div className="d-flex justify-content-between">
          <TelegramShareButton
            url={window.location.href}
            className="Demo__some-network__share-button"
          >
            <span>Telegram </span>
            <TelegramIcon size={32} round />
          </TelegramShareButton>

          <FacebookShareButton
            url={window.location.href}
            quote={"title"}
            className="Demo__some-network__share-button"
          >
            <span>Facebook </span>
            <FacebookIcon size={32} round />
          </FacebookShareButton>

          <LinkedinShareButton
            url={window.location.href}
            className="Demo__some-network__share-button"
          >
            <span>Linkedin </span>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default GoBack;
