import { useSelector } from "react-redux";
import { routes } from "./Routes";

export const getPublicRoutes = () => {
  return routes?.filter((item) => !item?.config.isAuth);
};

export const getPrivateRoutes = () => {
  return routes?.filter((item) => item?.config.isAuth);
};

export const usePermission = () => {
  const { permissions, isAuth, userData } = useSelector(
    (state: any) => state.authReducer
  );
  // console.log(permissions);
  const isPermitted = (name: any) => {
    return permissions ? permissions?.includes(name) : false;
  };

  return { permissions, isPermitted, isAuth, userData };
};
