import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AboutMe from "../../assets/icons/AboutMe";
import Add from "../../assets/icons/Add";
import Chat from "../../assets/icons/Chat";
import Connection from "../../assets/icons/Connection";
import Home from "../../assets/icons/Home";
import Profile from "../../assets/icons/Profile";
import ModalAdd from "../ModalAdd";
import styles from "./styles.module.scss";
import { checkHasNewMessage } from "../../api/profile";
import { useUnreadNotifChat } from "../../hooks/useUnreadNotifChat";
import { Badge } from "antd";

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLight } = useSelector((state: any) => state.uiReducer);
  const { count } = useUnreadNotifChat();

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <Fragment>
      <ModalAdd isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <nav
        className={`${isLight ? styles.light : styles.dark} ${styles.navbar}`}
      >
        <ul>
          <li>
            <NavLink to={"/"}>
              <Home />
            </NavLink>
          </li>
          <li>
            <NavLink to={"/connection"}>
              <Connection />
            </NavLink>
          </li>
          <li onClick={showModal}>
            <div className={styles.add}>
              <Add />
            </div>
          </li>
          <li>
            <Badge count={count}>
              <NavLink to={"/chat"}>
                <Chat />
              </NavLink>
            </Badge>
          </li>
          <li>
            <NavLink to={"/profile"}>
              <AboutMe />
            </NavLink>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

export default Navbar;
