import { Button } from "antd";
import styles from "./styles.module.scss";
type propType = {
  loading?: boolean;
  onClick?: (e: any) => void;
  ClassName?: string;
  children?: JSX.Element | string;
  disabled?: boolean;
};
const SaveBtn = (props: propType) => {
  const { loading, onClick, ClassName, children, disabled = false } = props;

  return (
    <Button
      loading={loading}
      htmlType="submit"
      onClick={onClick}
      type="primary"
      className={`${styles.button} w-100`}
      disabled={disabled}
      style={{ textTransform: "uppercase" }}
    >
      {children}
    </Button>
  );
};

export default SaveBtn;
