import React, { useEffect, useRef, useState } from "react";
import Container from "../../../../layout/Container";
import GoBack from "../../../../Components/Buttons/GoBack";
import { Avatar, Button, Col, Form, Input, Row } from "antd";
import { IMAGE_PATH } from "../../../../constants";
import { useGetAllData } from "../../../../hooks/useGetAllData";
import { useNavigate, useParams } from "react-router-dom";
import { SendOutlined } from "@ant-design/icons";
import "./style.scss";
import ArrowLeft from "../../../../assets/icons/ArrowLeft";
import {
  TypeAllMessages,
  TypePostMessage,
  checkHasNewMessage,
  getAllMessages,
  postMessage,
} from "../../../../api/profile";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface Root {
  company: Company;
  jobPosition: JobPosition;
  chat?: Chat;
  user?: { id: number; fullname: string; photoUrl: any };
}

interface Company {
  id: number;
  name: string;
  logo: string;
}

interface JobPosition {
  id: number;
  name: string;
  type: string;
  dueDate: string;
}

interface Chat {
  id: number;
  name: any;
  type: string;
  username: any;
  canSendMessage: boolean;
}

type UseGetAllDataType = {
  data: Root;
  loading: boolean;
  error: string;
};

type TypeMessages = {
  createdAt: string;
  updatedAt: string;
  isOwner: boolean;
  isRead: boolean;
  message: string;
  id: string;
};
const Chat = () => {
  const { t } = useTranslation();
  const { listId, userId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [messages, setMessages] = useState<TypeMessages[]>([]);

  const { data, loading, error }: UseGetAllDataType = useGetAllData({
    url: "/student/chats/title",
    params: {
      toUserId: userId,
      ...(listId !== "undefined" && { jobPositionId: listId }),
    },
  });

  const messagesEndRef: any = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const dataParam: TypeAllMessages = {
    toUserId: userId,
    ...(listId !== "undefined" && { jobPositionId: listId }),
    type: "PRIVATE",
  };
  const fetchaAllMess = async () => {
    try {
      const res = await getAllMessages(dataParam);
      setMessages(res?.data?.data);
      scrollToBottom();
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:61 ~ error:", error);
    }
  };
  useEffect(() => {
    fetchaAllMess();
    scrollToBottom();
  }, []);

  const onFinish = async (values: any) => {
    const data: TypePostMessage = {
      ...(listId !== "undefined" && { jobPositionId: listId }),
      toUserId: userId,
    };
    try {
      const res = await postMessage(data, values);
      fetchaAllMess();
      form.resetFields();
      scrollToBottom();
      console.log("🚀 ~ file: index.tsx:90 ~ onFinish ~ res:", res);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:89 ~ onFinish ~ error:", error);
    }
  };

  interface TypeHasNewMess {
    canSendMessage: boolean;
    hasNewMessage: boolean;
  }
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        const res = await checkHasNewMessage(dataParam);
        const data: TypeHasNewMess = res?.data?.data;
        data.hasNewMessage && fetchaAllMess();
        console.log("🚀 ~ file: index.tsx:119 ~ interval ~ data:", data);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:118 ~ interval ~ error:", error);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container type="fluid">
      {/* Go back */}
      {/* <GoBack className="m-0" noneShadow /> */}

      {/* Chat */}
      {/* <Row className="pb-2 bg-white">
        <Col span={24}>
          <div className="container d-flex align-items-center">
            <div className="d-flex align-items-center">
              <Avatar src={`${IMAGE_PATH}`} className="me-2" />
            </div>
            <div className="">
              <h6 className="m-0">Orlando Diggs</h6>
              <span>Online</span>
            </div>
          </div>
        </Col>
      </Row> */}

      <Row>
        <Col span={24}>
          <div className="chat">
            <div className="contact bar">
              <ArrowLeft
                className={`goBackIcon me-4`}
                onClick={() => navigate(-1)}
              />
              <div className={`d-flex align-items-center`}>
                <Avatar
                  src={`${IMAGE_PATH + "/" + data?.company?.logo}`}
                  className="me-2"
                  style={{
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }}
                />
                <div>
                  <div className="name">
                    {listId === "undefined"
                      ? data?.user?.fullname
                      : data?.company?.name}
                  </div>
                  <div className="seen">{data?.jobPosition?.name}</div>
                </div>
              </div>
            </div>
            <div className="messages" id="chat">
              {/* <div className="time">Today at 11:41</div> */}

              {messages &&
                messages?.map((item, index) =>
                  item.isOwner ? (
                    <div className="text-end">
                      <div key={index} className="message parker">
                        {item.message}
                      </div>
                      <span style={{ marginRight: "1rem" }}>
                        {dayjs(item.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <div key={index} className="message stark">
                        {item.message}
                      </div>
                      <span style={{ marginLeft: "1rem" }}>
                        {dayjs(item.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </div>
                  )
                )}
              <div
                className="message stark"
                style={{ opacity: 0 }}
                ref={messagesEndRef}
              />

              {/* <div className="message stark">
                <div className="typing typing-1"></div>
                <div className="typing typing-2"></div>
                <div className="typing typing-3"></div>
              </div> */}
            </div>
            {data?.chat?.canSendMessage ? (
              <div className="d-flex align-items-center w-100 px-2 py-3">
                <Form
                  form={form}
                  className="w-100 d-flex"
                  layout="vertical"
                  onFinish={onFinish}
                >
                  <Form.Item
                    className="w-100 m-0 me-2"
                    name="message"
                    required={false}
                    rules={[
                      {
                        required: true,
                        message: String(t("please_fill_the_field")),
                      },
                    ]}
                  >
                    <Input
                      width={"100%"}
                      allowClear
                      placeholder={String(t("type_your_message"))}
                    />
                  </Form.Item>
                  {/* <input placeholder="Type your message here!" type="text" /> */}
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <SendOutlined />
                  </Button>
                </Form>
              </div>
            ) : (
              <div style={{ padding: "13px 0" }}>
                <h6 className="text-center" style={{ color: "gray" }}>
                  {t("you_cannot_write_to_hr")}
                </h6>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Chat;
