import React, { Fragment } from "react";
import AboutMe from "../../assets/icons/AboutMe";
import Certificate from "../../assets/icons/Certificate";
import Education from "../../assets/icons/Education";
import Language from "../../assets/icons/Language";
import MyProject from "../../assets/icons/MyProject";
import Skill from "../../assets/icons/Skill";
import WorkExperience from "../../assets/icons/WorkExperience";
import ProfileCard from "./Components/ProfileCard";
import Container from "../../layout/Container";
import List from "./Components/List";
import { IList } from "./Components/List/list.interface";
import styles from "./styles.module.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();

  const list: IList[] = [
    {
      icon: <MyProject />,
      title: t("my_statistics"),
      path: "my-statistics",
    },
    {
      icon: <AboutMe />,
      title: t("about_me"),
      path: "about-me",
    },
    {
      icon: <MyProject />,
      title: t("my_projects"),
      path: "project",
    },
    {
      icon: <MyProject />,
      title: t("beta_projects"),
      path: "beta-project",
    },
    {
      icon: <WorkExperience />,
      title: t("work_experience"),
      path: "work-experience",
    },
    {
      icon: <Education />,
      title: t("educations"),
      path: "education",
    },
    {
      icon: <Skill />,
      title: t("skills"),
      path: "skills",
    },
    {
      icon: <Certificate />,
      title: t("my_certificate"),
      path: "certificate",
    },
    {
      icon: <Language />,
      title: t("languages"),
      path: "language",
    },
  ];
  const pathname = useLocation();
  console.log("🚀 ~ file: index.tsx:61 ~ Profile ~ pathname:", pathname);
  return (
    <Container type="fluid">
      {/* Profile Image - Card */}
      <ProfileCard />

      {/* Profile fields - List */}
      <div className="container">
        {/* <Outlet /> */}
        <List list={list} />
      </div>
    </Container>
  );
};

export default Profile;
