import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import Container from "../../../../layout/Container";
import GoBack from "../../../../Components/Buttons/GoBack";
import Typography from "../../../../Components/Typography";
import SaveBtn from "../../../../Components/Buttons/SaveBtn";
import {
  Button,
  Col,
  Drawer,
  Image,
  Row,
  Spin,
  TabsProps,
  message,
} from "antd";
import { Modal, Tabs } from "antd-mobile";
import { useNavigate, useParams } from "react-router-dom";
import {
  getVacanyById,
  postApplyJob,
  postSaveJob,
} from "../../../../api/profile";
import CustomEmpty from "../../../../Components/Empty";
import { IMAGE_PATH } from "../../../../constants";
import saveBtn from "../../../../assets/icons/source/SaveBtn.svg";
import savedBtn from "../../../../assets/icons/source/SavedBtn.svg";
import LineIphone from "../../../../assets/icons/LineIphone";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CloseCircleOutline,
  ExclamationCircleFill,
  ExclamationTriangleOutline,
} from "antd-mobile-icons";
import { SAVE_JOB_ID } from "../../../../redux/actionTypes";

interface VacanyTypes {
  companyDescription: string;
  areaName: string;
  jobDescription: string;
  applied: boolean;
  experience: string;
  salary: number;
  companyName: string;
  jobType: string;
  photoUrl: string;
  position: string;
  website: string;
  industry: string;
  createdAt: string;
  skills: string;
  dueDate: string;
  address: string;
  name: string;
  id: number;
  type: string;
  saved: boolean;
}

const ApplyVacany = () => {
  const { t } = useTranslation();
  const { isAuth } = useSelector((state: any) => state.authReducer);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);
  const [save, setSave] = useState<boolean>(false);
  const [apply, setApply] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [scaleStep, setScaleStep] = useState(0.5);
  const [data, setData] = useState<VacanyTypes>();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: SAVE_JOB_ID,
      jobId: null,
    });
    (async () => {
      try {
        setLoading(true);
        const res = await getVacanyById(id);
        const data: VacanyTypes = res?.data?.data;
        console.log("🚀 ~ file: index.tsx:66 ~ data:", data);
        setData(data);
        setApply(data.applied);
        setSave(data.saved);
        console.log("🚀 ~ file: index.tsx:23 ~ data:", data);
      } catch (error: any) {
        const err: string = error?.response?.data?.message;
        message.warning(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const applyJob = async () => {
    try {
      setLoadingApply(true);
      const res = await postApplyJob(id);
      setApply(true);
      message.success(t("applied_successfully"));
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:79 ~ applyJob ~ error:", error);
    } finally {
      setOpen(false);
      setLoadingApply(false);
    }
  };

  const saveJob = async () => {
    if (isAuth) {
      try {
        setLoadingSave(true);
        const res = await postSaveJob(id);
        setSave((prevState) => !prevState);
        !save && message.success(t("job_saved"));
      } catch (error: any) {
        const err: string = error?.response?.data?.message;
        message.warning(t("job_already_saved"));
      } finally {
        setLoadingSave(false);
      }
    } else {
      Modal.alert({
        header: (
          <ExclamationTriangleOutline
            style={{
              fontSize: 64,
              color: "var(--adm-color-warning)",
            }}
          />
        ),
        title: t("please_login_for_apply_job"),
        showCloseButton: true,
        confirmText: "Ok",
      });
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const openApplyDraw = () => {
    if (isAuth) {
      setOpen(true);
    } else {
      Modal.alert({
        header: (
          <ExclamationTriangleOutline
            style={{
              fontSize: 64,
              color: "var(--adm-color-warning)",
            }}
          />
        ),
        title: t("please_login_for_apply_job"),
        showCloseButton: true,
        confirmText: "Ok",
      });
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
    dispatch({
      type: SAVE_JOB_ID,
      jobId: id,
    });
  };

  return (
    <Container type="fluid" className="pb-5">
      {/* Go back */}
      <GoBack
        showLngSelect
        moreIcon
        shareIcon
        shareTitle={"share_this_vacancy"}
        to={"/"}
      />

      {loading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="content" />
        </Spin>
      ) : data ? (
        <>
          <div className="container">
            {/* Title */}
            {/* <Typography title="Notifications" variant="h2" /> */}

            <Row>
              <Col span={24} className="text-center">
                <div className={styles.imgBox}>
                  <img
                    className={`${styles.img}`}
                    onClick={() => setVisible(true)}
                    src={
                      data.photoUrl
                        ? `${IMAGE_PATH + "/" + data.photoUrl}`
                        : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                    }
                    // src={
                    //   "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    // }
                    alt=""
                  />
                </div>
                <Image
                  style={{ display: "none" }}
                  preview={{
                    visible,
                    scaleStep,
                    src: `${
                      data.photoUrl
                        ? `${IMAGE_PATH + "/" + data.photoUrl}`
                        : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                    }`,
                    onVisibleChange: (value) => {
                      setVisible(value);
                    },
                  }}
                  src={
                    data.photoUrl
                      ? `${IMAGE_PATH + "/" + data.photoUrl}`
                      : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                  }
                />
              </Col>

              <Col span={24} className="text-center">
                <h1 className={styles.title}>{data.name}</h1>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Tabs style={{ paddingBottom: "40px" }}>
                  <Tabs.Tab title={t("about_job")} key="job">
                    <div className="content pt-2">
                      <div className="ctRow">
                        <h6>{t("name")}:</h6>
                        <p className="m-0">{data.name}</p>
                      </div>
                      <div className="ctRow">
                        <h6>{t("salary")}:</h6>
                        <p className="m-0">
                          {data?.salary === 0
                            ? data?.type === "VACANCY"
                              ? t("Depending on interview's result")
                              : t("Free salary")
                            : data?.salary}
                        </p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("job_type")}:</h6>
                        <p className="m-0">{data.jobType}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("job_description")}:</h6>
                        <p className="m-0">{data.jobDescription}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("industry")}:</h6>
                        <p className="m-0">{data.industry}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("position")}:</h6>
                        <p className="m-0">{data.position}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("area_name")}:</h6>
                        <p className="m-0">{data.areaName}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("skills")}:</h6>
                        <p className="m-0">{data.skills}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("experience")}:</h6>
                        <p className="m-0">{data.experience} Years</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("due_date")}:</h6>
                        <p className="m-0">{data.dueDate}</p>
                      </div>
                    </div>
                  </Tabs.Tab>

                  <Tabs.Tab title={t("about_company")} key="company">
                    <div className="content pt-2">
                      <div className="ctRow">
                        <h6>{t("company_name")}</h6>
                        <p className="m-0">{data.companyName}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("description")}</h6>
                        <p className="m-0">{data.companyDescription}</p>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("website")}:</h6>
                        <a href={data.website} target="_blank">
                          {data.website}
                        </a>
                      </div>

                      <div className="ctRow mt-4">
                        <h6>{t("address")}:</h6>
                        <p className="m-0">{data.address}</p>
                      </div>
                    </div>
                  </Tabs.Tab>
                </Tabs>
              </Col>
            </Row>
          </div>

          <div className={styles.buttons}>
            <div className="container d-flex justify-content-between align-items-center">
              {/* <Button
                loading={loadingSave}
                className={styles.save}
                onClick={saveJob}
              >
                <img src={save ? savedBtn : saveBtn} alt="" />
              </Button> */}
              {isAuth ? (
                <Button
                  loading={loadingApply}
                  className={`${styles.submit} ${apply ? styles.applied : ""}`}
                  onClick={openApplyDraw}
                  disabled={apply}
                >
                  {apply ? t("applied") : t("apply_now")}
                </Button>
              ) : (
                <Button
                  className={`${styles.submit}`}
                  onClick={navigateToLogin}
                  disabled={apply}
                >
                  {t("please_register_for_apply_this_job")}
                </Button>
              )}
            </div>
          </div>

          {/* <SaveBtn onClick={applyJob}>APPLY NOW</SaveBtn> */}
        </>
      ) : (
        <CustomEmpty createBtn={false} />
      )}

      <Drawer
        height={220}
        placement={"bottom"}
        closable={false}
        onClose={onClose}
        open={open}
        key={"bottom"}
        className={`drawerModal`}
      >
        <div className="wrapper text-center">
          <LineIphone className={"drawerBlueLine"} />
          <h3 className={`mt-2 mb-4`}>{t("warning_apply_job")}</h3>
          {/* <p className="mb-4">description</p> */}
          <Button
            type="primary"
            className="w-100 mb-2"
            htmlType="submit"
            style={{ height: 48 }}
            onClick={applyJob}
            loading={loadingApply}
          >
            {t("yes")}
          </Button>
          <Button
            type="default"
            className="w-100"
            style={{ height: 48 }}
            onClick={() => setOpen(false)}
          >
            {t("cancel")}
          </Button>
        </div>
      </Drawer>
    </Container>
  );
};

export default ApplyVacany;
