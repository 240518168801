import {
  AutoComplete,
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Skeleton,
  Space,
  Switch,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import GoBack from "../../../../../Components/Buttons/GoBack";
import Container from "../../../../../layout/Container";
import Typography from "../../../../../Components/Typography";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
  UserOutlined,
  AntDesignOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import {
  InvitedUsersType,
  MemberType,
  TypeProjectItem,
} from "../interfaces/index.interface";
import {
  getAllSkillsGroup,
  getProjectById,
  joinProject,
  postProject,
  projectExist,
  projectLinkExist,
  updateProject,
} from "../../../../../api/profile";
import { useGetOptionsStr } from "../../../../../hooks/useGetOptionsStr";
import SaveBtn from "../../../../../Components/Buttons/SaveBtn";
import SkillsList from "../../../../../Components/SkillsList";
import Meta from "antd/es/card/Meta";
import { IMAGE_PATH } from "../../../../../constants";
import { convertSkillsToGroupedAreas } from "../helpers";
import {
  AreaSkillTypes,
  SkillTypes,
} from "../../../../../interfaces/index.interface";
import { useSelector } from "react-redux";
import UsersDrawer from "./components/UsersDrawer";
import Members from "./components/Members";
import LoaderOpacity from "../../../../../layout/LoaderDark";
import { useTranslation } from "react-i18next";

interface ValidatorTypes {
  endDate: boolean;
  submit: boolean;
  data: boolean;
  defaultChecked: boolean;
}

interface ProjectExistType {
  projectExists: boolean;
}

const AddProject = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [allAreaAndSkill, setAllAreaAndSkill] = useState<AreaSkillTypes[]>([]);
  const [selectedAreaSkills, setSelectedAreaSkills] = useState<
    AreaSkillTypes[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [members, setMembers] = useState<MemberType[] | undefined>([]);
  const [appliedSkills, setappliedSkills] = useState<number[]>([]);
  const { userData } = useSelector((state: any) => state.authReducer);
  const [trigger, setTrigger] = useState<number>(0);
  const [trigger2, setTrigger2] = useState<number>(0);
  const [selectedSkills, setselectedSkills] = useState<number[]>([]);
  const [usersIds, setUsersIds] = useState<number[]>([]);
  const [invitedUsers, setInvitedUsers] = useState<InvitedUsersType[]>([]);

  const [projectIsExist, setProjectIsExist] = useState<boolean>(false);
  const [projectLinkIsExist, setProjectLinkIsExist] = useState<boolean>(false);
  const [isJoined, setIsJoined] = useState<boolean | undefined>(true);

  const { id } = useParams();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isOwner: boolean | null =
    searchParams.get("isOwner") === "false" ? true : false;
  const visit = searchParams.get("visit");

  // Validator
  const [validator, setValidator] = useState<ValidatorTypes>({
    endDate: true,
    submit: false,
    data: false,
    defaultChecked: false,
  });

  useEffect(() => {
    (async () => {
      try {
        const res = await getAllSkillsGroup();
        setAllAreaAndSkill(res?.data?.data);

        if (id) {
          setLoading(true);
          setValidator((prevState) => ({
            ...prevState,
            data: true,
          }));
          const res = await getProjectById(id);
          const data: TypeProjectItem = res?.data?.data;
          setIsJoined(data?.isJoined);
          form.setFieldsValue({
            name: data.name,
            link: data.link,
            company: data.company,
            startDate: dayjs(data.startDate),
            endDate: data.endDate !== null ? dayjs(data.endDate) : "",
            description: data.description,
          });
          setMembers(data?.members);
          const ownerUser: any = data?.members?.filter(
            (item) => item.id === userData?.id
          );
          setValidator((prevState) => ({
            ...prevState,
            endDate: Boolean(data.endDate),
            defaultChecked: !Boolean(data.endDate),
          }));
          const user: MemberType = ownerUser?.[0];
          setappliedSkills(user.skills);
          setselectedSkills(user.skills);

          // console.log("🚀 ~ file: index.tsx:98 ~ data:", data);
        }

        // const res_student = await getStudentSkills();
        // setStudentSkills(res_student?.data?.data);
        // setStudentSkillsLength(res_student?.data?.data?.length);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:16 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const data: AreaSkillTypes[] = convertSkillsToGroupedAreas(
      appliedSkills,
      allAreaAndSkill
    );

    setSelectedAreaSkills(data);
  }, [appliedSkills]);

  const company = useGetOptionsStr({
    url: "student/companies",
  });

  //   On Finish
  const onFinish = async (values: TypeProjectItem) => {
    const data: TypeProjectItem = {
      name: values.name,
      link: values.link,
      company: values.company,
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: !validator.endDate
        ? null
        : dayjs(values.endDate).format("YYYY-MM-DD"),
      description: values.description,
      skillIds: appliedSkills,
      userIds: usersIds,
    };

    if (!projectIsExist && !projectLinkIsExist) {
      try {
        setValidator((prevState) => ({
          ...prevState,
          submit: true,
        }));
        const dataJoin = {
          skillIds: appliedSkills,
        };
        const res =
          id && visit
            ? joinProject(dataJoin, Number(id))
            : id
            ? await updateProject(Number(id), data)
            : await postProject(data);
        navigate("/profile/project");
        message.success(
          `${id && visit ? "Joined" : id ? "updated" : "created"}`
        );
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:37 ~ onFinish ~ error:", error);
      } finally {
        setValidator((prevState) => ({
          ...prevState,
          submit: false,
        }));
      }
    }
  };

  const handlerNow = (checked: boolean) => {
    setValidator((prevState: any) => ({
      ...prevState,
      endDate: !checked,
      defaultChecked: checked,
    }));

    form.setFieldsValue({
      endDate: "",
    });
    // setHeight(!checked ? Number(div) + 10 : 0);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const checkProject = async (e: any) => {
    const value: string = e.target.value;

    try {
      const res = id
        ? await projectExist(value, id)
        : await projectExist(value, null);
      const data: ProjectExistType = res?.data?.data;
      console.log("🚀 ~ file: index.tsx:228 ~ checkProject ~ data:", data);
      setProjectIsExist(data.projectExists);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:222 ~ checkProject ~ error:", error);
    }
  };

  const checkProjectLink = async (e: any) => {
    const value: string = e.target.value;

    try {
      const res = id
        ? await projectLinkExist(value, id)
        : await projectLinkExist(value, null);
      const data: ProjectExistType = res?.data?.data;
      console.log("🚀 ~ file: index.tsx:228 ~ checkProject ~ data:", data);
      setProjectLinkIsExist(data.projectExists);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:222 ~ checkProject ~ error:", error);
    }
  };

  const [open, setOpen] = useState<boolean>(false);

  const showModalUsers = () => {
    setOpen(true);
    setTrigger2((trigger) => trigger + 1);
  };

  // const disabledDate = (current: any) => {
  //   return current && current < dayjs().endOf("day");
  // };
  return (
    <Container type="fluid">
      <GoBack to={"/profile/project"} />
      {loading ? <LoaderOpacity /> : ""}
      <div className="container pb-5">
        {/* Title */}
        <Typography
          title={`${
            visit && id
              ? t("project_information")
              : id
              ? t("edit_project")
              : t("add_project")
          } `}
          variant="h2"
        />

        {/* Form */}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row>
            {/* Project name */}
            <Col span={24}>
              <Form.Item
                name="name"
                label={t("project_name")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
                help={
                  projectIsExist ? (
                    <span style={{ color: "red" }}>{t("project_exist")}</span>
                  ) : (
                    ""
                  )
                }
              >
                <Input onChange={(e) => checkProject(e)} disabled={isOwner} />
                {/* <AutoComplete
                  options={jobType.options}
                  // onSelect={onSelect}
                  onSearch={(text: string) => jobType.getPanelValue(text)}
                  onChange={(e: any) => jobType.handlerSearch(e)}
                /> */}
              </Form.Item>
            </Col>

            {/* Company */}
            <Col span={24}>
              <Form.Item
                name="company"
                label={t("company_name")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <AutoComplete
                  disabled={isOwner}
                  options={company.options}
                  onSearch={(text: string) => company.getPanelValue(text)}
                  onChange={(e: any) => company.handlerSearch(e)}
                />
              </Form.Item>
            </Col>

            {/* Project source */}
            <Col span={24}>
              <Form.Item
                name="link"
                label={t("project_source")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
                help={
                  projectLinkIsExist ? (
                    <span style={{ color: "red" }}>
                      {t("project_link_exist")}
                    </span>
                  ) : (
                    ""
                  )
                }
              >
                <Input
                  onChange={(e) => checkProjectLink(e)}
                  disabled={isOwner}
                />
                {/* <AutoComplete
                  options={jobType.options}
                  // onSelect={onSelect}
                  onSearch={(text: string) => jobType.getPanelValue(text)}
                  onChange={(e: any) => jobType.handlerSearch(e)}
                /> */}
              </Form.Item>
            </Col>

            {/* Start date */}
            <Col span={24}>
              <Form.Item
                name={"startDate"}
                label={t("start_date")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  disabled={isOwner}
                />
              </Form.Item>
            </Col>

            {/* End date */}
            <Col
              span={24}
              // style={{ height: `${height}px` }}
              className={`transitions ${validator.endDate ? "h1" : "h0"}`}
            >
              <Form.Item
                name={"endDate"}
                label={t("end_date")}
                required={false}
                className="getHeight"
                rules={[
                  {
                    required: validator.endDate,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  disabled={isOwner}
                  // showToday={false}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  // disabledDate={(current: any) => disabledDate(current)}
                />
              </Form.Item>
            </Col>

            {/* Switch */}
            <Col span={24} className="mb-3">
              <div className="d-flex justify-content-between">
                <span style={{ fontWeight: "normal" }}>{t("until_now")}</span>
                <Switch
                  disabled={isOwner}
                  onChange={handlerNow}
                  checked={validator.defaultChecked}
                />
              </div>
            </Col>

            {/* Description */}
            <Col span={24}>
              <Form.Item
                name={"description"}
                label={t("description")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input.TextArea
                  style={{ height: "155px" }}
                  disabled={isOwner}
                />
              </Form.Item>
            </Col>
          </Row>

          <SaveBtn
            loading={validator.submit}
            disabled={
              id && visit && !isJoined
                ? false
                : id && !visit
                ? false
                : isJoined && id
                ? true
                : false
            }
          >
            {id && visit && !isJoined
              ? String(t("join"))
              : id && !visit
              ? String(t("update"))
              : isJoined && id
              ? String(t("you_are_joined"))
              : String(t("create"))}
          </SaveBtn>
        </Form>

        {/* Skills */}
        <Row>
          <Col span={24}>
            <div className="d-flex justify-content-start">
              <span style={{ fontWeight: "normal" }}>{t("skills")}</span>
            </div>

            {selectedAreaSkills?.length ? (
              selectedAreaSkills?.map((item, index) => (
                <div key={item.id} className="mb-3">
                  <h6 className="mb-1" style={{ textTransform: "capitalize" }}>
                    {item.name}
                  </h6>
                  <div>
                    {item.skills.map((skill: SkillTypes, index: number) => (
                      <Tag color="blue" key={skill.id}>
                        {skill.name}
                      </Tag>
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">{t("no_selected_skills")}</p>
            )}
          </Col>

          {id && visit && !isJoined ? (
            <Col span={24}>
              <Button
                onClick={showModal}
                className="w-100"
                type="dashed"
                style={{ height: "50px" }}
              >
                {t("select_skill")}
              </Button>
            </Col>
          ) : id && !visit ? (
            <Col span={24}>
              <Button
                onClick={showModal}
                className="w-100"
                type="dashed"
                style={{ height: "50px" }}
              >
                {t("select_skill")}
              </Button>
            </Col>
          ) : isJoined && id ? (
            ""
          ) : (
            <Col span={24}>
              <Button
                onClick={showModal}
                className="w-100"
                type="dashed"
                style={{ height: "50px" }}
              >
                {t("select_skill")}
              </Button>
            </Col>
          )}
        </Row>

        {/* Members */}
        <Members
          members={members}
          loading={loading}
          allAreaAndSkill={allAreaAndSkill}
        />

        {/* Invite user btn */}
        {id && visit && !isJoined ? (
          <Row className="my-3">
            <Col span={24}>
              <div>
                <span style={{ fontWeight: "normal" }}>
                  {t("invite_employees")}
                </span>
              </div>

              {invitedUsers?.length ? (
                <Avatar.Group className="d-flex flex-wrap mb-2">
                  {invitedUsers.map((item, index) =>
                    item.photoUrl ? (
                      <Avatar
                        key={index}
                        src={
                          item?.photoUrl?.startsWith("https")
                            ? item.photoUrl
                            : IMAGE_PATH + item.photoUrl
                        }
                      />
                    ) : (
                      <a href="#" key={index}>
                        <Avatar style={{ backgroundColor: "#f56a00" }}>
                          {item.nameFirstLetter}
                        </Avatar>
                      </a>
                    )
                  )}
                </Avatar.Group>
              ) : (
                <p className="text-center">{t("no_invited_users")}</p>
              )}

              <Button
                onClick={showModalUsers}
                className="w-100"
                type="dashed"
                style={{ height: "50px" }}
              >
                {t("invite")}
              </Button>
            </Col>
          </Row>
        ) : id && !visit ? (
          <Row className="my-3">
            <Col span={24}>
              <div>
                <span style={{ fontWeight: "normal" }}>
                  {t("invite_employees")}
                </span>
              </div>

              {invitedUsers?.length ? (
                <Avatar.Group className="d-flex flex-wrap mb-2">
                  {invitedUsers.map((item, index) =>
                    item.photoUrl ? (
                      <Avatar
                        key={index}
                        src={
                          item?.photoUrl?.startsWith("https")
                            ? item.photoUrl
                            : IMAGE_PATH + item.photoUrl
                        }
                      />
                    ) : (
                      <a href="#" key={index}>
                        <Avatar style={{ backgroundColor: "#f56a00" }}>
                          {item.nameFirstLetter}
                        </Avatar>
                      </a>
                    )
                  )}
                </Avatar.Group>
              ) : (
                <p className="text-center">{t("no_invited_users")}</p>
              )}

              <Button
                onClick={showModalUsers}
                className="w-100"
                type="dashed"
                style={{ height: "50px" }}
              >
                {t("invite")}
              </Button>
            </Col>
          </Row>
        ) : isJoined && id ? (
          ""
        ) : (
          <Row className="my-3">
            <Col span={24}>
              <div>
                <span style={{ fontWeight: "normal" }}>
                  {t("invite_employees")}
                </span>
              </div>

              {invitedUsers?.length ? (
                <Avatar.Group className="d-flex flex-wrap mb-2">
                  {invitedUsers.map((item, index) =>
                    item.photoUrl ? (
                      <Avatar
                        key={index}
                        src={
                          item?.photoUrl?.startsWith("https")
                            ? item.photoUrl
                            : IMAGE_PATH + item.photoUrl
                        }
                      />
                    ) : (
                      <a href="#" key={index}>
                        <Avatar style={{ backgroundColor: "#f56a00" }}>
                          {item.nameFirstLetter}
                        </Avatar>
                      </a>
                    )
                  )}
                </Avatar.Group>
              ) : (
                <p className="text-center">{t("no_invited_users")}</p>
              )}

              <Button
                onClick={showModalUsers}
                className="w-100"
                type="dashed"
                style={{ height: "50px" }}
              >
                {t("invite")}
              </Button>
            </Col>
          </Row>
        )}

        <Modal
          title={t("add_skills")}
          open={isModalOpen}
          onOk={() => setTrigger((trigger) => trigger + 1)}
          onCancel={() => setTrigger((trigger) => trigger + 1)}
          closable={true}
          footer={false}
          centered
        >
          <SkillsList
            trigger={trigger}
            appliedSkills={appliedSkills}
            setappliedSkills={setappliedSkills}
            allAreaAndSkill={allAreaAndSkill}
            setAllAreaAndSkill={setAllAreaAndSkill}
            handleCancel={handleCancel}
            selectedSkills={selectedSkills}
            setselectedSkills={setselectedSkills}
          />
        </Modal>

        <UsersDrawer
          open={open}
          setOpen={setOpen}
          height={600}
          trigger={trigger2}
          usersIds={usersIds}
          invitedUsers={invitedUsers}
          setInvitedUsers={setInvitedUsers}
          setUsersIds={setUsersIds}
          projectId={Number(id)}
        />
      </div>
    </Container>
  );
};

export default AddProject;
