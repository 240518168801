import {
  AutoComplete,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Switch,
} from "antd";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import GoBack from "../../../../../Components/Buttons/GoBack";
import SaveBtn from "../../../../../Components/Buttons/SaveBtn";
import Typography from "../../../../../Components/Typography";
import {
  AutocomplateOptType,
  EducationTypes,
} from "../../../../../interfaces/index.interface";
import LoaderOpacity from "../../../../../layout/LoaderDark";
import {
  getEducationById,
  postEducation,
  updateEducation,
} from "../../../../../api/profile";
import { useGetOptionsObj } from "../../../../../hooks/useGetOptionsObj";
import { disabledDate } from "../../../../../helpers/disabledDatePicker";
import { useTranslation } from "react-i18next";
import Container from "../../../../../layout/Container";

interface ValidatorTypes {
  submit: boolean;
  data: boolean;
  visible: boolean;
  defaultChecked: boolean;
}

const AddEducation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [validator, setValidator] = useState<ValidatorTypes>({
    submit: false,
    data: false,
    visible: true,
    defaultChecked: false,
  });

  // Get Autocomplate options
  const { onSelect, getPanelValue, handlerSearch, options, optionsChild } =
    useGetOptionsObj({
      url: "/universities",
    });

  // Get Education values on update
  useEffect(() => {
    (async () => {
      if (id) {
        try {
          setValidator((prevState) => ({
            ...prevState,
            data: true,
          }));
          const res = await getEducationById(id);
          const data: EducationTypes = res?.data?.data;
          form.setFieldsValue({
            levelOfEducation: data.levelOfEducation,
            institutionName: data.institutionName,
            fieldOfStudy: data.fieldOfStudy,
            startDate: dayjs(data.startDate),
            endDate: data.endDate !== null ? dayjs(data.endDate) : "",
          });
          setValidator((prevState) => ({
            ...prevState,
            visible: Boolean(data.endDate),
            defaultChecked: !Boolean(data.endDate),
          }));
        } catch (error: any) {
          console.log("🚀 ~ file: index.tsx:31 ~ useEffect ~ error:", error);
        } finally {
          setValidator((prevState) => ({
            ...prevState,
            data: false,
          }));
        }
      } else {
        setValidator((prevState) => ({
          ...prevState,
          data: false,
        }));
      }
    })();
  }, []);

  const onFinish = async (values: EducationTypes) => {
    const data = {
      levelOfEducation: values.levelOfEducation,
      institutionName: values.institutionName,
      fieldOfStudy: values.fieldOfStudy,
      startDate: dayjs(values.startDate).format("YYYY-MM-DD"),
      endDate: !validator.visible
        ? null
        : dayjs(values.endDate).format("YYYY-MM-DD"),
      actually: !validator.visible,
    };

    try {
      setValidator((prevState) => ({
        ...prevState,
        submit: true,
      }));
      const res = id
        ? await updateEducation(Number(id), data)
        : await postEducation(data);
      navigate(-1);
      message.success(
        `${id ? t("education_updated") : t("education_created")}`
      );
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:37 ~ onFinish ~ error:", error);
    } finally {
      setValidator((prevState) => ({
        ...prevState,
        submit: false,
      }));
    }
  };

  const handlerNow = (checked: boolean) => {
    setValidator((prevState) => ({
      ...prevState,
      visible: !checked,
      defaultChecked: checked,
    }));

    form.setFieldsValue({
      endDate: "",
    });
    // setHeight(!checked ? Number(div) + 10 : 0);
  };

  return (
    <Container type="fluid">
      <GoBack to={"/profile/education"} />
      <div className="container">
        {/* Loader */}
        {validator.data && <LoaderOpacity />}

        {/* Title */}
        <Typography
          title={`${id ? t("update_education") : t("add_education")}`}
          variant="h2"
          colSpan={24}
        />

        {/* Form */}
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row>
            {/* Institution Name */}
            <Col span={24}>
              <Form.Item
                name="institutionName"
                label={t("institution_name")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <AutoComplete
                  options={options}
                  onSelect={(value: string, option: AutocomplateOptType) =>
                    onSelect(value, option)
                  }
                  onSearch={(text: string) => getPanelValue(text)}
                  onChange={(e: any) => handlerSearch(e)}
                />
              </Form.Item>
            </Col>
            {/* Field Of Study */}
            <Col span={24}>
              <Form.Item
                name="fieldOfStudy"
                label={t("field_of_study")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <AutoComplete
                  options={optionsChild}
                  filterOption={(inputValue, option) =>
                    option!.value
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {/* Level Of Education */}
            <Col span={24}>
              <Form.Item
                name="levelOfEducation"
                label={t("level_of_education")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* Start date */}
            <Col span={24}>
              <Form.Item
                name={"startDate"}
                label={t("start_date")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {/* End date */}
            <Col
              span={24}
              // style={{ height: `${height}px` }}
              className={`transitions ${validator.visible ? "h1" : "h0"}`}
            >
              <Form.Item
                name={"endDate"}
                label={t("end_date")}
                required={false}
                className="getHeight"
                rules={[
                  {
                    required: validator.visible,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                  disabledDate={(current: any) =>
                    disabledDate(current, form, 0)
                  }
                />
              </Form.Item>
            </Col>

            {/* Switch */}
            <Col span={24} className="mb-3">
              <div className="d-flex justify-content-between">
                <span style={{ fontWeight: "bold" }}>{t("until_now")}</span>
                <Switch
                  onChange={handlerNow}
                  checked={validator.defaultChecked}
                />
              </div>
            </Col>
          </Row>

          <SaveBtn loading={validator.submit}>
            {id ? String(t("update")) : String(t("create"))}
          </SaveBtn>
        </Form>
      </div>
    </Container>
  );
};

export default AddEducation;
