import { Button, Modal } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LineIphone from "../../assets/icons/LineIphone";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

type PropType = {
  isModalOpen: boolean;
  setIsModalOpen: any;
};

const ModalAdd = (props: PropType) => {
  const { t } = useTranslation();
  const { isModalOpen, setIsModalOpen } = props;
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={false}
      footer={null}
      centered
      className={`${styles.modal} ${
        isLight ? "antModalLight" : "antModalDark"
      }`}
    >
      <div className={styles.wrapper}>
        <LineIphone className={styles.line} />
      </div>
      <Link to={"profile/project/add"}>
        <Button
          className={`w-100 mb-3 ${styles.button}`}
          type="primary"
          style={{ height: "48px", textTransform: "uppercase" }}
        >
          + {t("add_project")}
        </Button>
      </Link>
      <Link to={"profile/beta-project/add"}>
        <Button
          className={`w-100 mb-3 ${styles.button}`}
          type="primary"
          style={{ height: "48px", textTransform: "uppercase" }}
        >
          + {t("add_beta_project")}
        </Button>
      </Link>
      <Link to={"profile/certificate/upload"}>
        <Button
          className={`w-100 ${styles.button}`}
          type="primary"
          style={{ height: "48px", textTransform: "uppercase" }}
        >
          + {t("add_certificate")}
        </Button>
      </Link>
    </Modal>
  );
};

export default ModalAdd;
