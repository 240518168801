import styles from "./styles.module.scss";

const Settings = (props: any) => {
  return (
    <span
      className={`icon ${styles.icon} ${props?.class ? props.class : ""}`}
    />
  );
};

export default Settings;
