import React, { useState } from "react";
import styles from "./styles.module.scss";
import { Button, Drawer, Form, Input } from "antd";
import IconFilter from "../../assets/icons/IconFilter";
import { ClearOutlined } from "@ant-design/icons";
import LineIphone from "../../assets/icons/LineIphone";
import { useTranslation } from "react-i18next";

const { Search } = Input;

type PropType = {
  open: boolean;
  onFinish: (e?: any) => void;
  onSearch: (e?: any) => void;
  setOpen: (e: boolean) => void;
  showModal: () => void;
  clearFilters: () => void;
};

const Topbar = (props: PropType) => {
  const { t } = useTranslation();
  const { onFinish, onSearch, setOpen, open, showModal, clearFilters } = props;

  const [navbar, setNavbar] = useState<string>("");
  const [openDraw, setOpenDraw] = useState<boolean>(false);

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setNavbar("0");
    } else {
      setNavbar("-60px");
    }
  }

  const onCloseDrawer = () => {
    setOpenDraw(false);
  };
  const openDrawer = () => {
    setOpenDraw(true);
  };
  const clearFiltersLocal = () => {
    setOpenDraw(false);
    clearFilters();
  };
  // const onSearch = (value: string) => console.log(value);
  return (
    <div
      id="navbar"
      className={`${styles.navbar} py-2`}
      //   style={{ top: navbar }}
    >
      <div className={`container ${styles.container}`}>
        {/* <Search placeholder="input search text" onSearch={onSearch} /> */}
        <Form className="w-100" onFinish={onFinish}>
          <Form.Item
            required={false}
            rules={[
              {
                required: true,
                message: String(t("please_fill_the_field")),
                warningOnly: true,
              },
            ]}
            className="w-100 mb-0"
            name="search"
          >
            <Search
              placeholder={String(t("please_enter_are_name"))}
              // value={searchValue}
              onSearch={onSearch}
              enterButton
              allowClear
              onChange={(e?: any) => onSearch(e.target.value)}
            />
          </Form.Item>
        </Form>
        <Button className={styles.buttonClear} onClick={openDrawer}>
          <ClearOutlined />
        </Button>
        <Button className={styles.button} onClick={showModal}>
          <IconFilter />
        </Button>
      </div>

      <Drawer
        height={280}
        placement={"bottom"}
        closable={false}
        onClose={onCloseDrawer}
        open={openDraw}
        key={"bottom"}
        className={`drawerModal`}
      >
        <div className="wrapper text-center">
          <LineIphone className={"drawerBlueLine"} />
          <h5 className={`mt-2 danger`}>{t("warning_clear_filters")}?</h5>
          <p className="mb-4">{t("sub_warning_clear_filters")}</p>
          <Button
            type="primary"
            className="w-100 mb-2"
            htmlType="submit"
            style={{ height: 48 }}
            onClick={clearFiltersLocal}
          >
            {t("yes")}
          </Button>
          <Button
            type="default"
            className="w-100"
            style={{ height: 48 }}
            onClick={() => setOpen(false)}
          >
            {t("cancel")}
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

export default Topbar;
