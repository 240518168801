import { DotLoading } from "antd-mobile";
import Logo from "../../assets/icons/Logo/Logo";
import LogoCircle from "../../assets/icons/LogoCircle";
import styles from "./styles.module.scss";

const Loader = () => {
  return (
    <div className={`${styles.bgBlue} d-flex`}>
      {/* <Logo class={styles.logo} /> */}
      <LogoCircle class={styles.logo} />
      <span style={{ fontSize: 20 }}>
        <DotLoading color="white" />
      </span>
    </div>
  );
};

export default Loader;
