import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Upload,
} from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getCertificateById,
  postCertificate,
  updateCertificate,
} from "../../../../../api/profile";
import { IMAGE_PATH } from "../../../../../constants";
import { CertificateType } from "../../../../../interfaces/index.interface";
import GoBack from "../../../../../Components/Buttons/GoBack";
import SaveBtn from "../../../../../Components/Buttons/SaveBtn";
import { useTranslation } from "react-i18next";
import Container from "../../../../../layout/Container";
import Typography from "../../../../../Components/Typography";

const UploadCertificate = () => {
  const { t } = useTranslation();
  const { certificateId } = useParams();
  const [form] = Form.useForm();
  const [sizeError, setSizeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<Array<any>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (certificateId) {
      (async () => {
        try {
          const res = await getCertificateById(Number(certificateId));
          const data: CertificateType = res?.data?.data;

          form.setFieldsValue({
            name: data?.name,
            dueDate: dayjs(data?.dueDate),
          });
          setFiles([
            {
              uid: "123",
              name: data?.name,
              url: `${IMAGE_PATH}${data?.file}`,
              status: "done",
              thumbUrl:
                "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png",
              db_file: true,
            },
          ]);
        } catch (error: any) {
          console.log("🚀 ~ file: index.tsx:26 ~ error:", error);
        }
      })();
    }
  }, []);

  const onFinish = async (values: any) => {
    if (!values.file) {
      try {
        setLoading(true);
        // console.log(files);

        const fmData = new FormData();
        fmData.append("name", values.name);
        fmData.append("dueDate", dayjs(values.dueDate).format("YYYY-MM-DD"));
        if (files?.length > 0 && !files[0]?.db_file) {
          fmData.append("file", files[0]);
        }
        const res = certificateId
          ? await updateCertificate(fmData, Number(certificateId))
          : await postCertificate(fmData);

        message.success(`${certificateId ? t("updated") : t("created")}`);
        navigate("/profile/certificate");
      } catch (error: any) {
      } finally {
        setLoading(false);
      }
    } else {
      message.error(t("file_is_require"));
    }
  };

  return (
    <Container type="fluid">
      {/* Back */}
      <GoBack to={"/profile/certificate"} />
      <div className={`container`}>
        <Typography
          variant="h2"
          title={t(
            `${certificateId ? "update_certificate" : "upload_certificate"}`
          )}
        />

        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                label={t("certificate_name")}
                name="name"
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input allowClear />
              </Form.Item>

              <Form.Item
                name="dueDate"
                label={t("issue_date")}
                required={false}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>

              <Form.Item
                name="file"
                label={t("file")}
                rules={[
                  { required: false, message: String(t("file_is_require")) },
                ]}
              >
                <Upload
                  listType="picture-card"
                  accept=".pdf"
                  onRemove={() => {
                    setFiles([]);
                  }}
                  fileList={files}
                  customRequest={(prop: any) => {
                    let errors = [];

                    if (
                      prop.file.size > 2 * 1024 * 1024 &&
                      prop.file.type === "application/pdf"
                    ) {
                      errors.push(t("warning_file_size"));
                    }
                    if (prop.file.type !== "application/pdf") {
                      errors.push(t("warning_file_type"));
                    }
                    if (errors.length === 0) {
                      setFiles([prop.file]);
                      form.setFieldValue("file", undefined);
                    } else {
                      message.error(errors.join(" "));
                    }
                    prop?.onSuccess("ok");
                  }}
                  multiple={false}
                  maxCount={1}
                  onPreview={() => {
                    let a = document.createElement("a");
                    a.target = "_blank";
                    a.href = files[0]?.url;
                    a.click();
                    a.remove();
                  }}
                >
                  {files.length === 0 ? (
                    <p className="ant-upload-drag-icon d-flex flex-column align-items-center m-0">
                      <InboxOutlined style={{ fontSize: "35px" }} />
                      Upload
                    </p>
                  ) : (
                    ""
                  )}
                </Upload>
              </Form.Item>
              {/* 
            <p className="mt-2">
              Upload files in PDF format up to 5 MB. Just upload it once and you
              can use it in your next application.
            </p> */}

              {/* <Button
              htmlType="submit"
              type="primary"
              className="w-100"
              style={{ height: "48px" }}
              loading={loading}
            >
              
            </Button> */}
            </Col>
          </Row>
          <SaveBtn loading={loading}>
            {certificateId ? String(t("save")) : String(t("create"))}
          </SaveBtn>
        </Form>
      </div>
    </Container>
  );
};

export default UploadCertificate;
