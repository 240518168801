import { Col, Row, Statistic } from "antd";
import styles from "./styles.module.scss";
import Portfolio from "../../../../assets/icons/Portfolio";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getStatistics } from "../../../../api/profile";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";

type DataTypes = {
  companyAmount: number;
  vacancyAmount: number;
  internshipAmount: number;
};

const initialState: DataTypes = {
  companyAmount: 0,
  vacancyAmount: 0,
  internshipAmount: 0,
};

const Statistics = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLight } = useSelector((state: any) => state.uiReducer);
  const [statistics, setStatistics] = useState<DataTypes>(initialState);

  const formatter = (value: number) => (
    <CountUp end={value} separator="," style={{ color: "#4265f6" }} />
  );

  useEffect(() => {
    (async () => {
      try {
        const res = await getStatistics();
        const data: DataTypes = res?.data?.data;
        setStatistics(data);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:17 ~ error:", error);
      }
    })();
  }, []);

  return (
    <>
      <Row className="justify-content-between">
        <Col
          span={24}
          className="mb-3"
          onClick={() => navigate("/all-companies")}
        >
          <div
            className={`${styles.statisticCard} ${
              isLight ? styles.light : styles.dark
            }`}
          >
            {/* <Portfolio className={styles.icon} /> */}
            <Statistic formatter={() => formatter(statistics?.companyAmount)} />
            <p>{t("all_companies")}</p>
          </div>
        </Col>

        <Col span={12} className="pe-2" onClick={() => navigate("/all-jobs")}>
          <div
            className={`${styles.statisticCard} ${
              isLight ? styles.light : styles.dark
            }`}
          >
            {/* <Portfolio className={styles.icon} /> */}
            <Statistic formatter={() => formatter(statistics?.vacancyAmount)} />
            <p>{t("all_jobs")}</p>
          </div>
        </Col>

        <Col
          span={12}
          className="ps-2"
          onClick={() => navigate("/all-internships")}
        >
          <div
            className={`${styles.statisticCard} ${
              isLight ? styles.light : styles.dark
            }`}
          >
            {/* <Portfolio className={styles.icon} /> */}
            <Statistic
              formatter={() => formatter(statistics?.internshipAmount)}
            />
            <p>{t("all_internerships")}</p>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Statistics;
