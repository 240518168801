import { useEffect, useState } from "react";
import { getUnreadNotif } from "../api/profile";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface Data {
  unreadCount: number;
}

export const useUnreadNotif = (delay: number = 5000) => {
  const [count, setCount] = useState<number>(0);
  const { isAuth } = useSelector((state: any) => state.authReducer);
  const { pathname } = useLocation();

  const fetch = async () => {
    try {
      const res = await getUnreadNotif();
      const data: Data = res?.data?.data;
      setCount(data.unreadCount);
    } catch (error: any) {
      console.log(
        "🚀 ~ file: useUnreadNotif.ts:10 ~ setInterval ~ error:",
        error
      );
    }
  };

  useEffect(() => {
    if (isAuth && pathname == "/") {
      fetch();

      const interval = setInterval(fetch, delay);
      return () => clearInterval(interval);
    }
  }, []);

  return { count };
};
