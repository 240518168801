import Banner from "../../Components/Banner";
import Typography from "../../Components/Typography";
import Container from "../../layout/Container";
import Statistics from "./Components/Statistics";
import ProfilePic from "./Components/ProfilePic";
import Topbar from "../../Components/Topbar";
import VacanyCard from "./Components/VacanyCard";
import { Col, Progress, Row, Tag } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();
  const [userId, setUserId] = useState(0);
  const { isLight, jobId } = useSelector((state: any) => state.uiReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (jobId) {
      navigate(`/job/${jobId}`);
    }
  }, []);

  const showId = () => {
    setUserId(565);
  };
  return (
    <Container className="pb-5 mb-5" type="fluid">
      <div className="container">
        {/* Profile pic */}
        <ProfilePic />

        {/* Banner */}
        {/* <Banner /> */}

        {/* <div className="my-4" onClick={showId}>
          {userId ? (
            <div className="text-center d-flex justify-content-center">
              <p className="m-0 me-2">YOUR ID</p>
              <Tag color="#108ee9" className="m-0">
                {userId}
              </Tag>
            </div>
          ) : (
            <>
              <p className="m-0 text-center">
                Please complete the additional information in your profile
              </p>
              <Progress percent={30} />
            </>
          )}
        </div> */}

        {/* Statistics */}
        <Typography title={t("statistics")} className="mt-3" />

        {/* Statistics */}
        <Statistics />

        {/* Job and internships */}
        <Typography title={t("recent_job_list")} className="mt-3" />
      </div>

      <VacanyCard />
    </Container>
  );
};

export default Home;
