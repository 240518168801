import { PropTypeIcon } from "../../../interfaces/index.interface";
import styles from "./styles.module.scss";

const Delete = (props: PropTypeIcon) => {
  const { className } = props;
  return (
    <span className={`icon ${styles.icon} ${className ? className : ""}`} />
  );
};

export default Delete;
