import { Button, Checkbox, Col, Form, Input, message, Modal, Row } from "antd";
import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { postLogin, postLoginOauth2 } from "../../api/auth";
import LogoCircle from "../../assets/icons/LogoCircle";
import AuthBtn from "../../Components/Buttons/AuthBtn";
import {
  ACCESS_TOKEN,
  USER_AGREEMENT_EN,
  USER_AGREEMENT_RU,
  USER_AGREEMENT_UZ,
} from "../../constants";
import { SET_ALL_DATA, SET_ENTERED } from "../../redux/actionTypes";
import styles from "./styles.module.scss";
import { Modal as ModalMobile } from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import google from "../../assets/icons/source/google.svg";
import appStore from "../../assets/icons/source/appStore.svg";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const onFinish = async (values: any) => {
    try {
      setLoading(true);
      const res: any = await postLogin(values);
      let token = res?.data?.data?.token;
      localStorage.setItem(ACCESS_TOKEN, token);

      dispatch({
        type: SET_ALL_DATA,
        payload: {
          isAuth: true,
          data: res?.data?.data?.user,
        },
      });

      message.success("Login success");
      navigate(`/`);
    } catch (error: any) {
      ModalMobile.alert({
        header: (
          <div>
            <ExclamationCircleFill
              style={{
                fontSize: 64,
                color: "var(--adm-color-warning)",
              }}
            />
          </div>
        ),
        title: t("warning_email_username"),
        showCloseButton: true,
        confirmText: "Ok",
      });
      // message.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const googleAuth = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res: any = await postLoginOauth2(response?.access_token);
        console.log("🚀 ~ file: index.tsx:68 ~ onSuccess: ~ res:", res);
        const token = res?.data?.data?.token;

        localStorage.setItem(ACCESS_TOKEN, token);

        dispatch({
          type: SET_ALL_DATA,
          payload: {
            isAuth: true,
            data: res?.data?.data?.user,
          },
        });

        message.success("Login success");
        navigate(`/`);
      } catch (error: any) {
        ModalMobile.alert({
          header: (
            <ExclamationCircleFill
              style={{
                fontSize: 64,
                color: "var(--adm-color-warning)",
              }}
            />
          ),
          title: error?.response?.data?.message,
          showCloseButton: true,
          confirmText: "Ok",
        });
      }
    },
  });

  const appleAuth = async () => {
    ModalMobile.alert({
      header: (
        <div>
          <ExclamationCircleFill
            style={{
              fontSize: 64,
              color: "var(--adm-color-warning)",
            }}
          />
        </div>
      ),
      title: t("apple_warning"),
      showCloseButton: true,
      confirmText: t("ok"),
    });
  };

  const skip = () => {
    dispatch({
      type: SET_ENTERED,
    });
    navigate("/");
  };

  return (
    <Fragment>
      <Form layout="vertical" onFinish={onFinish}>
        {/* Inputs */}
        <Row>
          <Col span={24}>
            <div className="text-center">
              {/* <Link to={"/"}> */}
              <LogoCircle
                class={`${styles.logo} ${isLight ? "logoLight" : "logoDark"}`}
              />
              {/* </Link> */}
              <h2>{t("welcome_to_onecruit")}</h2>
              <p className="px-4">{t("welcome_intro_subtitle")}</p>
            </div>

            <Form.Item
              className="px-3"
              label={t("email_or_username")}
              name="login"
              required={false}
              rules={[
                {
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
              ]}
            >
              <Input allowClear placeholder="Brandonelouis@gmail.com" />
            </Form.Item>

            <Form.Item
              className="px-3"
              label={t("password")}
              name="password"
              required={false}
              rules={[
                {
                  required: true,
                  message: `${t("please_fill_the_field")}`,
                },
              ]}
            >
              <Input.Password allowClear placeholder="*******" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="px-3 d-flex justify-content-between">
          <Col>
            <p></p>
          </Col>
          <Col>
            <NavLink to={"/forgot-password"}>Forgot password</NavLink>
          </Col>
        </Row>

        {/* Login & Skip */}
        <Row className="d-flex justify-content-between px-3 mt-4">
          <Col span={12} className="pe-2 mb-3">
            <Button
              htmlType="submit"
              className="w-100 py-4 d-flex justify-content-center align-items-center"
              loading={loading}
              type="primary"
            >
              {t("Login")}
            </Button>
          </Col>
          <Col span={12} className="ps-2">
            <Button
              onClick={skip}
              className="w-100 py-4 d-flex justify-content-center align-items-center"
            >
              {t("skip")}
            </Button>
          </Col>
        </Row>

        {/* Google & Apple */}
        <Row className="px-3">
          <Col span={12} className="pe-2">
            <Button
              className="me-3 w-100 py-4 d-flex justify-content-center align-items-center"
              onClick={() => googleAuth()}
            >
              <img src={google} alt="" className="me-2" />
              {t("sign_in_with_google")}
            </Button>
          </Col>
          <Col span={12} className="ps-2">
            <Button
              className="w-100 py-4 d-flex justify-content-center align-items-center"
              onClick={() => appleAuth()}
            >
              <img src={appStore} alt="" className="me-2" />
              {t("sign_in_with_apple")}
            </Button>
          </Col>
        </Row>

        {/* Sign up */}
        <Row>
          <Col span={24}>
            <p className="text-center mt-3">
              {t("you_dont_have_an_account_yet")}
              <Link to={"/sign-up"}> {t("sign_up")}</Link>
            </p>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
};

export default Login;
