import React, { useEffect, useRef, useState } from "react";
import Container from "../../../layout/Container";
import GoBack from "../../../Components/Buttons/GoBack";
import Typography from "../../../Components/Typography";
import { Button, Card, Col, Empty, Rate, Row, Spin } from "antd";
import { Image, Swiper, SwiperRef, Tabs } from "antd-mobile";
import { FolderViewOutlined, LoadingOutlined } from "@ant-design/icons";
import { HeartOutline, HeartFill } from "antd-mobile-icons";
import { Image as ImageAntd } from "antd";
import dayjs from "dayjs";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  TypePostLike,
  getLikes,
  getStudentById,
  postLike,
} from "../../../api/profile";
import { StudentDataType } from "./interface.index";
import { IMAGE_PATH } from "../../../constants";
import Meta from "antd/es/card/Meta";
import like_outlined from "../../../assets/icons/png/like_true.png";
import like_inlined from "../../../assets/icons/png/like_false.png";
import { useTranslation } from "react-i18next";
import CustomEmpty from "../../../Components/Empty";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface TypeLike {
  amount: number;
  dataStatus: number;
}

const StudentProfile = () => {
  const { t } = useTranslation();
  const tabItems = [
    { key: "personalInfo", title: `${t("personal_information")}` },
    { key: "educations", title: `${t("educations")}` },
    { key: "work_experience", title: `${t("work_experience")}` },
    { key: "certificate", title: `${t("certificate")}` },
    { key: "projects", title: `${t("projects")}` },
    { key: "beta_project", title: `${t("beta_project")}` },
  ];
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { userID } = useParams();
  const swiperRef = useRef<SwiperRef>(null);
  const [loading, setLoading] = useState(true);
  const [loadingPostLike, setLoadingPostLike] = useState(false);
  const [visible, setVisible] = useState(false);
  const [scaleStep, setScaleStep] = useState(0.5);
  const [amountLike, setAmountLike] = useState<number>(0);
  const [isExistLike, setIsExistLike] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState(
    Number(searchParams.get("activeTab"))
  );
  const [data, setData] = useState<StudentDataType>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getStudentById(userID);
        const data = res?.data?.data;

        setData(data);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:18 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const fetchLike = async () => {
    try {
      const res_like = await getLikes(userID);
      const data_like: any = res_like?.data;
      console.log(
        "🚀 ~ file: index.tsx:73 ~ fetchLike ~ data_like:",
        data_like
      );
      setIsExistLike(data_like.dataStatus === 0 ? false : true);
      setAmountLike(data_like?.data?.amount);
      console.log("🚀 ~ file: index.tsx:47 ~ data_like:", data_like);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:71 ~ fetchLike ~ error:", error);
    }
  };
  useEffect(() => {
    fetchLike();
  }, []);

  const postLikeFunc = async () => {
    try {
      setLoadingPostLike(true);
      const data: TypePostLike = {
        as_like: !isExistLike,
      };
      const res = await postLike(userID, data);
      setIsExistLike(!isExistLike);
      fetchLike();
      console.log("🚀 ~ file: index.tsx:69 ~ postLike ~ res:", res);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:67 ~ postLike ~ error:", error);
    } finally {
      setLoadingPostLike(false);
    }
  };
  return (
    <Container type="fluid" className="bg-white">
      {/* Go back */}
      <GoBack shareIcon shareTitle={`share_this_employee`} to={"/connection"} />
      {loading ? (
        <Spin
          tip="Loading"
          size="large"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="content" />
        </Spin>
      ) : (
        <div className="container">
          <Row>
            <Col span={24}>
              <div className="d-flex justify-content-end position-relative">
                <div
                  className="text-center"
                  style={{ width: "50px", position: "absolute", zIndex: "5" }}
                >
                  <Button
                    // loading={loadingPostLike}
                    style={{ border: "none", width: "55px", height: "45px" }}
                  >
                    {/* {isExistLike ? (
                      <HeartFill fontSize={25} onClick={postLikeFunc} />
                    ) : (
                      <HeartOutline fontSize={25} onClick={postLikeFunc} />
                    )} */}

                    {loadingPostLike ? (
                      <div className="position-relative">
                        <img
                          style={{ width: "25px" }}
                          src={isExistLike ? like_inlined : like_outlined}
                          alt="like image"
                        />
                        {loadingPostLike && (
                          <Spin
                            indicator={antIcon}
                            style={{
                              position: "absolute",
                              left: "0",
                              backgroundColor: "#8080802e",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="position-relative">
                        <img
                          onClick={postLikeFunc}
                          style={{ width: "25px" }}
                          src={isExistLike ? like_inlined : like_outlined}
                          alt="like image"
                        />
                        {loadingPostLike && (
                          <Spin
                            indicator={antIcon}
                            style={{
                              position: "absolute",
                              left: "0",
                              backgroundColor: "#8080802e",
                              borderRadius: "50%",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </Button>
                  <p
                    style={{
                      marginTop: "-10px",
                      position: "relative",
                      zIndex: "6",
                    }}
                  >
                    {amountLike}
                  </p>
                </div>
              </div>
            </Col>
            <Col span={24} className="d-flex justify-content-center">
              {loading ? (
                <Image
                  src="/404"
                  width={70}
                  height={70}
                  className="img-fluid rounded-circle mb-2"
                  style={{
                    marginRight: "12px",
                    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
                  }}
                />
              ) : (
                <>
                  <ImageAntd
                    style={{ display: "none" }}
                    preview={{
                      visible,
                      scaleStep,
                      src: data?.photoPath
                        ? data?.photoPath?.startsWith("https")
                          ? data?.photoPath
                          : IMAGE_PATH + data?.photoPath
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png",
                      onVisibleChange: (value) => {
                        setVisible(value);
                      },
                    }}
                    src={
                      data?.photoPath
                        ? data?.photoPath?.startsWith("https")
                          ? data?.photoPath
                          : IMAGE_PATH + data?.photoPath
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                  />
                  <img
                    style={{ width: "70px", height: "70px" }}
                    onClick={() => setVisible(true)}
                    src={
                      data?.photoPath
                        ? data?.photoPath?.startsWith("https")
                          ? data?.photoPath
                          : IMAGE_PATH + data?.photoPath
                        : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                    }
                    alt=""
                    className="img-fluid rounded-circle "
                  />
                </>
              )}
            </Col>
          </Row>
          {/* Title */}
          <Typography
            className="text-center mb-0"
            colSpan={24}
            title={String(data?.fullname)}
            variant="h2"
          />

          {data?.avgRate ? (
            <Row>
              <Col span={24} className="text-center">
                <Rate
                  allowHalf
                  disabled
                  value={Number(data?.avgRate?.toFixed(1))}
                />
              </Col>
            </Row>
          ) : (
            ""
          )}

          <Row>
            <Col span={24}>
              <Tabs
                className="mb-3"
                activeKey={tabItems[activeIndex].key}
                onChange={(key) => {
                  const index = tabItems.findIndex((item) => item.key === key);
                  setActiveIndex(index);
                  swiperRef.current?.swipeTo(index);
                  setSearchParams({
                    activeTab: String(index),
                  });
                }}
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "2",
                  backgroundColor: "#fff",
                }}
              >
                {tabItems.map((item) => (
                  <Tabs.Tab title={item.title} key={item.key} />
                ))}
              </Tabs>

              <Swiper
                direction="horizontal"
                loop
                indicator={() => null}
                ref={swiperRef}
                defaultIndex={activeIndex}
                onIndexChange={(index) => {
                  setActiveIndex(index);
                }}
              >
                <Swiper.Item>
                  <div className="ps-2">
                    <div className="ctRow mb-2 d-flex flex-wrap">
                      <h6 className="me-2 mb-0">{t("fullname")}: </h6>
                      <span className="m-0">{data?.fullname}</span>
                    </div>
                    <div className="ctRow mb-2 d-flex flex-wrap">
                      <h6 className="me-2 mb-0">{t("about_me")}: </h6>
                      <span className="m-0">{data?.aboutMe}</span>
                    </div>
                    <div className="ctRow mb-2 d-flex flex-wrap">
                      <h6 className="me-2 mb-0">{t("areas")}: </h6>
                      {data?.areas?.map((item, index) => (
                        <span className="me-2 m-0" key={index}>
                          {item.name}
                          {data?.areas?.length - 1 !== index ? "," : ""}
                        </span>
                      ))}
                    </div>
                    <div className="ctRow mb-2 d-flex flex-wrap">
                      <h6 className="me-2 mb-0">{t("skills")}: </h6>
                      {data?.skills?.map((item, index) => (
                        <span className="me-2 mb-0" key={index}>
                          {item.name}
                          {data?.skills?.length - 1 !== index ? "," : ""}
                        </span>
                      ))}
                    </div>
                    <div className="ctRow mb-2 d-flex flex-wrap">
                      <h6 className="me-2 mb-0">{t("languages")}: </h6>
                      {data?.languages?.map((item, index) => (
                        <span className="me-2 mb-0" key={index}>
                          {item.name}({item.level})
                        </span>
                      ))}
                    </div>
                  </div>
                </Swiper.Item>

                <Swiper.Item>
                  <div className="ps-2">
                    {data?.educations ? (
                      data?.educations?.map((item, index) => (
                        <Card className="me-2 mb-2 mb-1" key={index}>
                          <div className="text-end">
                            <strong>{index + 1}</strong>
                          </div>
                          <strong>{t("education_name")}: </strong>
                          {item.institutionName} <br />
                          <strong>{t("level_of_education")}: </strong>
                          {item.levelOfEducation} <br />
                          <strong>{t("field_of_study")}: </strong>
                          {item.fieldOfStudy} <br />
                          <strong>{t("start_date")}: </strong>
                          {dayjs(item.startDate).format("DD-MM-YYYY")} <br />
                          <strong>{t("end_date")}: </strong>
                          {item.endDate
                            ? dayjs(item.endDate).format("DD-MM-YYYY")
                            : t("until_now")}
                          <br />
                          <strong>{t("duration")}: </strong>
                          {item?.duration >= 12
                            ? Math.floor(item?.duration / 12) +
                              ` ${t("year")}` +
                              (item?.duration % 12) +
                              ` ${t("months")}`
                            : item?.duration + ` ${t("months")}`}
                          <br />
                        </Card>
                      ))
                    ) : (
                      <Empty className="mt-5" description={t("no_data")} />
                    )}
                  </div>
                </Swiper.Item>

                <Swiper.Item>
                  <div className="ps-2">
                    {data?.works ? (
                      data?.works?.map((item, index) => (
                        <Card className="me-2 mb-0 mb-2" key={index}>
                          <div className="text-end">
                            <strong>{index + 1}</strong>
                          </div>
                          <strong>{t("company_name")}: </strong>
                          {item.companyName} <br />
                          <strong>{t("description")}: </strong>
                          {item.description} <br />
                          <strong>{t("job_type")}: </strong>
                          {item.jobType} <br />
                          <strong>{t("start_date")}: </strong>
                          {dayjs(item.startDate).format("DD-MM-YYYY")} <br />
                          <strong>{t("end_date")}: </strong>
                          {item.endDate
                            ? dayjs(item.endDate).format("DD-MM-YYYY")
                            : t("until_now")}
                          <br />
                          <strong>Duration: </strong>
                          {item?.duration >= 12
                            ? Math.floor(item?.duration / 12) +
                              ` ${t("year")}` +
                              (item?.duration % 12) +
                              ` ${t("months")}`
                            : item?.duration + ` ${t("months")}`}
                          <br />
                        </Card>
                      ))
                    ) : (
                      <Empty className="mt-5" description={t("no_data")} />
                    )}
                  </div>
                </Swiper.Item>

                <Swiper.Item>
                  <div className="ps-2">
                    {data?.certificates ? (
                      data?.certificates?.map((item, index) => (
                        <Card key={index} className="mb-2">
                          <p className="d-flex justify-content-between m-0">
                            {item.name}
                            <Button
                              type="primary"
                              target="_blank"
                              href={IMAGE_PATH + item.file}
                            >
                              {t("download")}
                            </Button>
                          </p>
                        </Card>
                      ))
                    ) : (
                      <Empty className="mt-5" description={t("no_data")} />
                    )}
                  </div>
                </Swiper.Item>

                <Swiper.Item>
                  <div className="ps-2">
                    {data?.projects ? (
                      data?.projects?.map((item, index) => (
                        <Card
                          key={index}
                          actions={[
                            <span
                              onClick={() =>
                                navigate(
                                  `/profile/project/${item.projectId}?isOwner=false&visit=true`
                                )
                              }
                            >
                              {t("details")}
                            </span>,
                          ]}
                          className="mb-2"
                        >
                          <Meta
                            title={`${t("project_name")}: ${item.name}`}
                            description={`${t("start_date")}: null
                          `}
                          />
                          <Meta
                            title=""
                            description={`${t("end_date")}: ${
                              item.endDate ? item.endDate : t("until_now")
                            }`}
                          />
                        </Card>
                      ))
                    ) : (
                      <Empty className="mt-5" description={t("no_data")} />
                    )}
                  </div>
                </Swiper.Item>

                <Swiper.Item>
                  <div className="ps-2">
                    {data?.betaProjects ? (
                      data?.betaProjects?.map((item, index) => (
                        <Card
                          key={index}
                          actions={[
                            <span
                              onClick={() =>
                                navigate(
                                  `/profile/beta-project/${item.projectId}?isOwner=false&visit=true`
                                )
                              }
                            >
                              {t("details")}
                            </span>,
                          ]}
                          className="mb-2"
                        >
                          <Meta
                            title={`${t("beta_project_name")}: ${item.name}`}
                            description={`${t("start_date")}: null
                          `}
                          />
                          <Meta
                            title=""
                            description={`${t("end_date")}: ${
                              item.endDate ? item.endDate : t("until_now")
                            }`}
                          />
                        </Card>
                      ))
                    ) : (
                      <Empty className="mt-5" description={t("no_data")} />
                    )}
                  </div>
                </Swiper.Item>
              </Swiper>
            </Col>
          </Row>
        </div>
      )}
    </Container>
  );
};

export default StudentProfile;
