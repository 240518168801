import React, { useState } from "react";
import { Image } from "antd";
import { Image as ImageMobile } from "antd-mobile";
import { useSelector } from "react-redux";
import Settings from "../../../../assets/icons/Settings";
import styles from "./styles.module.scss";
import Edit from "../../../../assets/icons/Edit";
import { Link } from "react-router-dom";
import GoBack from "../../../../Components/Buttons/GoBack";
import { IMAGE_PATH } from "../../../../constants";
import { useTranslation } from "react-i18next";

const ProfileCard = ({ version }: any) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [scaleStep, setScaleStep] = useState(0.5);
  const { userData, isAuth } = useSelector((state: any) => state.authReducer);

  return (
    <div className={`${styles.userCard} mb-3 ${styles.hide}`}>
      <div className="container">
        <div className="row">
          <div className="col text-end pt-3">
            {/* <Forward class="me-2" /> */}
            <Link
              to={`${version === "edit" ? "/profile/settings" : "settings"}`}
            >
              <Settings />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col pt-3 px-4 ">
            <div className={styles.profileBox}>
              {/* {userData?.photoUrl ? (
                    <Image
                      width={200}
                      src={`https://onecruit.uz/api/web/files/${userData?.photoUrl}`}
                    />
                  ) : (
                    ""
                  )} */}
              {userData?.photoUrl ? (
                <img
                  onClick={() => setVisible(true)}
                  src={
                    userData?.photoUrl
                      ? userData?.photoUrl?.startsWith("https")
                        ? userData?.photoUrl
                        : IMAGE_PATH + userData?.photoUrl
                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                  }
                  alt=""
                  className="img-fluid rounded-circle"
                />
              ) : (
                <ImageMobile
                  src="/404"
                  width={62}
                  height={62}
                  className="img-fluid rounded-circle"
                />
              )}
              <div className={styles.name}>
                <span>{userData?.fullname}</span>
                <span style={{ opacity: "0.7" }}>{userData?.email}</span>
                {/* {userData?.fullname
                      .split(" ")
                      .map((item: string, index: number) => (
                        <span key={index}>{item}</span>
                      ))} */}
              </div>

              <Image
                width={200}
                style={{ display: "none" }}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
                preview={{
                  visible,
                  scaleStep,
                  src: userData?.photoUrl
                    ? userData?.photoUrl?.startsWith("https")
                      ? userData?.photoUrl
                      : IMAGE_PATH + userData?.photoUrl
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png",
                  onVisibleChange: (value) => {
                    setVisible(value);
                  },
                }}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex justify-content-end">
            <div className={styles.edit}>
              {version === "edit" ? (
                <p onClick={() => alert("It will work soon")}>
                  {t("change_image")}
                </p>
              ) : (
                <Link
                  to={`${isAuth ? "edit" : "/profile/404"}`}
                  style={{ textDecoration: "none" }}
                >
                  <p>
                    {t("edit_profile")} <Edit />
                  </p>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
