import React, { useEffect } from "react";
import Container from "../../../../layout/Container";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import GoBack from "../../../../Components/Buttons/GoBack";
import Typography from "../../../../Components/Typography";
import { getAllNotif } from "../../../../api/profile";
import { MetaType } from "../../../../interfaces/index.interface";
import { useGetAllData } from "../../../../hooks/useGetAllData";
import { Badge, Breadcrumb, Card, Col, Row, Spin } from "antd";
import CustomEmpty from "../../../../Components/Empty";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { NOTIFICATION_TYPE } from "../../../../constants";
import { useSelector } from "react-redux";
import { NotificationType } from "../../interface/index.interface";

type ResType = {
  items: NotificationType[];
  meta: MetaType;
};

type UseGetAllDataTypes = {
  data: ResType;
  error: string;
  loading: boolean;
  fetchData: () => void;
};

const Notifications = () => {
  const { isAuth } = useSelector((state: any) => state.authReducer);
  const { data, error, loading, fetchData }: UseGetAllDataTypes = useGetAllData(
    {
      url: "/student/notifications",
    }
  );
  console.log("🚀 ~ file: index.tsx:38 ~ Notifications ~ data:", data);

  useEffect(() => {
    if (isAuth) {
      const interval = setInterval(fetchData, 5000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack />

      <div className="container">
        {/* Breadcrumb */}
        <Row className="pb-2">
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/notifications"} style={{ textDecoration: "none" }}>
                  Notifications
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        {/* Title */}
        <Typography title="Notifications" variant="h2" />

        {/* List */}
        {loading ? (
          <Spin
            tip="Loading"
            size="large"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="content" />
          </Spin>
        ) : data?.items?.length ? (
          <Row>
            {data?.items.map((item, index) => (
              <Col span={24} key={index} className="mb-3">
                <Link to={String(item.id)} style={{ textDecoration: "none" }}>
                  <Card
                    style={{
                      borderLeft: `8px solid ${
                        item.isRead ? "rgb(200 200 200)" : "#4265f6"
                      }`,
                    }}
                    size="small"
                    title={
                      item.notificationType === NOTIFICATION_TYPE
                        ? "Project invition"
                        : "Other"
                    }
                  >
                    {/* <p className="truncateW100">
                      {item.notificationType === NOTIFICATION_TYPE
                        ? "Project invition"
                        : "Other"}
                    </p> */}
                    <p className="truncateW100 m-0">
                      <b>From:</b> <span>{item.fromUser.fullname}</span>
                    </p>
                    <p className="truncateW100 m-0">
                      <b>Project name:</b> <span>{item.project.name}</span>
                    </p>
                    <p className="text-end">
                      <span style={{ color: "rgb(66, 101, 246)" }}>
                        {dayjs(item.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </p>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        ) : error ? (
          <h1>Error</h1>
        ) : (
          <CustomEmpty type="customize" createBtn={false} />
        )}
      </div>
    </Container>
  );
};

export default Notifications;
