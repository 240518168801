import React, { useEffect, useState } from "react";
import Container from "../../../../../layout/Container";
import GoBack from "../../../../../Components/Buttons/GoBack";
import Typography from "../../../../../Components/Typography";
import { Avatar, Breadcrumb, Col, Row } from "antd";
import { Link, useParams } from "react-router-dom";
import { getNotifById } from "../../../../../api/profile";
import { NotificationType } from "../../../interface/index.interface";
import { NOTIFICATION_TYPE } from "../../../../../constants";
import Meta from "antd/es/card/Meta";
import { Button } from "antd";
import SaveBtn from "../../../../../Components/Buttons/SaveBtn";

const NotificationView = () => {
  const { notificationId } = useParams();
  const [data, setData] = useState<NotificationType>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getNotifById(notificationId);
        const data: NotificationType = res?.data?.data;
        setData(data);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:17 ~ error:", error);
      }
    })();
  }, []);

  return (
    <Container type="fluid">
      {/* Go back */}
      <GoBack />

      <div className="container">
        {/* Breadcrumb */}
        <Row className="pb-2">
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"} style={{ textDecoration: "none" }}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={"/notifications"} style={{ textDecoration: "none" }}>
                  Notifications
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {data?.notificationType === NOTIFICATION_TYPE
                  ? "Project invition"
                  : "Other"}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        {/* Title */}
        {/* <Typography title="Notifications" variant="h2" /> */}

        {/* Data */}
        <h1>Message</h1>

        <div className="text-center mb-3">
          <Meta
            avatar={
              <Avatar
                src={
                  data?.photoUrl
                    ? data.photoUrl
                    : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                }
              />
            }
          />
          <p className="m-0">
            <b>From:</b> {data?.fromUser.fullname}
          </p>
          <p className="m-0">
            <b>Type:</b>{" "}
            {data?.notificationType === NOTIFICATION_TYPE
              ? "Project invition"
              : "Other"}
          </p>
          <p className="m-0">
            <b>Project name:</b> {data?.project.name}
          </p>
          <p className="m-0">
            <b>Project type:</b>{" "}
            {data?.project.isBetaProject ? "Beta project" : "Project"}
          </p>
        </div>

        {/* Btn */}
        <Link
          to={`/profile/${data?.project?.isBetaProject ? "beta-" : ""}project/${
            data?.project?.id
          }?isOwner=false&visit=true`}
        >
          <SaveBtn>View project</SaveBtn>
        </Link>
      </div>
    </Container>
  );
};

export default NotificationView;
