import { Col, Row } from "antd";
import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import Home from "../assets/icons/Home";
import Portfolio from "../assets/icons/Portfolio";
import Banner from "../Components/Banner";
import Navbar from "../Components/Navbar";
import { ACCESS_TOKEN } from "../constants";
import styles from "./styles.module.scss";

interface useData {
  fullname: string;
  photoUrl: string;
}

const Layout = () => {
  const { userData, isAuth } = useSelector((state: any) => state.authReducer);

  // const { fullname, photoUrl } = userData;
  // console.log("🚀 ~ file: index.tsx:8 ~ Layout ~ userData:", userData);

  return (
    <Fragment>
      <Outlet />
      {isAuth && <Navbar />}
    </Fragment>
  );
};

export default Layout;
