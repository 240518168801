import { Fragment, useEffect, useState } from "react";
import {
  Checkbox,
  Col,
  Drawer,
  Empty,
  Input,
  message,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import React from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import {
  getAllLngs,
  getLanguageLevels,
  getStudentLngs,
  postLanguages,
} from "../../../../api/profile";
import GoBack from "../../../../Components/Buttons/GoBack";
import LineIphone from "../../../../assets/icons/LineIphone";
import SaveBtn from "../../../../Components/Buttons/SaveBtn";
import { useTranslation } from "react-i18next";
import Container from "../../../../layout/Container";
import Typography from "../../../../Components/Typography";

const { Search } = Input;

// const levels: Record<number, string> = {
//   1: "A1 - Начальный",
//   2: "A2 - Элементарный",
//   3: "B1 - Средний",
//   4: "B2 - Средне-продвинутый",
//   5: "C1 - Продвинутый",
//   6: "C2 - Совершенстве",
// };

const Language = () => {
  const { t } = useTranslation();
  const [levels, setlevels] = useState<Record<number, string>>([]);
  const [loading, setLoading] = useState(false);
  const [allLanguages, setAllLanguages] = useState([]);
  const [searchedLanguages, setSearchedLanguages] = useState([]);
  const [studentLngs, setStudentLngs] = useState<Array<number>>([]);
  const [studentLevels, setStudentLevels] = useState<{ [key: number]: number }>(
    {}
  );
  const [level, setLevel] = useState(0);
  const [open, setOpen] = useState<any>({ open: false, langId: undefined });

  useEffect(() => {
    (async () => {
      try {
        const res_all_lng = await getAllLngs();
        const res_student_lng = await getStudentLngs();

        setAllLanguages(res_all_lng?.data?.data);

        const keys: any = Object.keys(JSON.parse(res_student_lng?.data?.data));
        setStudentLevels(JSON.parse(res_student_lng?.data?.data));
        setStudentLngs(keys?.map((item: any) => Number(item)));
      } catch (error) {
        console.log("🚀 ~ file: index.tsx:15 ~ error:", error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        // Languages
        const res = await getLanguageLevels();
        const parsedData = JSON.parse(res?.data?.data);

        setlevels(parsedData);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:77 ~ error:", error);
      }
    })();
  }, []);

  const showDrawer = (id: number) => {
    setOpen({ open: true, langId: id });
  };

  const onClose = () => {
    setOpen(false);
  };

  const onChange = (e: boolean, id: any) => {
    if (e) {
      showDrawer(id);
    } else {
      setStudentLngs(studentLngs?.filter((item) => item !== id));
      let data = studentLevels;
      delete data[id];
      setStudentLevels(data);
    }
  };

  const handlerLevel = (e: RadioChangeEvent) => {
    let levelId = e.target.value;
    setStudentLngs([...studentLngs, open.langId]);
    setStudentLevels({ ...studentLevels, [open.langId]: levelId });
    onClose();
  };

  const onSearch = (e: any) => {
    const filtered = allLanguages.filter((item: any) =>
      item.name.toLowerCase().startsWith(e.target.value.toLowerCase())
    );
    filtered?.length && setSearchedLanguages(filtered);
  };

  const handlerSave = () => {
    (async () => {
      let str = Object.entries(studentLevels)
        ?.map(([key, value]: any) => {
          return `${key}:${value}`;
        })
        .join(",");

      const data = {
        text: str,
      };
      try {
        setLoading(true);
        const res = await postLanguages(data);
        message.success(t("saved"));
      } catch (err: any) {
        console.log("🚀 ~ file: index.tsx:100 ~ err:", err);
        message.error(err?.response?.data?.error);
      } finally {
        setLoading(false);
      }
    })();
  };

  const mapLng = (item: any, index: number) => {
    return (
      <Col span={24} key={index} className="mb-4">
        <Checkbox
          className="w-100"
          onChange={(e: any) => onChange(e.target.checked, item.id)}
          checked={studentLngs.includes(Number(item.id))}
        >
          <h5 className="mb-0 ms-2">{item?.name}</h5>
          {studentLngs.includes(Number(item.id)) ? (
            <span className="ms-2">{levels[studentLevels[item.id]]}</span>
          ) : (
            ""
          )}
        </Checkbox>
      </Col>
    );
  };

  return (
    <Container type="fluid">
      <GoBack to={"/profile"} />
      <div className="container">
        {/* Title */}
        <Typography title={t("adding_a_language")} variant="h2" />

        <Row>
          <Col span={24} className="mb-3">
            <Input
              placeholder={String(t("please_enter_language_name"))}
              onChange={(e: any) => onSearch(e)}
              className="w-100 mb-2"
            />
          </Col>
        </Row>
        <Row>
          {searchedLanguages?.length
            ? searchedLanguages.map((item: any, index: any) =>
                mapLng(item, index)
              )
            : allLanguages.map((item: any, index: any) => mapLng(item, index))}

          <Drawer
            placement={"bottom"}
            closable={false}
            onClose={onClose}
            open={open.open}
            key={"bottom"}
            style={{ borderRadius: "20px 20px 0 0" }}
            height={320}
          >
            <div className="wapper text-center">
              <LineIphone className={"drawerBlueLine"} />
            </div>
            <Radio.Group onChange={handlerLevel} value={level}>
              {Object.entries(levels)?.map((item, index) => (
                <Radio value={item[0]} className="w-100 mb-4" key={item[0]}>
                  <span className="ms-2">{item[1]}</span>
                </Radio>
              ))}
            </Radio.Group>
          </Drawer>
          <SaveBtn onClick={handlerSave} loading={loading}>
            {String(t("save"))}
          </SaveBtn>
        </Row>
      </div>
    </Container>
  );
};

export default Language;
