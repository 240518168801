import { Col, DatePicker, Form, Input, message, Row } from "antd";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SaveBtn from "../../../../Components/Buttons/SaveBtn";
import ProfileCard from "../../Components/ProfileCard";
import moment from "moment";
import { updateMe } from "../../../../api/auth";
import { ACCESS_TOKEN } from "../../../../constants";
import { SET_USER_DATA } from "../../../../redux/actionTypes";
import GoBack from "../../../../Components/Buttons/GoBack";
import Container from "../../../../layout/Container";
import { useTranslation } from "react-i18next";

const MyProfile = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { userData } = useSelector((state: any) => state.authReducer);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: any) => {
    // console.log("🚀 ~ file: index.tsx:17 ~ onFinish ~ values:", values);
    // console.log(
    //   "🚀 ~ file: index.tsx:8 ~ onFinish ~ values:",
    //   moment(values.birthday).format("DD-MM-YYYY")
    // );

    const data = {
      fullname: values?.fullname,
      birthday: dayjs(values.birthday).format("YYYY-MM-DD"),
    };

    try {
      setLoading(true);
      const res = await updateMe(data, "profile");
      dispatch({
        type: SET_USER_DATA,
        payload: res?.data?.data,
      });

      message.success(t("profile_updated"));
    } catch (error) {
      console.log("🚀 ~ file: index.tsx:19 ~ onFinish ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      email: userData?.email,
      fullname: userData?.fullname,
      birthday: userData?.birthday ? dayjs(userData?.birthday) : "",
    });
  }, []);

  return (
    <Container type="fluid">
      <ProfileCard version={"edit"} />

      <Form form={form} layout="vertical" onFinish={onFinish}>
        {/* <Row className="px-2">
          <Col>
            <GoBack to={-1} />
          </Col>
        </Row> */}
        <GoBack to={"/profile"} />

        <div className="container">
          <Row>
            <Col span={24}>
              <Form.Item
                required={false}
                name="fullname"
                label={t("fullname")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <Input placeholder="Brandone Louis" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="birthday"
                required={false}
                label={t("birthday")}
                rules={[
                  {
                    required: true,
                    message: String(t("please_fill_the_field")),
                  },
                ]}
              >
                <DatePicker
                  placeholder={String(t("select_date"))}
                  className="w-100"
                  format={"DD-MM-YYYY"}
                />
                {/* <Input placeholder="Brandone Louis" /> */}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="email" label={t("email")}>
                <Input placeholder="Brandonelouis@gmail.com " disabled />
              </Form.Item>
            </Col>
          </Row>
          <SaveBtn loading={loading}>{String(t("save"))}</SaveBtn>
        </div>
      </Form>
    </Container>
  );
};

export default MyProfile;
