import React, { useEffect, useState } from "react";
import Container from "../../../../layout/Container";
import GoBack from "../../../../Components/Buttons/GoBack";
import { getLikes } from "../../../../api/profile";
import { LikeOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Col, Row, Spin, Statistic } from "antd";
import like_inlined from "../../../../assets/icons/png/like_false.png";
import LoaderOpacity from "../../../../layout/LoaderDark";
import Typography from "../../../../Components/Typography";
import { useTranslation } from "react-i18next";

type ResType = {
  amount: number;
};
const MyStatistics = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: any) => state.authReducer);
  const [totalVote, setTotalVote] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const res = await getLikes(userData?.id);
        const data: ResType = res?.data?.data;
        setTotalVote(data?.amount);
        console.log("🚀 ~ file: index.tsx:15 ~ data:", data);
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:11 ~ error:", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Container type="fluid" className="bg-white">
      {/* Go back */}
      <GoBack to={"/profile"} />

      <div className="container">
        <Typography
          title={t("my_statistics")}
          variant="h2"
          className="text-center"
          colSpan={24}
        />

        {loading ? (
          <Spin
            tip="Loading"
            size="large"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="content" />
          </Spin>
        ) : (
          <Row>
            <Col span={12}>
              <Statistic
                title={t("total_votes")}
                value={totalVote}
                prefix={
                  <img
                    style={{ width: "23px" }}
                    src={like_inlined}
                    alt="like image"
                  />
                }
              />
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default MyStatistics;
