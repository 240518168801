import styles from "./styles.module.scss";

const WorkExperience = (props: any) => {
  return (
    <span
      className={`icon ${styles.arrow} ${props?.class ? props.class : ""}`}
    />
  );
};

export default WorkExperience;
