import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { aboutMe } from "./api/auth";
import { ACCESS_TOKEN } from "./constants";
import Layout from "./layout";
import CustomComponent from "./layout/Component";
import Loader from "./layout/Loader";
import PageNotFound from "./pages/NotFound";
import { IS_ENTERED, SET_ALL_DATA, SET_ENTERED } from "./redux/actionTypes";
import { routes as all_routes } from "./route/Routes";
import {
  getPrivateRoutes,
  getPublicRoutes,
  usePermission,
} from "./route/configs";
import { useTranslation } from "react-i18next";

function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  // console.log(
  //   "🚀 ~ file: App.tsx:29 ~ App ~ location:",
  //   location.search.split("=")
  // );
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const routes = getPrivateRoutes();
  const publicRoutes = getPublicRoutes();
  const navigate = useNavigate();
  const { isPermitted, isAuth } = usePermission();
  const [params, setParams] = useSearchParams();

  // useEffect(() => {
  //   if (params.get("isEntered")) {
  //     dispatch({
  //       type: SET_ENTERED,
  //     });
  //   }
  // }, []);

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("serviceWorker.js")
      .then((reg) => console.log("service worker registered"))
      .catch((err) => console.log("service worker not registered", err));
  }

  const permittedRoutes = () => {
    return routes?.filter((item) => isPermitted(item.key));
  };

  const allRoutes = () => {
    return all_routes.filter((route) => route.config.layout === "layout");
  };

  useEffect(() => {
    if (location?.search) {
      const currenLng = location?.search?.split("=")?.[1];

      i18n.changeLanguage(currenLng);
      localStorage.setItem("lng", currenLng);
    }
    (async () => {
      const token = localStorage.getItem(ACCESS_TOKEN);

      if (token) {
        try {
          let resp = await aboutMe();
          if (resp.data?.dataStatus === 1) {
            dispatch({
              type: SET_ALL_DATA,
              payload: { isAuth: true, data: resp.data?.data },
            });
          } else {
            // message.error(resp.data?.message);
            if (params.get("isEntered")) {
              dispatch({
                type: SET_ENTERED,
              });
              navigate(`${location.pathname}`);
            } else {
              navigate(`/`);
            }
          }
          setLoading(false);
        } catch (error) {
          // message.error(
          //   error?.response ? error?.response?.data?.message : error?.message
          // );
          navigate(`/`);
          setLoading(false);
        }
      } else {
        if (params.get("isEntered")) {
          dispatch({
            type: SET_ENTERED,
          });
          navigate(`${location.pathname}`);
        }
        setLoading(false);
      }
    })();
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <Routes>
      {(isAuth || localStorage.getItem(IS_ENTERED) || location.search) && (
        <Route path="/" element={<Layout />}>
          {allRoutes()?.map((item: any, i: any) => (
            <Route
              key={i}
              path={`/${item.path}`}
              element={<CustomComponent Component={item.component} />}
            >
              {item?.children?.map((child: any, inx: any) => (
                <Route
                  key={inx}
                  path={`/${child.path}`}
                  element={<CustomComponent Component={child.component} />}
                />
              ))}
            </Route>
          ))}
        </Route>
      )}
      {publicRoutes?.map((item, i) => (
        <Route
          key={i}
          path={item.path}
          element={<CustomComponent Component={item.component} />}
        />
      ))}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
