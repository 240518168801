import { Button, Card, Col, Drawer, Empty, message, Modal, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  UploadOutlined,
  FolderViewOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { EditSOutline } from "antd-mobile-icons";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { deleteCertificate, getAllCertificate } from "../../../../api/profile";
import { CertificateType } from "../../../../interfaces/index.interface";
import GoBack from "../../../../Components/Buttons/GoBack";
import { IMAGE_PATH } from "../../../../constants";
import LineIphone from "../../../../assets/icons/LineIphone";
import { mapSkeleton } from "../../../../utils/MapSkeleton";
import CustomEmpty from "../../../../Components/Empty";
import Container from "../../../../layout/Container";
import Typography from "../../../../Components/Typography";
import { useTranslation } from "react-i18next";

const Certificate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [certificates, setCertificates] = useState<CertificateType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imagePath, setImagePath] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number>(0);

  const toUpdatePage = (id: number) => {
    navigate(`${id}`);
  };

  const showModal = (path: string) => {
    setImagePath(path);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setImagePath("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setImagePath("");
    setIsModalOpen(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getAllCertificate();
        setCertificates(res?.data?.data);
        // console.log("🚀 ~ file: index.tsx:43 ~ res:", res?.data?.data);
      } catch (error) {
        console.log("🚀 ~ file: index.tsx:44 ~ error:", error);
      }
    })();
  }, []);

  const openDelete = (id: number) => {
    setOpen(true);
    setId(id);
  };

  const onCloseDelete = () => {
    setOpen(false);
  };

  const handlerDelete = async () => {
    try {
      setLoading(true);
      const res = await deleteCertificate(id);
      message.success("Certificate deleted");
      setCertificates(
        certificates.filter((item: CertificateType) => item.id !== id)
      );
      setOpen(false);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:65 ~ handlerDeleteAcc ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container type="fluid">
      {/* Back */}
      <GoBack extraIcon={{ path: "upload" }} to={"/profile"} />
      <div className={`container ${styles.container}`}>
        {/* Title */}
        <Typography title={t("my_certificate")} variant="h2" />

        {/* List */}
        <Row>
          {loading ? (
            Array.from({ length: 2 }).map((item: any, index: number) =>
              mapSkeleton(index)
            )
          ) : certificates?.length ? (
            certificates.map((item: CertificateType, index: number) => (
              <Col span={24} key={index}>
                <Card style={{ width: 300 }} className="mb-2 w-100">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="m-0">
                      <b>{t("name")}:</b> {item.name}
                      <span className="d-block">
                        <b>{t("issue_date")}:</b>
                        {dayjs(item.dueDate).format("DD-MM-YYYY")}
                      </span>
                    </p>
                    <div className="d-flex">
                      {/* <FileTextOutlined /> */}

                      {/* <FolderViewOutlined
                        style={{ fontSize: "20px" }}
                        className="me-3"
                        onClick={() => showModal(item.file)}
                      /> */}

                      <EditSOutline
                        onClick={() => toUpdatePage(item.id)}
                        style={{ fontSize: "20px" }}
                        className="me-3"
                      />
                      <DeleteOutlined
                        style={{ fontSize: "20px" }}
                        onClick={() => openDelete(item.id)}
                      />
                    </div>
                  </div>
                  {/* <div className="w-100 d-flex">
                          <Button className="w-100" type="primary">
                            View
                          </Button>
                          <Button className="w-100 mx-2" type="primary" ghost>
                            Edit
                          </Button>
                          <Button className="w-100" type="primary" danger>
                            Delete
                          </Button>
                        </div> */}
                </Card>
              </Col>
            ))
          ) : (
            <CustomEmpty />
          )}

          {/* <Empty
                  className="mt-5"
                  description="No certificate added yet"
                /> */}
        </Row>

        {/* Modal */}
        <Modal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          centered
          footer={null}
        >
          {imagePath ? (
            <iframe
              src={`http://docs.google.com/gview?url=${IMAGE_PATH}${imagePath}&embedded=true`}
              width="100%"
              height="500px"
            ></iframe>
          ) : (
            <Empty />
          )}

          {/* <iframe
          src={`${IMAGE_PATH}/certificates/865aa200-6247-42cd-9725-a546b83899fe.pdf#toolbar=0&navpanes=0&scrollbar=0`}
          width="100%"
          height="500px"
        ></iframe> */}
        </Modal>

        {/* Delete */}
        <Drawer
          height={250}
          placement={"bottom"}
          closable={false}
          onClose={onCloseDelete}
          open={open}
          key={"bottom"}
          className={`drawerModal`}
        >
          <div className="wrapper text-center">
            <LineIphone className={"drawerBlueLine"} />
            <h3 className={`mt-2 danger`}>{t("delete_certificate")}</h3>
            <p className="mb-4">{t("warning_delete_certificate")}</p>
            <Button
              type="primary"
              className="w-100 mb-2"
              htmlType="submit"
              style={{ height: 48 }}
              onClick={handlerDelete}
              loading={loading}
            >
              {t("yes")}
            </Button>
            <Button
              type="default"
              className="w-100"
              style={{ height: 48 }}
              onClick={() => setOpen(false)}
            >
              {t("cancel")}
            </Button>
          </div>
        </Drawer>
      </div>
    </Container>
  );
};

export default Certificate;
