import { enteredReducer } from "./is-entered";
import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { uiReducer } from "./ui-slice";
const reducers = combineReducers({
  authReducer,
  uiReducer,
  enteredReducer,
});

export default reducers;
