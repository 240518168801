import { TypeAxiosConfig } from "../interfaces/index.interface";
import { useEffect, useState } from "react";
import { getJobOnType } from "../api/profile";
import useDebounce from "./useDebounce";
import instance from "../configs/axios";

type PropType = {
  url: string;
  language?: "uz" | "ru" | "en";
};

export const useGetOptionsStr = (props: PropType) => {
  const { url, language = "en" } = props;

  // Autocomplate Options
  const [options, setOptions] = useState<{ value: string }[]>([]);

  //   Search value
  const [search, setSearch] = useState<string>("");
  const debounce = useDebounce(search);

  //   Handler
  const handlerSearch = (data: any) => {
    setOptions([]);
  };

  //   OnSearch
  const getPanelValue = (searchText: string) => {
    setSearch(searchText);
  };

  // Get Options on type
  useEffect(() => {
    (async () => {
      if (debounce) {
        try {
          const config: TypeAxiosConfig = {
            url: `${url}?searchAny=${debounce}`,
            method: "get",
            headers: {
              "ACCEPT-LANGUAGE": language,
            },
          };
          const res = await instance(config);
          setOptions([]);
          const data: any = res?.data?.data;
          for (let i = 0; i < data.length; i++) {
            setOptions((prevState) => [
              ...prevState,
              {
                value: data[i],
              },
            ]);
          }
        } catch (error: any) {
          console.log("🚀 ~ file: index.tsx:105 ~ error:", error);
        }
      }
    })();
  }, [debounce]);
  return { options, getPanelValue, handlerSearch };
};
