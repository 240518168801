import React, { Fragment, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import google from "../../../../assets/icons/source/google.svg";
import IconSave from "../../../../assets/icons/IconSave";
import {
  AutoComplete,
  Button,
  Col,
  Collapse,
  Drawer,
  Empty,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  RadioChangeEvent,
  Row,
  Slider,
  Space,
  Spin,
  message,
} from "antd";
import { Modal as MobileModal } from "antd-mobile";
import { useGetAllData } from "../../../../hooks/useGetAllData";
import {
  AreaSkillTypes,
  MetaType,
  ParamsType,
} from "../../../../interfaces/index.interface";
import { FilteredDataType, ItemType } from "../../interface/index.interface";
import { SettingOutlined } from "@ant-design/icons";
import saveBtn from "../../../../assets/icons/source/SaveBtn.svg";
import savedBtn from "../../../../assets/icons/source/SavedBtn.svg";
import { DotLoading, InfiniteScroll, List } from "antd-mobile";
import { getVacanies, postApplyJob } from "../../../../api/profile";
import { IMAGE_PATH } from "../../../../constants";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Topbar from "../../../../Components/Topbar";
import CustomEmpty from "../../../../Components/Empty";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { getAllSkillsGroup } from "../../../../api/profile";
import { SliderMarks } from "antd/es/slider";
import LineIphone from "../../../../assets/icons/LineIphone";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;
interface DataType {
  meta: MetaType;
  items: ItemType[];
}

type UseGetAllDataType = {
  loading: boolean;
  error: string;
  data: DataType;
};

type PropType = {
  jobType?: "INTERNSHIP" | "VACANCY";
};

const VacanyCard = (props: PropType) => {
  const { t } = useTranslation();
  const { jobType } = props;

  const { isAuth } = useSelector((state: any) => state.authReducer);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingApply, setLoadingApply] = useState(false);
  const [openApply, setOpenApply] = useState(false);
  const [id, setId] = useState<number>(Number);
  const [jobIndex, setJobIndex] = useState<number>(Number);
  const [save, setSave] = useState<boolean>(false);
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });

  const [listJobs, setListJobs] = useState<ItemType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  // Filter states START
  const [valueJobType, setValueJobType] = useState("");
  const [valueType, setValueType] = useState("");
  const [valueExperience, setValueExperience] = useState<string>("");
  const [valuePosition, setValuePosition] = useState("");
  const [areaOptions, setAreaOptions] = useState<{ value: string }[]>([]);
  const [valueAre, setValueAre] = useState<string>("");
  const [valueMinSalary, setvalueMinSalary] = useState<number>();
  const [valueMaxSalary, setvalueMaxSalary] = useState<number>();
  const [minMaxSalValidator, setMinMaxSalValidator] = useState(false);
  // Filter states END

  const [filtered_data, setFiltered_data] = useState<FilteredDataType>({
    page: params.page,
    size: params.size,
    searchAny: searchValue,
    type: jobType ? jobType : checkParam("job_type"),
    job_type: checkParam("type"),
    experience: checkParam("experience"),
    position: checkParam("position"),
    area: checkParam("area"),
    minSalary: checkParam("minSalary"),
    maxSalary: checkParam("maxSalary"),
  });

  useEffect(() => {
    // filtered_data.job_type = jobType;
    (async () => {
      try {
        const res = await getAllSkillsGroup();
        const data: AreaSkillTypes[] = res?.data?.data;

        setAreaOptions([]);

        for (let i = 0; i < data.length; i++) {
          setAreaOptions((prevState) => [
            ...prevState,
            {
              value: data[i].name,
            },
          ]);
        }
      } catch (error: any) {
        console.log("🚀 ~ file: index.tsx:76 ~ error:", error);
      }
    })();
  }, []);

  //   const { loading, error, data }: UseGetAllDataType = useGetAllData({
  //     url: "/student/job-position/filter",
  //   });
  //   console.log("🚀 ~ file: index.tsx:12 ~ VacanyCard ~ data:", data);

  function checkParam(value: string): string | null {
    return searchParams.get(value);
  }

  // const filtered_data: FilteredDataType = {
  //   page: params.page,
  //   size: params.size,
  //   searchAny: searchValue,
  //   job_type: checkParam("job_type"),
  //   type: checkParam("type"),
  //   experience: checkParam("experience"),
  //   position: checkParam("position"),
  //   area: checkParam("area"),
  //   minSalary: checkParam("minSalary"),
  //   maxSalary: checkParam("maxSalary"),
  // };

  const clearFiltersValue = () => {
    setListJobs([]);
    params.page = 1;
    params.size = 10;
    filtered_data.page = 1;
    filtered_data.size = 10;
    filtered_data.area = null;
    filtered_data.experience = null;
    filtered_data.maxSalary = null;
    filtered_data.minSalary = null;
    filtered_data.position = null;
    filtered_data.type = null;
    filtered_data.searchAny = null;
    if (jobType) {
      filtered_data.job_type = jobType;
    } else {
      filtered_data.job_type = null;
    }
  };
  const loadMore = async () => {
    try {
      setLoading(true);

      console.log(filtered_data);

      const res = await getVacanies(filtered_data);
      const data: DataType = res?.data?.data;
      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      setFiltered_data((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));

      setListJobs((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMore(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>
            {t("no_more")}
            {/* {jobType === "VACANCY" ? "vacancy" : "internships"} */}
          </span>
        )}
      </>
    );
  };

  const saveJob = async () => {
    setSave((prevState) => !prevState);
    !save && message.success("Job saved");
  };

  const resetSearch = () => {
    params.page = 1;
    params.size = 10;
    setListJobs([]);
  };

  const onFinish = async (values?: any) => {
    resetSearch();

    try {
      setLoading(true);
      const res = await getVacanies(filtered_data);
      const data: DataType = res?.data?.data;
      setHasMore(data.meta.hasNextPage);

      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      setListJobs(data.items);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:112 ~ loadMore ~ error:", error);
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (e: string) => {
    filtered_data.page = 1;
    filtered_data.searchAny = e;
    // setFiltered_data((prevState) => ({
    //   ...prevState,
    //   searchAny: e,
    //   page: 1,
    // }));
    setSearchValue(e);
    onFinish();
    if (e?.length === 0) {
      resetSearch();
      setSearchValue(null);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  const showModal = () => {
    setOpen(true);

    form.setFieldsValue({
      job_type: checkParam("job_type") ? checkParam("job_type") : valueJobType,
      type: checkParam("type") ? checkParam("type") : valueType,
      experience: checkParam("experience")
        ? Number(checkParam("experience"))
        : valueExperience,
      position: checkParam("position") ? checkParam("position") : valuePosition,
      area: checkParam("area") ? checkParam("area") : valueAre,
      min: checkParam("min") ? checkParam("min") : valueMinSalary,
      max: checkParam("max") ? checkParam("max") : valueMaxSalary,
    });

    // MobileModal.alert({
    //   header: (
    //     <div>
    //       <Form layout="vertical" onFinish={onFinishFilter}>
    //         <Form.Item
    //           label="Last update"
    //           name="login"
    //           required={false}
    //           rules={[
    //             {
    //               required: true,
    //               message: "Please fill the field!",
    //             },
    //           ]}
    //         >
    //           <Input allowClear placeholder="Brandonelouis@gmail.com" />
    //         </Form.Item>
    //       </Form>
    //     </div>
    //   ),
    //   showCloseButton: true,
    //   confirmText: "Apply",
    // });
  };

  const onChange = (key: string | string[]) => {
    // console.log(key);
  };

  // Filter Functions START
  const onFinishFilter = async (values: any) => {
    await setSearchParams({
      job_type: valueJobType,
      type: valueType,

      position: valuePosition,
      ...(valueExperience && { experience: String(valueExperience) }),
      ...(valueAre && { area: values.area }),
      ...(valueMinSalary && { minSalary: values.min, maxSalary: values.max }),
    });

    filtered_data.page = 1;
    filtered_data.area = values.area;
    filtered_data.experience = String(valueExperience);
    filtered_data.job_type = valueJobType;
    filtered_data.minSalary = values.min;
    filtered_data.maxSalary = values.max;
    filtered_data.position = valuePosition;
    filtered_data.type = jobType ? jobType : valueType;

    setFiltered_data((prevState) => ({
      ...prevState,
      area: values.area,
      experience: String(valueExperience),
      job_type: valueJobType,
      minSalary: values.min,
      maxSalary: values.max,
      position: valuePosition,
      type: valueType,
    }));
    resetSearch();
    loadMore();
    // console.log("🚀 ~ file: index.tsx:132 ~ onFinishFilter ~ values:", values);
    handleCancel();
  };

  // Changes
  const onChangeJobType = (e: RadioChangeEvent) => {
    setValueJobType(e.target.value);
  };
  const onChangeType = (e: RadioChangeEvent) => {
    setValueType(e.target.value);
  };
  const onChangeExperience = (e: any) => {
    const value: string = e.target.value;
    setValueExperience(value);
  };
  const onChangePosition = (e: RadioChangeEvent) => {
    setValuePosition(e.target.value);
  };
  const onChangeArea = (e: string) => {
    setValueAre(e);
  };
  const onChangeMinSal = (e: any) => {
    const value = Number(e.target.value);

    setMinMaxSalValidator(value > 0 ? true : false);

    setvalueMinSalary(value);
  };
  const onChangeMaxSal = (e: any) => {
    const value = Number(e.target.value);
    if (e <= 0) {
      setMinMaxSalValidator(true);
    } else {
      setvalueMaxSalary(value);
    }
  };

  const clearFilters = () => {
    clearFiltersValue();
    setSearchParams({});
    // resetSearch();
    loadMore();
  };

  // Filter Functions END

  const applyJob = async () => {
    try {
      setLoadingApply(true);
      const res = await postApplyJob(String(id));
      listJobs[jobIndex].applied = true;
      message.success("Applied successfully");
    } catch (error: any) {
      const err = error.response.data.message;
      message.error(err);
      console.log("🚀 ~ file: index.tsx:79 ~ applyJob ~ error:", error);
    } finally {
      setOpenApply(false);
      setLoadingApply(false);
    }
  };

  const onCloseApply = () => {
    setOpenApply(false);
  };

  const onShowApply = (id: number, index: number) => {
    setOpenApply(true);
    setId(id);
    setJobIndex(index);
  };

  return (
    <Fragment>
      {/* Navbar */}
      <Topbar
        onFinish={onFinish}
        onSearch={onSearch}
        setOpen={setOpen}
        showModal={showModal}
        clearFilters={clearFilters}
        open={open}
      />

      <div className="container">
        <Row>
          <div className="w-100">
            {listJobs?.length ? (
              listJobs?.map((item, index) => (
                <Col span={24} key={index}>
                  <div className={styles.card}>
                    <Link
                      to={`${jobType ? "/" : ""}job/${item.jobId}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className={styles.firstRow}>
                        <span className={styles.companyImg}>
                          <img
                            src={
                              item.photoUrl
                                ? `${IMAGE_PATH + "/" + item.photoUrl}`
                                : "https://forcebrands.com/assets/fallback/company-default-4549373b79625823b56e48c7918608f77be903ad2fd38cfc9b6929d095994013.png"
                            }
                            alt=""
                          />
                        </span>

                        <p className="truncateW100 text-end">{item.name}</p>

                        {/* <span className={styles.saveIcon}> */}
                        {/* <img src={save ? savedBtn : saveBtn} alt="" onClick={saveJob}/> */}
                        {/* <IconSave /> */}
                        {/* </span> */}
                      </div>

                      <div className={styles.secondRow}>
                        {/* <p>{item.name}</p> */}
                        <p className="truncateW100">
                          {t("company")}: <span>{item.company}</span>
                        </p>
                        <p className="truncateW100">
                          {t("addres")}: <span>{item.address}</span>
                        </p>
                        <p className="truncateW100">
                          {t("experience")}:{" "}
                          <span>{item.experience} Years</span>
                        </p>
                      </div>

                      <div className={styles.tags}>
                        <span>{item.position}</span>
                        <span>{item.area}</span>
                        <span style={{ textTransform: "lowercase" }}>
                          {item.type}
                        </span>
                      </div>
                    </Link>

                    <div className={styles.thirdRow}>
                      <span className={styles.timePost}>{item.createdAt}</span>
                      {/* {item.applied ? "Applied" : ""} */}
                      <p>
                        {item?.salary === 0
                          ? item?.type === "VACANCY"
                            ? t("Depending on interview's result")
                            : t("Free salary")
                          : `${item?.salary}/${t("mo")}`}
                        {/* <span>{item.salary}</span> */}
                      </p>
                      {isAuth ? (
                        !item.applied ? (
                          <>
                            {/* <Button
                              type="primary"
                              onClick={() => onShowApply(item.jobId, index)}
                            >
                              Apply
                            </Button> */}
                          </>
                        ) : (
                          <span style={{ color: "#1677ff" }}>
                            {t("applied")}
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Col>
              ))
            ) : loading ? (
              ""
            ) : (
              <Empty className="mt-5" description={t("no_data")} />
            )}
          </div>

          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            className="text-center w-100"
          >
            {listJobs?.length ? (
              <InfiniteScrollContent hasMore={hasMore} />
            ) : (
              ""
            )}
          </InfiniteScroll>
        </Row>
      </div>

      <Modal
        title={t("filter")}
        open={open}
        onCancel={handleCancel}
        // onOk={onFinishFilter}
        centered
        footer={false}
      >
        <div
          style={{
            maxHeight: "80vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Form
            layout="vertical"
            onFinish={onFinishFilter}
            form={form}
            style={{ position: "relative" }}
          >
            <Collapse
              ghost
              // defaultActiveKey={["1", "2", "3"]}
              onChange={onChange}
              expandIconPosition={"end"}
            >
              <Panel header={t("job_type")} key="1">
                <Form.Item name="job_type" required={false} className="m-0">
                  <Radio.Group
                    value={valueJobType}
                    defaultValue={valueJobType}
                    onChange={onChangeJobType}
                  >
                    <Space direction="vertical">
                      <Radio value={"office"}>Office</Radio>
                      <Radio value={"online"}>Online</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Panel>

              {!jobType && (
                <Panel header={t("type")} key="2">
                  <Form.Item name="type" required={false} className="m-0">
                    <Radio.Group
                      value={valueType}
                      defaultValue={valueType}
                      onChange={onChangeType}
                    >
                      <Space direction="vertical">
                        <Radio value={"INTERNSHIP"}>Internships</Radio>
                        <Radio value={"VACANCY"}>Vacancy</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Panel>
              )}

              <Panel header={t("position")} key="3">
                <Form.Item name="position" required={false} className="m-0">
                  <Radio.Group
                    value={valuePosition}
                    defaultValue={valuePosition}
                    onChange={onChangePosition}
                  >
                    <Space direction="vertical">
                      <Radio value={"junior"}>Junior</Radio>
                      <Radio value={"middle"}>Middle</Radio>
                      <Radio value={"senior"}>Senior</Radio>
                      <Radio value={"teamlead"}>Teamlead</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Panel>

              <Panel header={t("experience")} key="4">
                <Form.Item name="experience" required={false} className="m-0">
                  {/* <Slider
                  defaultValue={valueExperience}
                  value={valueExperience}
                  min={1}
                  max={20}
                  onChange={onChangeExperience}
                /> */}
                  {/* <span>From: {valueExperience}-Year</span> */}
                  <Input
                    onChange={onChangeExperience}
                    placeholder="1"
                    defaultValue={valueExperience}
                    value={valueExperience}
                    type="number"
                    suffix={t("more_year")}
                    className="inputDisableArrow"
                  />
                </Form.Item>
              </Panel>

              <Panel header={t("area")} key="5">
                <Form.Item name="area" required={false} className="m-0">
                  <AutoComplete
                    allowClear
                    value={valueAre}
                    defaultValue={valueAre}
                    onChange={onChangeArea}
                    options={areaOptions}
                    placeholder="Backend"
                    filterOption={(inputValue, option) =>
                      option!.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                  />
                </Form.Item>
              </Panel>

              <Panel header={t("salary")} key="6">
                <div className="d-flex">
                  <Form.Item
                    label={t("min")}
                    name="min"
                    required={false}
                    rules={[
                      {
                        required: false,
                        message: "Please fill the field!",
                      },
                    ]}
                    className="m-0 me-4"
                  >
                    <Input
                      onChange={onChangeMinSal}
                      placeholder="500"
                      defaultValue={valueMinSalary}
                      value={valueMinSalary}
                      type="number"
                      prefix="$"
                      className="inputDisableArrow"
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("max")}
                    name="max"
                    required={false}
                    rules={[
                      {
                        required: minMaxSalValidator,
                        message: "Please fill the field!",
                      },
                    ]}
                    className="m-0"
                  >
                    <Input
                      onChange={onChangeMaxSal}
                      placeholder="1000"
                      defaultValue={valueMaxSalary}
                      value={valueMaxSalary}
                      type="number"
                      prefix="$"
                      className="inputDisableArrow"
                    />
                  </Form.Item>
                </div>
              </Panel>
            </Collapse>

            <div
              style={{
                position: "sticky",
                bottom: "0",
                backgroundColor: "#fff",
              }}
            >
              <Button htmlType="submit" type="primary" className="w-100 mt-3">
                {t("search")}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>

      <Drawer
        height={220}
        placement={"bottom"}
        closable={false}
        onClose={onCloseApply}
        open={openApply}
        key={"bottom"}
        className={`drawerModal`}
      >
        <div className="wrapper text-center">
          <LineIphone className={"drawerBlueLine"} />
          <h3 className={`mt-2 mb-4`}>Do you want apply this job?</h3>
          {/* <p className="mb-4">description</p> */}
          <Button
            type="primary"
            className="w-100 mb-2"
            htmlType="submit"
            style={{ height: 48 }}
            onClick={applyJob}
            loading={loadingApply}
          >
            OK
          </Button>
          <Button
            type="default"
            className="w-100"
            style={{ height: 48 }}
            onClick={() => setOpenApply(false)}
          >
            CANCEL
          </Button>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default VacanyCard;
