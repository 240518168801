import { Button, Empty } from "antd";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type PropType = {
  type?: "basic" | "customize";
  to?: string | "add";
  createBtn?: boolean;
  description?: string;
  btnTitle?: string;
};

const styles: React.CSSProperties = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const CustomEmpty = (props: PropType) => {
  const { t } = useTranslation();

  const {
    type,
    to = "add",
    createBtn = true,
    description = t("no_data"),
    btnTitle = t("create_now"),
  } = props;

  const navigate = useNavigate();

  return (
    <Fragment>
      {type === "basic" ? (
        <Empty style={styles} description={<span>{description}</span>} />
      ) : (
        <Empty
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{ height: 60 }}
          description={<span>{description}</span>}
          style={styles}
        >
          {createBtn && (
            <Button type="primary" onClick={() => navigate(to)}>
              {btnTitle}
            </Button>
          )}
        </Empty>
      )}
    </Fragment>
  );
};

export default CustomEmpty;
