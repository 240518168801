import { Button, Drawer, message } from "antd";
import { useState } from "react";
import LineIphone from "../../assets/icons/LineIphone";
import instance from "../../configs/axios";
import { ParamsType, TypeAxiosConfig } from "../../interfaces/index.interface";
import { reDrawData } from "../../helpers/reDrawData";
import { AxiosRequestConfig } from "axios";
import { useTranslation } from "react-i18next";

type PropType = {
  id?: number | undefined;
  open: boolean;
  setOpen: (value: boolean) => void;
  title?: string;
  description?: string;
  okText?: string | "YES";
  cancelText?: string | "CANCEL";
  successMsg?: string | "DELETED";
  url: string;
  data?: any;
  setData?: any;
  params?: ParamsType;
  method?: "post" | "get" | "put" | "delete";
};

const DeleteDrawer = (props: PropType) => {
  const { t } = useTranslation();
  const {
    id,
    open,
    setOpen,
    title,
    description,
    okText = t("yes"),
    cancelText = t("cancel"),
    successMsg = t("deleted"),
    url,
    data,
    setData,
    params,
    method = "delete",
  } = props;
  const [loading, setLoading] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const handlerSubmit = async () => {
    try {
      setLoading(true);
      const config: AxiosRequestConfig = params
        ? {
            url,
            method,
            params,
          }
        : {
            url,
            method,
          };
      const res = await instance(config);
      reDrawData(data, setData, id);

      message.success(successMsg);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      console.log(
        "🚀 ~ file: index.tsx:25 ~ handlerSubmit ~ error:",
        error?.response?.data?.message
      );
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };
  return (
    <Drawer
      height={250}
      placement={"bottom"}
      closable={false}
      onClose={onClose}
      open={open}
      key={"bottom"}
      className={`drawerModal`}
    >
      <div className="wrapper text-center">
        <LineIphone className={"drawerBlueLine"} />
        <h3 className={`mt-2 danger`}>{title}</h3>
        <p className="mb-4">{description}</p>
        <Button
          type="primary"
          className="w-100 mb-2"
          htmlType="submit"
          style={{ height: 48 }}
          onClick={handlerSubmit}
          loading={loading}
        >
          {okText}
        </Button>
        <Button
          type="default"
          className="w-100"
          style={{ height: 48 }}
          onClick={() => setOpen(false)}
        >
          {cancelText}
        </Button>
      </div>
    </Drawer>
  );
};

export default DeleteDrawer;
