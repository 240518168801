import { Fragment, useEffect, useState } from "react";
import { Button, Col, Empty, Form, Input, message, Row } from "antd";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeft from "../../../../assets/icons/ArrowLeft";
import SaveBtn from "../../../../Components/Buttons/SaveBtn";
import GoBack from "../../../../Components/Buttons/GoBack";
import { Modal } from "antd-mobile";
import { ExclamationCircleFill } from "antd-mobile-icons";
import { updateMe } from "../../../../api/auth";
import { ACCESS_TOKEN } from "../../../../constants";
import { SET_USER_DATA } from "../../../../redux/actionTypes";
import Container from "../../../../layout/Container";
import { useTranslation } from "react-i18next";

const AboutMe = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userData, isAuth } = useSelector((state: any) => state.authReducer);
  const { isLight } = useSelector((state: any) => state.uiReducer);

  const onFinish = async (values: any) => {
    if (!values?.description) {
      Modal.alert({
        header: (
          <ExclamationCircleFill
            style={{
              fontSize: 64,
              color: "var(--adm-color-warning)",
            }}
          />
        ),
        title: t("please_fill_a_field"),
        showCloseButton: true,
        confirmText: t("ok"),
      });
    } else {
      setLoading(true);
      try {
        const data = {
          aboutMe: values?.description,
        };

        const res = await updateMe(data, "about-me");
        message.success(t("profile_success_updated"));

        dispatch({
          type: SET_USER_DATA,
          payload: res?.data?.data,
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      description: userData.aboutMe,
    });
  }, []);

  return (
    <Container type="fluid">
      <GoBack to={"/profile"} />
      <div className={`container ${styles.container}`}>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row className="px-2">
            <Col span={24} className={`${styles.col}`}>
              <Form.Item
                name="description"
                label={t("about_me")}
                className={`${isLight ? "textLight" : "textDark"}`}
              >
                <Input.TextArea placeholder={`${t("tell_me_about")}`} />
              </Form.Item>
            </Col>
            <Col span={24}></Col>
          </Row>
          <SaveBtn loading={loading}>SAVE</SaveBtn>
        </Form>
        {/* <Empty />
      <Link to={"/profile"}>
        <Button type="primary" className="mt-2">
          Back Profile
        </Button>
      </Link> */}
      </div>
    </Container>
  );
};

export default AboutMe;
