import {
  AreaSkillTypes,
  SkillTypes,
} from "../../../../../interfaces/index.interface";

export const convertSkillsToGroupedAreas = (
  inputSkills: number[],
  areas: AreaSkillTypes[]
) => {
  let result: AreaSkillTypes[] = [];

  areas.forEach(({ id, name, skills }) => {
    let updatedSkills: SkillTypes[] = skills.filter(({ id }) => {
      return inputSkills.includes(id);
    });

    if (updatedSkills.length > 0) {
      result.push({
        id,
        name,
        skills: updatedSkills,
      });
    }
  });

  return result;
};
