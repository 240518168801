import React, { useEffect, useRef, useState } from "react";
import Container from "../../layout/Container";
import {
  DotLoading,
  InfiniteScroll,
  Swiper,
  SwiperRef,
  Tabs,
} from "antd-mobile";
import {
  FolderViewOutlined,
  DeleteOutlined,
  EditOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { useGetAllData } from "../../hooks/useGetAllData";
import { MetaType, ParamsType } from "../../interfaces/index.interface";
import { TypeProjectItem } from "../Profile/Pages/Project/interfaces/index.interface";
import { Avatar, Card, Col, Form, Input, Pagination, Row, Tag } from "antd";
import { mapSkeleton } from "../../utils/MapSkeleton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Meta from "antd/es/card/Meta";
import CustomEmpty from "../../Components/Empty";
import Typography from "../../Components/Typography";
import { IMAGE_PATH } from "../../constants";
import { getAllProjects, getAllStudents } from "../../api/profile";
import { useTranslation } from "react-i18next";

const { Search } = Input;

interface TypeDataUser {
  male: boolean;
  fullname: string;
  username?: string;
  photoPath?: string;
  id: number;
}

interface DataType {
  meta: MetaType;
  items: TypeDataUser[];
}
interface DataTypeProject {
  meta: MetaType;
  items: TypeProjectItem[];
}

interface LoadingTypes {
  employees: boolean;
  project: boolean;
  beta_project: boolean;
}

const Connection = () => {
  const { t } = useTranslation();
  const tabItems = [
    { key: "employees", title: t("employees") },
    { key: "projects", title: t("projects") },
    { key: "beta_projects", title: t("beta_project") },
  ];
  const navigate = useNavigate();
  const swiperRef = useRef<SwiperRef>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeIndex, setActiveIndex] = useState(
    Number(searchParams.get("activeTab"))
  );
  const [loading, setLoading] = useState<LoadingTypes>({
    employees: true,
    project: true,
    beta_project: true,
  });
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreProject, setHasMoreProject] = useState(true);
  const [hasMoreBetaProject, setHasMoreBetaProject] = useState(true);
  const [params, setParams] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });
  const [paramsProject, setParamsProject] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
  });
  const [paramsBetaProject, setParamsBetaProject] = useState<ParamsType>({
    page: 1,
    size: 10,
    searchAny: null,
    isBetaProject: true,
  });
  const [listStudents, setListStudents] = useState<TypeDataUser[]>([]);
  const [listProjects, setListProjects] = useState<TypeProjectItem[]>([]);
  const [listBetaProjects, setListBetaProjects] = useState<TypeProjectItem[]>(
    []
  );
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [searchValueProjects, setSearchValueProjects] = useState<string | null>(
    null
  );
  const [searchValueBetaProjects, setSearchValueBetaProjects] = useState<
    string | null
  >(null);

  const loadMoreEmployees = async () => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        employees: true,
      }));

      const res = await getAllStudents(params);
      const data: DataType = res?.data?.data;
      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));

      setListStudents((val) => [...val, ...data.items]);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMore(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        employees: false,
      }));
    }
  };

  const loadMoreProjects = async () => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        project: true,
      }));

      const res = await getAllProjects(paramsProject);
      const data: DataTypeProject = res?.data?.data;
      setParamsProject((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));

      setListProjects((val) => [...val, ...data.items]);
      setHasMoreProject(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMoreProject(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        project: false,
      }));
    }
  };

  const loadMoreBetaProjects = async () => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        beta_project: true,
      }));

      const res = await getAllProjects(paramsBetaProject);
      const data: DataTypeProject = res?.data?.data;
      setParamsBetaProject((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));

      setListBetaProjects((val) => [...val, ...data.items]);
      setHasMoreBetaProject(data.meta.hasNextPage);
    } catch (error: any) {
      setHasMoreBetaProject(false);
      console.log("🚀 ~ file: index.tsx:42 ~ loadMore ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        beta_project: false,
      }));
    }
  };

  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>{t("no_more")}</span>
        )}
      </>
    );
  };

  const InfiniteScrollContentProject = ({
    hasMoreProject,
  }: {
    hasMoreProject?: boolean;
  }) => {
    return (
      <>
        {hasMoreProject ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>{t("no_more")}</span>
        )}
      </>
    );
  };

  const InfiniteScrollContentBProject = ({
    hasMoreBetaProject,
  }: {
    hasMoreBetaProject?: boolean;
  }) => {
    return (
      <>
        {hasMoreBetaProject ? (
          <>
            <span>{t("loading")}</span>
            <DotLoading />
          </>
        ) : (
          <span>{t("no_more")}</span>
        )}
      </>
    );
  };

  const resetSearch = () => {
    params.page = 1;
    params.size = 10;
    setListStudents([]);
  };
  const resetSearchProject = () => {
    paramsProject.page = 1;
    paramsProject.size = 10;
    setListProjects([]);
  };
  const resetSearchBetaProject = () => {
    paramsBetaProject.page = 1;
    paramsBetaProject.size = 10;
    setListBetaProjects([]);
  };

  const onFinish = async (values?: any) => {
    resetSearch();

    try {
      setLoading((prevState) => ({
        ...prevState,
        employees: true,
      }));
      const res = await getAllStudents(params);
      const data: DataType = res?.data?.data;
      setHasMore(data.meta.hasNextPage);

      setParams((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      setListStudents(data.items);
      setHasMore(data.meta.hasNextPage);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:112 ~ loadMore ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        employees: false,
      }));
    }
  };

  const onFinishProjects = async (values?: any) => {
    resetSearchProject();

    try {
      setLoading((prevState) => ({
        ...prevState,
        project: true,
      }));
      const res = await getAllProjects(paramsProject);
      const data: DataTypeProject = res?.data?.data;
      setHasMoreProject(data.meta.hasNextPage);

      setParamsProject((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      setListProjects(data.items);
      setHasMoreProject(data.meta.hasNextPage);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:112 ~ loadMore ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        project: false,
      }));
    }
  };

  const onFinishBetaProjects = async (values?: any) => {
    resetSearchBetaProject();

    try {
      setLoading((prevState) => ({
        ...prevState,
        beta_project: true,
      }));
      const res = await getAllProjects(paramsBetaProject);
      const data: DataTypeProject = res?.data?.data;
      setHasMoreProject(data.meta.hasNextPage);

      setParamsBetaProject((prevState) => ({
        ...prevState,
        page: data.meta.page + 1,
      }));
      setListBetaProjects(data.items);
      setHasMoreBetaProject(data.meta.hasNextPage);
    } catch (error: any) {
      console.log("🚀 ~ file: index.tsx:112 ~ loadMore ~ error:", error);
    } finally {
      setLoading((prevState) => ({
        ...prevState,
        beta_project: false,
      }));
    }
  };

  const onSearchEmployees = (e: string) => {
    params.page = 1;
    params.searchAny = e;
    // setFiltered_data((prevState) => ({
    //   ...prevState,
    //   searchAny: e,
    //   page: 1,
    // }));
    setSearchValue(e);
    onFinish();
    if (e?.length === 0) {
      resetSearch();
      setSearchValue(null);
    }
  };

  const onSearchProjects = (e: string) => {
    paramsProject.page = 1;
    paramsProject.searchAny = e;
    // setFiltered_data((prevState) => ({
    //   ...prevState,
    //   searchAny: e,
    //   page: 1,
    // }));
    setSearchValueProjects(e);
    onFinishProjects();
    if (e?.length === 0) {
      resetSearchProject();
      setSearchValueProjects(null);
    }
  };

  const onSearchBetaProjects = (e: string) => {
    paramsBetaProject.page = 1;
    paramsBetaProject.searchAny = e;
    // setFiltered_data((prevState) => ({
    //   ...prevState,
    //   searchAny: e,
    //   page: 1,
    // }));
    setSearchValueBetaProjects(e);
    onFinishBetaProjects();
    if (e?.length === 0) {
      resetSearchBetaProject();
      setSearchValueBetaProjects(null);
    }
  };

  return (
    <Container className="pb-5 mb-5" type="fluid">
      <Tabs
        className="mb-3"
        activeKey={tabItems[activeIndex].key}
        onChange={(key) => {
          const index = tabItems.findIndex((item) => item.key === key);
          setActiveIndex(index);
          swiperRef.current?.swipeTo(index);
          setSearchParams({
            activeTab: String(index),
          });
        }}
        style={{
          position: "sticky",
          top: "0",
          zIndex: "2",
          backgroundColor: "#fff",
        }}
      >
        {tabItems.map((item) => (
          <Tabs.Tab title={item.title} key={item.key} />
        ))}
      </Tabs>

      <Swiper
        direction="horizontal"
        loop
        indicator={() => null}
        ref={swiperRef}
        defaultIndex={activeIndex}
        onIndexChange={(index) => {
          setActiveIndex(index);
        }}
      >
        <Swiper.Item>
          <Row
            style={{
              position: "sticky",
              top: "0",
              zIndex: "3",
              backgroundColor: "#fff",
            }}
            className="py-3 mb-2"
          >
            <div className="container">
              <Col span={24}>
                <Form className="w-100" onFinish={onFinish}>
                  <Form.Item
                    required={false}
                    rules={[
                      {
                        required: false,
                        message: "Search any",
                        warningOnly: true,
                      },
                    ]}
                    className="w-100 mb-0"
                    name="search"
                  >
                    <Search
                      placeholder={`${t("please_enter_employees_name")}`}
                      // value={searchValue}
                      onSearch={onSearchEmployees}
                      enterButton
                      allowClear
                      onChange={(e?: any) => onSearchEmployees(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </div>
          </Row>
          <div className="container">
            <Row>
              {listStudents?.length
                ? listStudents?.map((item, index) => (
                    <Col span={24} key={index} className="mb-2">
                      <div>
                        <Link
                          to={`${item.id}?activeTab=0`}
                          style={{ textDecoration: "none" }}
                        >
                          <Card>
                            <Meta
                              avatar={
                                <Avatar
                                  src={
                                    item?.photoPath
                                      ? item?.photoPath?.startsWith("https")
                                        ? item?.photoPath
                                        : IMAGE_PATH + item?.photoPath
                                      : "https://cdn-icons-png.flaticon.com/512/149/149071.png"
                                  }
                                />
                              }
                              title={item.fullname}
                              description={item.male}
                            />
                          </Card>
                        </Link>

                        {/* <div className={styles.thirdRow}>
                      <span className={styles.timePost}>{item.createdAt}</span>
                      <p>
                        <span>${item.salary}</span>/Mo
                      </p>
                      {isAuth ? (
                        !item.applied ? (
                          <Button
                            type="primary"
                            onClick={() => onShowApply(item.jobId, index)}
                          >
                            Apply
                          </Button>
                        ) : (
                          <span style={{ color: "#1677ff" }}>Applied</span>
                        )
                      ) : (
                        ""
                      )}
                    </div> */}
                      </div>
                    </Col>
                  ))
                : loading.employees
                ? ""
                : ""}
            </Row>
            <InfiniteScroll
              loadMore={loadMoreEmployees}
              hasMore={hasMore}
              className="text-center w-100"
            >
              <InfiniteScrollContent hasMore={hasMore} />
            </InfiniteScroll>

            {/* <Tabs
        activeKey={tabItems[activeIndex].key}
        onChange={(key) => {
          const index = tabItems.findIndex((item) => item.key === key);
          setActiveIndex(index);
          swiperRef.current?.swipeTo(index);
        }}
        style={{
          position: "sticky",
          top: "0",
          zIndex: "2",
          backgroundColor: "#fff",
        }}
      >
        {tabItems.map((item) => (
          <Tabs.Tab title={item.title} key={item.key} />
        ))}
      </Tabs> */}
            {/* <Swiper
          direction="horizontal"
          loop
          indicator={() => null}
          ref={swiperRef}
          defaultIndex={activeIndex}
          onIndexChange={(index) => {
            setActiveIndex(index);
          }}
        >
          <Swiper.Item>
            <div className={"styles.content"}>
              <h1>Projecy</h1>
            </div>
          </Swiper.Item>
          <Swiper.Item>
            <div className={"styles.content"}>
              <h1>Beta projecy</h1>
            </div>
          </Swiper.Item>
        </Swiper> */}
          </div>
        </Swiper.Item>

        <Swiper.Item>
          <Row
            style={{
              position: "sticky",
              top: "0",
              zIndex: "3",
              backgroundColor: "#fff",
            }}
            className="py-3 mb-2"
          >
            <div className="container">
              <Col span={24}>
                <Form className="w-100" onFinish={onFinishProjects}>
                  <Form.Item
                    required={false}
                    rules={[
                      {
                        required: false,
                        message: "Search any",
                        warningOnly: true,
                      },
                    ]}
                    className="w-100 mb-0"
                    name="search"
                  >
                    <Search
                      placeholder={`${t("please_enter_project_name")}`}
                      // value={searchValue}
                      onSearch={onSearchProjects}
                      enterButton
                      allowClear
                      onChange={(e?: any) => onSearchProjects(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </Col>
            </div>
          </Row>

          {/* List */}
          <Row>
            {loading.project
              ? Array.from({ length: 2 }).map((item: any, index: number) =>
                  mapSkeleton(index)
                )
              : listProjects?.length
              ? listProjects.map((item: TypeProjectItem, index: number) => (
                  <Col span={24} key={index} className={"mb-2 px-2"}>
                    <Card
                      actions={
                        item.isOwner
                          ? [
                              <EditOutlined
                                key="edit"
                                onClick={() =>
                                  navigate(
                                    `/profile/project/${String(
                                      item.id
                                    )}?isOwner=${item.isOwner}`
                                  )
                                }
                              />,

                              <span
                                onClick={() =>
                                  navigate(
                                    `/profile/project/${String(
                                      item.id
                                    )}?isOwner=${item.isOwner}`
                                  )
                                }
                              >
                                {t("details")}
                              </span>,
                            ]
                          : [
                              <span
                                onClick={() =>
                                  navigate(
                                    `/profile/project/${String(
                                      item.id
                                    )}?isOwner=${item.isOwner}&visit=true`
                                  )
                                }
                              >
                                {t("details")}
                              </span>,
                            ]
                      }
                    >
                      {/* {item.isOwner ? (
                      <Tag
                        color={`blue`}
                        style={{ position: "absolute", right: "20px" }}
                      >
                        Owner
                      </Tag>
                    ) : item.isJoined ? (
                      <Tag
                        color={`orange`}
                        style={{ position: "absolute", right: "20px" }}
                      >
                        Joined
                      </Tag>
                    ) : (
                      ""
                    )} */}
                      <Meta
                        title={item.name}
                        description={`${t("from")} ${item.startDate} ${
                          item.endDate !== null
                            ? `${t("to")} ${item.endDate}`
                            : t("until_now")
                        }`}
                      />
                    </Card>
                  </Col>
                ))
              : ""}
          </Row>
          <InfiniteScroll
            loadMore={loadMoreProjects}
            hasMore={hasMoreProject}
            className="text-center w-100"
          >
            <InfiniteScrollContentProject hasMoreProject={hasMoreProject} />
          </InfiniteScroll>
        </Swiper.Item>

        <Swiper.Item>
          <Row
            style={{
              position: "sticky",
              top: "0",
              zIndex: "3",
              backgroundColor: "#fff",
            }}
            className="py-3 mb-2"
          >
            <div className="container">
              <Col span={24}>
                <Form className="w-100" onFinish={onFinishBetaProjects}>
                  <Form.Item
                    required={false}
                    rules={[
                      {
                        required: false,
                        message: "Search any",
                        warningOnly: true,
                      },
                    ]}
                    className="w-100 mb-0"
                    name="search"
                  >
                    <Search
                      placeholder={`${t("please_enter_beta_project_name")}`}
                      // value={searchValue}
                      onSearch={onSearchBetaProjects}
                      enterButton
                      allowClear
                      onChange={(e?: any) =>
                        onSearchBetaProjects(e.target.value)
                      }
                    />
                  </Form.Item>
                </Form>
              </Col>
            </div>
          </Row>
          {/* List */}
          <Row>
            {loading.beta_project
              ? Array.from({ length: 2 }).map((item: any, index: number) =>
                  mapSkeleton(index)
                )
              : listBetaProjects?.length
              ? listBetaProjects.map((item: TypeProjectItem, index: number) => (
                  <Col span={24} key={index} className={"mb-2 px-2"}>
                    <Card
                      actions={[
                        <span
                          onClick={() =>
                            navigate(
                              `/profile/beta-project/${String(
                                item.id
                              )}?isOwner=${item.isOwner}&visit=true`
                            )
                          }
                        >
                          {t("details")}
                        </span>,
                      ]}
                    >
                      {/* {item.isOwner ? (
                      <Tag
                        color={`blue`}
                        style={{ position: "absolute", right: "20px" }}
                      >
                        Owner
                      </Tag>
                    ) : item.isJoined ? (
                      <Tag
                        color={`orange`}
                        style={{ position: "absolute", right: "20px" }}
                      >
                        Joined
                      </Tag>
                    ) : (
                      ""
                    )} */}
                      <Meta
                        title={item.name}
                        description={`${t("from")} ${item.startDate} ${
                          item.endDate !== null
                            ? `${t("to")} ${item.endDate}`
                            : t("until_now")
                        }`}
                      />
                    </Card>
                  </Col>
                ))
              : ""}
          </Row>
          <InfiniteScroll
            loadMore={loadMoreBetaProjects}
            hasMore={hasMoreBetaProject}
            className="text-center w-100"
          >
            <InfiniteScrollContentBProject
              hasMoreBetaProject={hasMoreBetaProject}
            />
          </InfiniteScroll>
        </Swiper.Item>
      </Swiper>
    </Container>
  );
};

export default Connection;
